/**
 * Created by Malcolm Laing on 2017-07-28.
 */
'use strict';


angular.module('core').controller('PartialsOemRemoteStartClientController', ['$scope', 'Wmoapi', 'messageBox', 'alertBar',
    '$rootScope', 'wu', 'Request', 'DeviceService', '$q', 'ngDialog', 'InternalRemotes', '_',
    function ($scope, Wmoapi, messageBox, alertBar, $rootScope, wu, Request, DeviceService, $q, ngDialog, InternalRemotes, _) {

        $scope.hasOemRemoteConfig = false;
        $scope.callComplete = false;
        $scope.saveOemRemoteStartConfig = saveOemRemoteStartConfig;
        $scope.saveAndGoToSummary = saveAndGoToSummary;
        $scope.flashConfigFromFavorites = flashConfigFromFavorites;

        init();
        $scope.$on('oem-start-save-config', saveOemRemoteStartConfig);

        function init() {
            ngDialog.closeAll();
            $rootScope.isGlobalLoading = true;
            $scope.hidePageNaviNextBtn = true;
            $scope.$parent.responseData.quickConfigs = [];
            $scope.$parent.configurationParameters.flashFromFavoriteConfig = false;
            var promise;
            if ($scope.$parent.request.configModuleRequestId) {
                promise = cancelPreviousRequest().then(createRequestForOemRemoteStart);
            } else {
                promise = createRequestForOemRemoteStart();
            }
            promise.then(
                function (oemRemoteStartConfigRequestId) {
                    getQuickConfigsOptions(oemRemoteStartConfigRequestId).then(function () {
                        $rootScope.isGlobalLoading = false;
                    }, function (error) {
                        $rootScope.isGlobalLoading = false;
                        console.error('error in getOemRemoteStartConfig:', error);
                        alertBar.show('error', 'CONTROLS_ERROR_GET_OEM_REMOTE');
                    });

                }, function (error) {
                    $rootScope.isGlobalLoading = false;
                    console.error('error in createRequestForOemRemoteStart (config):', error);
                    alertBar.show('error', 'CONTROLS_ERROR_CREATE_REQUEST_CONFIG_MODULE');
                }
            );

        }

        $scope.$on('PartialsControls_OnSaveRemotesFlashMode', saveOemRemoteStartConfig);

        function cancelPreviousRequest() {
            var deferred = $q.defer();

            Wmoapi.cancel($scope.$parent.request.configModuleRequestId).then(
                function (success) {
                    deferred.resolve(success.data.result);
                },
                function(error) {
                    console.error('error in cancelPreviousRequest:', error);
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function createRequestForOemRemoteStart() {
            var requestId = $scope.$parent.request.id;
            var selectedFwId = $scope.$parent.userSelection.selectedFirmware.id;
            var deferred = $q.defer();

            Wmoapi.createRequestConfigureModule(requestId).then(function (success) {
                var requestId_configModule = success.data.requestId;
                $scope.$parent.request.configModuleRequestId = requestId_configModule;
                $rootScope.globalResponseData.deviceFeatures = null;
                $scope.$parent.prevSelectedVehicle.firmwareId = selectedFwId;
                deferred.resolve(requestId_configModule);
            }, function (error) {
                console.error('error in createRequestForOemRemoteStart:', error);
                deferred.reject(error);
            });

            return deferred.promise;
        }


        function getQuickConfigsOptions(configRequestId) {
            var deferred = $q.defer();

            Wmoapi.getQuickConfigs(configRequestId).then(function (success) {
                $scope.callComplete = true;
                //Is this always true?
                if (success.data.length > 0) {
                    $scope.hidePageNaviNextBtn = false;
                    $scope.$parent.responseData.quickConfigs = findSelectedItems(success.data);
                    $scope.hasOemRemoteConfig = _.some($scope.$parent.responseData.quickConfigs, 'isOemRemoteConfig');
                    var remoteConfigDisabled = _.some($scope.$parent.responseData.quickConfigs, {'isOemRemoteConfig': true, 'disabled': true});
                    var isBypass = $scope.$parent.userSelection.selectedProtocol.usedAsAddOn;
                    if (!$scope.hasOemRemoteConfig || (remoteConfigDisabled && !isBypass)) showModalBox();
                    deferred.resolve(success.data);
                } else {
                    //Technically unreachable?
                    deferred.resolve(success);
                }
            }, function (error) {
                console.error('error in getQuickConfigsOptions:', error);
                deferred.reject(error);
            });
            return deferred.promise;
        }

        function flashConfigFromFavorites () {
            $rootScope.isGlobalLoading = true;
            $scope.$parent.loadFavoritesPartial(true);
        }


        function findSelectedItems(data) {
            var result = [];

            _.find(data, function (o) {
                _.assign(o, {selectedItem: _.find(o.option, 'isSelected' ? 'isSelected' : 'isDefault')});
                result.push(o);
            });
            return result;
        }


        function showModalBox() {
            ngDialog.openConfirm({
                template: 'modules/core/views/directives-partial/oemRemoteMessageBox.html',
                className: 'ngdialog-theme-plain',
                controller: 'PartialsOemRemoteStartModalController as ctrl',
                closeByDocument: false,
                showClose: false
            }).then(function (result) {
                // user did somthing else
            }, function (error) {
                // user closed dialog - do nothing
            });
        }

        function saveOemRemoteStartConfig() {
            // if oem, save.
            goTo(true);
        }

        function saveAndGoToSummary() {
            goTo(false);
        }

        function goTo(toFeatures) {
            var configModuleRequestId = $scope.$parent.request.configModuleRequestId;
            var configsArray = $scope.$parent.responseData.quickConfigs;
            var configsStr = extractConfigs(configsArray);
            var hasOemRemoteConfig = _.some(configsArray, 'isOemRemoteConfig');
            $scope.$parent.userSelection.oemRemote = hasOemRemoteConfig ? _.find(configsArray, 'isOemRemoteConfig').selectedItem : {};
            
            if (hasOemRemoteConfig || configsStr !== '') {
                $rootScope.isGlobalLoading = true;
                setQuickConfigs(configModuleRequestId, configsStr)
                    .then(function () {
                        $rootScope.isGlobalLoading = false;
                        if (toFeatures) $scope.$parent.loadFeaturePartial(false);
                        else loadFlashPage();

                    }, function () {
                        $rootScope.isGlobalLoading = false;
                        alertBar.show('error', 'CONTROLS_ERROR_SET_OEM_REMOTE');
                    });
            } else {
                if (toFeatures) $scope.$parent.loadFeaturePartial(false);
                else loadFlashPage();
            }
        }


        function extractConfigs(array) {
            return _.chain(array)
                .map(function (o) {
                    return o.id + ':' + o.selectedItem.id;
                })
                .join(',')
                .value();
        }


        function loadFlashPage() {
            var parentRequestId = $scope.$parent.request.id;
            var ownerLink = '';
            var installLink = '';
            var productLink;

            var installGuideUrl = $scope.$parent.responseData.setFirmware.installGuideUrl;
            if (installGuideUrl && installGuideUrl !== '') {
                installLink = installGuideUrl;
            }

            var flashableDevice = DeviceService.getFlashableDevice();
            var productId = flashableDevice.productID;
            productLink = flashableDevice.productGuideDownloadUrl;

            var ownerGuidesHash = {
                '360': 'img/pdf/owner_guide_hcx_hcf.pdf',
                '418': 'img/pdf/owner_guide_hcx_hcf.pdf',
                '420': 'img/pdf/owner_guide_hc1_hc2.pdf',
                '422': 'img/pdf/owner_guide_hc1_hc2.pdf',
                '424': 'img/pdf/owner_guide_hc1_hc2.pdf',
                '468': 'img/pdf/owner_guide_vw2_e.pdf',
                '508': 'img/pdf/owner_guide_hc3_e.pdf',
                '528': 'img/pdf/owner_guide_vwx_e.pdf',
                '532': 'img/pdf/OM_FT-DC2_2016_10_03_e.pdf',
                '558': 'img/pdf/OM_RS1BDC2_2016_10_03_e.pdf',
                '586': 'img/pdf/OM_FT-DC2_2016_10_03_e.pdf'
            };

            if (ownerGuidesHash[productId]) {
                ownerLink = ownerGuidesHash[productId];
            }

            var guides = [], guideObj;
            if (installLink) {
                guideObj = extractInstallGuideFromUrl(installLink);
                guides.push(
                    {
                        'id': '1',
                        'name': '',
                        'type': 'GUIDES_TYPE_INSTALLGUIDE',
                        'filename': guideObj.filename,
                        'version': guideObj.version,
                        'link': guideObj.url,
                        'isNewVersion': 0,
                        'language': guideObj.language
                    }
                );
            }

            if (ownerLink) {
                guideObj = extractOwnerGuideFromUrl(ownerLink);
                guides.push({
                    'id': '2',
                    'name': '',
                    'type': 'GUIDES_TYPE_OWNERMANUAL',
                    'filename': guideObj.filename,
                    'version': '',
                    'link': guideObj.url,
                    'isNewVersion': 0,
                    'language': ''
                });
            }

            if (productLink) {
                guides.push({
                    'id': '3',
                    'name': '',
                    'type': 'GUIDES_TYPE_PRODUCTGUIDE',
                    'filename': flashableDevice.productName,
                    'version': '',
                    'link': productLink,
                    'isNewVersion': 0,
                    'language': ''
                });
            }

            $scope.$parent.responseData.deviceGuides = guides;
            $rootScope.isGlobalLoading = true;

            Wmoapi
                .getFirmwarePosts(parentRequestId)
                .success(function (response) {
                    $scope.responseData.firmwarePosts = response;
                })
                .error(function (error) {
                    console.error('error getFirmwarePosts in oemRemoteStart', error);
                    $scope.responseData.firmwarePosts = [];
                }).finally(function () {
                $rootScope.isGlobalLoading = false;
                $scope.$parent.loadFlashPartial(false, false);
            });


        }


        function setQuickConfigs(configModuleRequestId, configsStr) {
            var deferred = $q.defer();
            alertBar.hide();

            Wmoapi
                .setQuickConfigs(configModuleRequestId, configsStr)
                .then(function (response) {

                    $rootScope.globalResponseData.deviceFeatures = null;
                    $rootScope.isGlobalLoading = false;
                    deferred.resolve(response);

                }, function (error) {
                    console.error('error in setQuickConfigs:', error);
                    deferred.reject(error);
                });

            return deferred.promise;

        }


        function extractInstallGuideFromUrl(guideUrl) {
            var re, match, language, filename, version, guideObj;
            re = /.+\/(.+?)-(EN|FR)_(\d+)\.pdf/;
            match = guideUrl.match(re);
            language = '';
            filename = '';
            version = '';
            if (match !== null) {
                filename = match[1];
                language = match[2];
                version = match[3];
            }

            guideObj = {
                language: language,
                filename: filename,
                version: version,
                url: guideUrl
            };

            return guideObj;
        }

        function extractOwnerGuideFromUrl(guideUrl) {
            var re, match, language, filename, version, guideObj;

            re = /.+\/(.+?)\.pdf/;
            match = guideUrl.match(re);

            filename = '';
            if (match !== null) {
                filename = match[1];
            }

            guideObj = {
                filename: filename,
                url: guideUrl
            };

            return guideObj;
        }
    }
]);
