'use strict';


angular.module('core').controller('PartialsFeaturesFeaturesController', ['$scope', 'Wmoapi','messageBox','$timeout', '$rootScope', 'alertBar', 'wu', 'DeviceService', 'constants', '_', 'Request', 'ngDialog',
	function($scope, Wmoapi, messageBox, $timeout, $rootScope, alertBar, wu, DeviceService, constants, _, Request, ngDialog) {

        $scope.btnSaveNextText = 'FEATURES_BUTTON_SAVE_NEXT';
        $scope.btnPreviousMenuText = 'FEATURES_BUTTON_PREVIOUS_MENU';
        $scope.editFeatureOnly = false;

        $scope.initFeatures = function(){
            $rootScope.globalResponseData = { deviceFeatures:[] };
            $scope.getVehicleFeatures();
            $scope.managePageNavigation();
        };

        $scope.managePageNavigation = function() {

            var modifyFeaturesOnly = $scope.$parent.modifyFeaturesOnly;
            var flashByFirmware = $scope.$parent.configurationParameters.flashByFirmware;

            $scope.hidePageNaviNextBtn = modifyFeaturesOnly || flashByFirmware;
            $scope.backSkipStep = (modifyFeaturesOnly || flashByFirmware) ? 'device' : 'oem-remote-start';
            $scope.nextSkipStep = 'guides';

        };

        $scope.getVehicleFeatures = function(){
            var parentRequestId = $scope.$parent.request.id;
            var title = 'FIRMWARE_CONFIGURE_MODULE';
            var description = 'FIRMWARE_MUST_CONFIGURE';

            if (!$scope.$parent.modifyFeaturesOnly) {
                if (parentRequestId==='' || parentRequestId===undefined) {
                    //TODO: decide what to do when master request is not valid
                    messageBox.show(title,description);
                    $scope.$parent.loadDevicePartial();
                } else {
                    var currentFirmware = $scope.$parent.responseData.currentFirmware;
                    var requestid_configModule = $scope.$parent.request.configModuleRequestId;
                    $rootScope.isGlobalLoading = true;

                    if ((currentFirmware === '' || currentFirmware === undefined) && !$scope.$parent.firmwareAlreadySet) {
                        messageBox.show(title, description);
                        $scope.$parent.loadDevicePartial();
                    } else {

                        if (requestid_configModule === '') {

                            $rootScope.globalResponseData.deviceFeatures = null;
                            $scope.$parent.submenu.items = [];
                            $scope.createRequestLoadFeatures(parentRequestId);

                        } else {
                            if ($rootScope.globalResponseData.deviceFeatures && $rootScope.globalResponseData.deviceFeatures.length > 0) {

                                loadSubmenu($rootScope.globalResponseData.deviceFeatures);
                                $rootScope.isGlobalLoading = false;

                            } else {
                                loadVehicleFeatures(requestid_configModule);
                            }
                        }
                    }

                }
            } else {
                $rootScope.isGlobalLoading = true;
                $scope.editFeatureOnly = true;
                //If RSU
                if($scope.$parent.isConfigRsuFeatures) {
                    getRsuConfig();
                } else {
                    $scope.getEepromAdress();
                }
            }


        };

        function getRsuConfig() {
            var rsuDevice = DeviceService.getDeviceBySerial(constants.RSU_BLANK_SERIAL);
            Wmoapi
            .createRequestConfigureRsu(rsuDevice.Product.rsuManufacturerId, rsuDevice.Product.id, rsuDevice.Firmware.id)
                .success(function (response) {
                    $scope.requestId = response.requestId;
                    Wmoapi
                    .getRsuFeatureAddresses(response.requestId, rsuDevice.Firmware.id)
                        .success(function (response) {
                            var addresses = response.addresses.split('');
                            var requiredPages = [];
                            addresses.forEach(function(str, index) {
                                if(index !== 0 &&  Math.abs(index % 2) === 1) {
                                    requiredPages.push(addresses[index-1] + str);
                                }
                            });
                            if (requiredPages.length > 0) {
                                $scope.requiredPages = requiredPages;
                                $scope.readRequiredPagesResult = wu.getRsuConfig(rsuDevice, requiredPages, $scope.onReadRequiredPagesCompleteRsu, $scope.onReadRequiredPagesError);
                                $scope.readRequiredPagesResult.start();
                
                            } else {
                                alertBar.show( 'error', 'FEATURES_ERROR_NO_REQUIRED_PAGES');
                                console.log('FEATURES_ERROR_NO_REQUIRED_PAGES');
                            }
                        })
                        .error(function (error) {
                            $scope.$parent.showErrorMessageFromServer('GENERAL_ERROR_CREATE_REQUEST', error);
                            $scope.$parent.loadDevicePartial(false);
                        });
                })
                .error(function (error) {
                    $scope.$parent.showErrorMessageFromServer('GENERAL_ERROR_CREATE_REQUEST', error);
                    $scope.$parent.loadDevicePartial(false);
                });
        }

        function loadVehicleFeatures(configModuleRequestId) {
            Wmoapi
                .getDeviceFeatures(configModuleRequestId)
                .success(function(response){

                    var deviceFeatures = response;
                    $rootScope.globalResponseData.deviceFeatures = deviceFeatures;
					if ($scope.$parent.submenu.partial === 'feature') {
						loadSubmenu(deviceFeatures);
					}

                })
                .error(function(error){
                    //TODO: handle error
                    $rootScope.isGlobalLoading = false;
                })
                .finally(function(){
                    $rootScope.isGlobalLoading = false;
                });
        }

        function loadSubmenu(deviceFeatures) {

            $scope.$parent.submenu.items = [];

            for (var i = 0; i < deviceFeatures.length; i++) {
                $scope.$parent.submenu.items.push(deviceFeatures[i].name);
            }

            if (deviceFeatures.length > 0) {
                $scope.$parent.submenu.selectedItem = 0;
            }

        }

        $scope.hasEditedFeatures = false;

        $scope.$watch('$root.globalResponseData.deviceFeatures', function() {
            $scope.hasEditedFeatures = getEditedFeatures() !== '';
        }, true);

        $scope.$on('PartialsFeatures_OnSubmitFeatureUpdates', function () {
            $scope.submitFeatureUpdates(false);
        });

        $scope.submitFeatureUpdates = function (isLastIndex) {

            var editedFeatures = getEditedFeatures();

            var title = 'POPUP_FEATURES_SAVED_TITLE';
            var description = 'POPUP_FEATURES_SAVED_DESC';
            if(editedFeatures === undefined ||
                editedFeatures === 'undefined' ||
                editedFeatures === ''){
                //nothing changed
                //TODO: do we show save pop up anyway?
                editedFeatures='';
                if( isLastIndex === true ){
                    $scope.skipstep('guides');
                }
            } else {
                //submit updated features to wmoapi
                var configModuleRequestId = $scope.$parent.request.configModuleRequestId;

                $rootScope.isGlobalLoading = true;

                Wmoapi
                    .setDeviceConfigRequest(configModuleRequestId, editedFeatures)
                    .success(function(response){

                    if(isLastIndex === true && $scope.editFeatureOnly){
                        //TODO: pop-up saved msg box and redirect to next section?
                        messageBox.show(title,description);
                    }else if( isLastIndex === true ){
                            $scope.skipstep('guides');
                    }

                    })
                    .error(function(error){
                        //TODO: handle error
                    })
                    .finally(function(){
                        $rootScope.isGlobalLoading = false;
                    });
            }

        };

        function getEditedFeatures() {
            var deviceFeatures = $rootScope.globalResponseData.deviceFeatures;
            var modifiedFeatures = '';
            var featureConfigurations;
            var options;
            var featureId;
            var optionId;
            var originalOptionId;

            angular.forEach(deviceFeatures, function(featuresGroup){

                featureConfigurations = featuresGroup.featureConfigurations;

                angular.forEach(featureConfigurations, function(feature){

                    if(feature.clientSide_isChanged) {

                        options = feature.option;
                        featureId = $scope.$parent.isConfigRsuFeatures ? featuresGroup.id + '-' + feature.id : feature.id;
                        originalOptionId = feature.clientSide_originalSelectedOptionId;

                        angular.forEach(options, function (option) {

                            optionId = option.id;

                            if (option.isSelected === 1 && optionId !== originalOptionId) {
                                modifiedFeatures += (modifiedFeatures === ''?'':',') + featureId + ':' + option.id;
                            }
                        });
                    }
                });
            });

            return modifiedFeatures;
        }

        $scope.nextGroupFeatures = function( ){
            var index = $scope.submenu.selectedItem;
            var nextIndex = index + 1;
            var deviceFeaturesLength = $rootScope.globalResponseData.deviceFeatures.length;
            var isLastIndex = nextIndex >= deviceFeaturesLength ;

            $scope.submitFeatureUpdates(isLastIndex);

            if( nextIndex < deviceFeaturesLength ) {
                $scope.$parent.submenu.selectedItem = nextIndex;
            }



            //TODO: end of group?, go to next step?
        };

        $scope.previousGroupFeatures = function(){

            var index = $scope.submenu.selectedItem;
            var previousIndex = index - 1;
            var deviceFeaturesLength = $rootScope.globalResponseData.deviceFeatures.length;

            if( previousIndex < deviceFeaturesLength ) {
                $scope.$parent.submenu.selectedItem = previousIndex;
            }



        };

        $scope.$watch('submenu.selectedItem', function(){
            var index = $scope.submenu.selectedItem;

            var deviceFeaturesLength = ($rootScope.globalResponseData.deviceFeatures)?$rootScope.globalResponseData.deviceFeatures.length:0;
            var nextIndex = deviceFeaturesLength===0 ? -1: index + 1;

            $scope.btnSaveNextText = ((nextIndex) >= (deviceFeaturesLength)) ? 'FEATURES_BUTTON_SAVE' : 'FEATURES_BUTTON_SAVE_NEXT';
            $scope.isLastIndex = ((nextIndex) >= (deviceFeaturesLength)) && $scope.editFeatureOnly;
            $scope.isFirstIndex = ((nextIndex) <= 1) ;

        });


        $scope.resetGroupFeatures = function(){
            var elems = angular.element(document.querySelectorAll('.reset-btn'));
            $timeout(function() {
                elems.triggerHandler('click');
            }, 100);
        };

        $scope.readRequiredPages = function() {
            var requiredPages = $scope.$parent.responseData.configModuleRequiredPages;
            if (requiredPages.length > 0) {
                var device = DeviceService.getFlashableDevice();

                $scope.readRequiredPagesResult = wu.getReadModuleResult(device, requiredPages, $scope.onReadRequiredPagesComplete, $scope.onReadRequiredPagesError);
                $scope.readRequiredPagesResult.start();

            } else {
                alertBar.show( 'error', 'FEATURES_ERROR_NO_REQUIRED_PAGES');
                console.log('FEATURES_ERROR_NO_REQUIRED_PAGES');
            }

        };

        $scope.onReadRequiredPagesCompleteRsu = function() {
            var readings = $scope.readRequiredPagesResult.configuration._pageContents;
            var eeprom = '';
            Object.keys(readings).map(function(key, i) {
                eeprom +=  '0x'+key+':'+readings[key];
                if (Object.keys(readings).length > i + 1) {
                    eeprom += ',';
                }
            });
            
            Wmoapi
                .getRsuFeatures($scope.requestId, eeprom)
                    .success(function (response) {
                        var formatedData = [];
                        Object.values( _.groupBy(Object.values(response.Features), 'groupNumber')).map(function(features) {
                            var formatedFeature = {
                                id: features[0].groupNumber,
                                name: features[0].rsuFeatureGroupName,
                                featureConfigurations: []
                            };
                            features.map(function(feature){
                                var pos = features[0].groupNumber + '-' + feature.memberNumber;
                                formatedFeature.featureConfigurations.push({
                                    id: feature.memberNumber,
                                    featureConfigurationId: feature.memberNumber,
                                    description: feature.rsuFeatureName,
                                    selectionType: 'select',
                                    clientSide_originalSelectedOptionId: response.map[pos] ? response.map[pos].option : false,
                                    option: feature.options.map(function(option, index){
                                        var isDefault = feature.defaultOption === index;
                                        return {
                                            id: index,
                                            name: option,
                                            featureConfigurationId: feature.memberNumber,
                                            isDefault: isDefault,
                                            isDefaultFeature: isDefault,
                                            isSelected: response.map[pos] ? (response.map[pos].option === index ? 1 : 0) : feature.defaultOption === index ? 1 : 0
                                        };
                                    })
                                });
                            });
                            formatedData.push(formatedFeature);
                        });
                        var deviceFeatures = formatedData;
                        $rootScope.globalResponseData.deviceFeatures = deviceFeatures;
                        loadSubmenu(deviceFeatures);
                        $rootScope.isGlobalLoading = false;
                    })
                    .error(function (error) {
                        $scope.$parent.showErrorMessageFromServer('GENERAL_ERROR_CREATE_REQUEST', error);
                        $scope.$parent.loadDevicePartial(false);
                    });
        };

        $scope.onReadRequiredPagesComplete = function() {

            var readings = wu.transformPageReadingsToArray($scope.readRequiredPagesResult.configuration);
            $scope.$parent.responseData.configModulePagesReadings = readings;
            $scope.createRequestConfigureModuleSolo();
        };

        $scope.onReadRequiredPagesError = function(){
            //TODO: handle required pages read error
            alertBar.show( 'error', 'FEATURES_ERROR_READ_REQUIRED_PAGES');
            console.log('FEATURES_ERROR_READ_REQUIRED_PAGES');
        };

        $scope.createRequestConfigureModuleSolo = function (){
			var device = DeviceService.getFlashableDevice();
            var bootloader = device.bootloader.version;
            var serial = device.serial;
            var readings = $scope.$parent.responseData.configModulePagesReadings;
            var requestid_configModule = '';


            Wmoapi
                .createRequestConfigureModuleSolo(bootloader,serial,readings)
                .success(function (response) {

                    //$rootScope.isGlobalLoading = false;
                    requestid_configModule = response.requestId;
                    $scope.$parent.request.configModuleRequestId = requestid_configModule;
                    loadVehicleFeatures(requestid_configModule);
                })
                .error(function (error) {
                    $scope.$parent.showErrorMessageFromServer('GENERAL_ERROR_CREATE_REQUEST', error);
                    $scope.$parent.loadDevicePartial(false);
                });
        };

        $scope.getEepromAdress = function (){

            Wmoapi
                .getEepromAddress()
                .success(function(response){
                    $scope.$parent.responseData.configModuleRequiredPages = response.address;
                    $scope.readRequiredPages();
                })
                .error(function(error){
                    //TODO: handle error
                    console.log(error);
                });
        };

        $scope.createRequestLoadFeatures = function (parentRequestId){

            Wmoapi
                .createRequestConfigureModule(parentRequestId)
                .success(function (response) {

                    var requestid_configModule = response.requestId;
                    $scope.$parent.request.configModuleRequestId = requestid_configModule;

                    loadVehicleFeatures(requestid_configModule);
                })
                .error(function (error) {
                    //TODO: handle error
                    console.log(error);
                    $rootScope.isGlobalLoading = false;

                });

        };

        /**
         * Save features in modify features mode only
         */
        $scope.saveFeatures = function (){

            $rootScope.isGlobalLoading = true;
            $scope.setDeviceConfigRequest();

        };


        $scope.cancel = function () {
            $rootScope.isGlobalLoading = true;
            var requestId = $scope.$parent.isConfigRsuFeatures ? $scope.requestId : $scope.$parent.request.configModuleRequestId;
            Request.cancelRequest(requestId,
                function () {
                    $scope.$parent.loadDevicePartial(false);
                }, function () {
                    $scope.$parent.loadDevicePartial(false);
                });

        };

        $scope.setDeviceConfigRequest = function (){

            var configModuleRequestId = $scope.$parent.request.configModuleRequestId;
            var editedFeatures = getEditedFeatures();

            if($scope.$parent.isConfigRsuFeatures) {
                Wmoapi
                    .setStarterConfigRequest($scope.requestId, editedFeatures)
                    .success(function (response) {
                        $scope.downloadConfigModule();
                    })
                    .error(function (error) {
                        $scope.$parent.showErrorMessageFromServer('GENERAL_ERROR_CREATE_REQUEST', error);
                        $scope.$parent.loadDevicePartial(false);
                    });
            } else {
                if(editedFeatures === ''){

                    $scope.downloadConfigModule();

                } else {
                    // If RSU
                        Wmoapi
                            .setDeviceConfigRequest(configModuleRequestId, editedFeatures)
                            .success(function (response) {
        
                                $scope.downloadConfigModule();
        
                            })
                            .error(function (error) {
                                $scope.$parent.showErrorMessageFromServer('GENERAL_ERROR_CREATE_REQUEST', error);
                                $scope.$parent.loadDevicePartial(false);
                            });
                }
            }

        };

        $scope.downloadConfigModule = function(){
            var configModuleRequestId = $scope.$parent.isConfigRsuFeatures ? $scope.requestId : $scope.$parent.request.configModuleRequestId;

            Wmoapi
                .download(configModuleRequestId)
                .success(function(response){

                    $scope.$parent.responseData.configModuleDownload = response;
                    $scope.writeConfigModule();

                })
                .error(function(error){
                    //TODO handle error
                });

        };



        $scope.writeConfigModule = function(){

            var pageContent =  $scope.$parent.responseData.configModuleDownload.body.eepromMap;
            var device = $scope.$parent.isConfigRsuFeatures ? DeviceService.getRsuDevice() : DeviceService.getFlashableDevice();

            if($scope.$parent.isConfigRsuFeatures) {
                var newArray = [];
                pageContent.split(',').map(function(page) {
                    newArray[page.substr(0,2)] = page.substr(2);
                });
                pageContent = newArray;
            }

            $scope.configResult = wu.getWriteModuleResult(device, pageContent, $scope.onWriteConfigModuleComplete, $scope.onWriteConfigModuleError, $scope.$parent.isConfigRsuFeatures);
            $scope.configResult.start();
        };

        $scope.onWriteConfigModuleComplete = function(){

            $scope.confirmConfigModule();

        };

        $scope.onWriteConfigModuleError = function(){
            console.error('Error Features write to eeprom!');
            alertBar.show('error', 'FEATURES_ERROR_READ_REQUIRED_PAGES');
        };

        $scope.confirmConfigModule = function(){

            var configModuleRequestId =   $scope.$parent.isConfigRsuFeatures ? $scope.requestId : $scope.$parent.request.configModuleRequestId;
            var downloadModId =  $scope.$parent.responseData.configModuleDownload.downloadId;
            var title = 'POPUP_FEATURES_SAVED_TITLE';
            var description = 'POPUP_FEATURES_SAVED_DESC';

            Wmoapi
                .confirm(configModuleRequestId,downloadModId)
                .success(function(response){
                    $rootScope.isGlobalLoading = false;
                    console.log('features write complete !!!');
                    messageBox.show(title,description);
                    $scope.$parent.request.configModuleRequestId = '';
                    $scope.$parent.loadDevicePartial();
                })
                .error(function(error){
                    console.error('error in confirmConfigModule:', error);
                });
        };

        $scope.saveFavoriteConfig = function () {
            ngDialog.openConfirm({
                template: 'modules/core/views/partials/favorites/modals/save-favorite.modal.client.view.html',
                className:'ngdialog-theme-plain favorites-modal',
                controller: ['$scope', function ($scope) {
                    $scope.name = '';
                    $scope.description = '';
                    $scope.disableNameField = false;
                    $scope.proceedToFlash = false;

                    $scope.submit = function (favoriteForm) {
                        if (favoriteForm.$valid) {
                            $scope.confirm({name: $scope.name, description: $scope.description});
                        }
                    };
                }]
            }).then(function (result){
                var editedFeatures = getEditedFeatures();
                if(editedFeatures === undefined ||
                    editedFeatures === 'undefined' ||
                    editedFeatures === ''){
                    $scope.skipstep('guides');
                } else {
                    //submit updated features to wmoapi
                    var configModuleRequestId = $scope.$parent.request.configModuleRequestId;
                    $rootScope.isGlobalLoading = true;

                    Wmoapi
                        .setDeviceConfigRequest(configModuleRequestId, editedFeatures)
                        .success(function(response){
                            Wmoapi.saveFavoriteConfigureModule(result.name, result.description, $scope.$parent.request.configModuleRequestId, $scope.$parent.request.id)
                                .then(function (response) {
                                    if(response.data.result === 1) {
                                        messageBox.show('FAVORITE_SAVE_SUCCESS_TITLE', 'FAVORITE_SAVE_SUCCESS_SUBTITLE');
                                        $scope.loadGuidesPartial();
                                    } else {
                                        alertBar.show('errorFull', (response.data.error) ? response.data.error : 'FAVORITE_SAVE_ERROR');
                                    }
                                }, function (error){
                                    alertBar.show('errorFull', (error.data) ? error.data : 'FAVORITE_SAVE_ERROR');
                                });
                        })
                        .error(function(error){
                            alertBar.show('errorFull', (error) ? error : 'FAVORITE_SAVE_ERROR');
                        });
                }
            });
        };

    }
]);
