(function() {
    'use strict';
    angular
        .module('core')
        .directive('detectedDevice', DetectedDevice);

      function DetectedDevice() {
        return {
            restrict:'E',
            scope: {
                device: '=',
                latestRequest: '=',
                lastVehicleFlashed: '=',
                rsuRemoteControl: '=',
                detect: '&',
                retryUpdateBySerial: '=',
                hasImbedded: '=',
                isAntenna: '=',
                isDeviceAccessory: '=',
                isDeviceRfid: '=',
                canFlashByVehicle: '=',
                hasKlonFw: '=',
                hasSuspendedRequest: '=',
                isAlreadyFlashed: '=',
                skipControls: '=',
                isIdatastartRemotePairingCompatible: '=',
                hasFirmwareUpdates: '=',
                hasRsuFirmwareUpdate: '=',
                configureNewModule: '&',
                configModuleOnly: '=',
                configRsuFeatures: '&',
                configRemoteOnly: '&',
                loadKlonProgressPartial: '&',
                doFirmwareUpdate: '=',
                detectedDevices:'=',
                embeddedDevice:'=',
                embeddedDevices:'=',
                rsuDevice:'=',
                telematic:'=',
                getWiringSection: '&',
                getGuidesSection: '&',
                controlPreview:'&',
                controlsObject:'=',
                hasFlexPackageNoBill:'=',
                deviceHasBlankFirmware:'=',
                toggleFlexSlider:'&',
                flexSliderOpen:'=',
                applyFlexUpgrade:'&',
                packageSelection:'=',
                hideControlsCard:'=',
                isClassicDevice:'=',
                isFavoriteActive:'=',
                classicDeviceInstallOption:'=',
                canModifyFeatures:'=',
                reFlashDevice:'=',
                reFlashDeviceBySerial:'=',
                flashFromFavorites: '&',
                flashByFirmware:'=',
                flashByFirmwareEmbeddedDevice:'=',
                filterInternalOn:'=',
                canModifyFeaturesBySerial:'='
          },
          templateUrl:'modules/core/views/directives-partial/detected.device.html',
        };
      }
  })();
