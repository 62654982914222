'use strict';

angular.module('core').factory('translateService', ['$translate', '$locale', 'tmhDynamicLocale',
    function ($translate, $locale, tmhDynamicLocale) {

        return {

            getCurrentLanguage: function () {
                return $translate.use();
            },
            setCurrentLanguage: function (newCurrentLanguage) {
                tmhDynamicLocale.set(newCurrentLanguage + '-ca');
                $translate.use(newCurrentLanguage);
            },
            translate: function (key) {
                return $translate.instant(key);
            }
        };

    }

]);
