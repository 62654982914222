'use strict';

// Init the application configuration module for AngularJS application
var ApplicationConfiguration = (function () {
	// Init module configuration options
	var applicationModuleName = 'Weblink';
	//here we inject any dependcies got installed to avoid error like this : $injector:unpr Unknown Provider
	var applicationModuleVendorDependencies = ['ui.select', 'ngResource', 'ngAnimate', 'ngTouch', 'ngSanitize', 'ngDialog',
		'ui.router', 'ui.bootstrap', 'ui.utils', 'smart-table', 'schemaForm', 'pascalprecht.translate', 'lodashFactory', 'angular-carousel', 'tmh.dynamicLocale'
	];

	// Add a new vertical module
	var registerModule = function (moduleName, dependencies) {
		// Create angular module
		angular.module(moduleName, dependencies || []);

		// Add the module to the AngularJS configuration file
		angular.module(applicationModuleName).requires.push(moduleName);
	};

	return {
		applicationModuleName: applicationModuleName,
		applicationModuleVendorDependencies: applicationModuleVendorDependencies,
		registerModule: registerModule
	};
})();
