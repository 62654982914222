'use strict';

var deviceInteractionImplGlobal;

angular.module('core').factory('deviceDetect', ['deviceIdentifier', 'Wmoapi', '$q','AppService', 'constants', '_', '$location', '$rootScope',
    function (deviceIdentifier, Wmoapi, $q, AppService, constants, _, $location, $rootScope) {
        var deviceDetecter = {};
        var isInWeblinkDesktop = AppService.isPramanthaObjectDetected();

        deviceDetecter.detect = function() {
            var deferred = $q.defer();
            var wu, activeXObj, deviceInteractionImpl, result;
            var detectedDevices;

            if(!isInWeblinkDesktop) {
                try {
                    wu = weblinkupdater.weblinkupdater;
                    activeXObj = wu.weblinkActiveXObject();
                    deviceInteractionImpl = wu.deviceInteractionImpl(activeXObj);
                } catch (e) {
                    deferred.reject('DETECT_NO_PLUGIN_DETECTED');
                }

            }
            try {
                wu = weblinkupdater.weblinkupdater;
                activeXObj = wu.weblinkActiveXObject();
                deviceInteractionImpl = wu.deviceInteractionImpl(activeXObj);
                deviceInteractionImplGlobal = deviceInteractionImpl;
                result = wu.detect(
                    wu,
                    deviceInteractionImpl,
                    deviceIdentifier,
                    function () {
                        detectedDevices = result.devices;
                        var RsuDevice = _.find(detectedDevices, function(item){ return item.bootloader.moduleType === '4'; });
                        if (RsuDevice && isInWeblinkDesktop) {
                            var configProvider = {
                                'provideConfig': function (callback) {
                                    var eepromPages = [];
                                    for (var i=0; i < 256; i++) {
                                        var page = i.toString(16).toUpperCase();
                                        if (i < 16) {
                                            page = '0' + page;
                                        }
                                        eepromPages.push(page);
                                    }
                                    var config = weblinkupdater.Configuration.blankRSUFromPageAddresses(eepromPages);
                                    callback(config);
                                }
                            };
                            var read = wu.readConfiguration(
                                wu,
                                deviceInteractionImpl,
                                configProvider,
                                RsuDevice,
                                function() {
                                    var configuration = read.configuration;
                                    var eeprom = configuration.getPageContents('81');
                                    Wmoapi
                                        .rsuGetManufacturer(eeprom)
                                        .success(function (response) {
                                            var manufacturerId = response.id;
                                            var hardwareAddress = response.hardwareAddress, hardwareSignature = '';
                                            var firmwareAddress = response.firmwareAddress, firmwareSignature = '';

                                            if(hardwareAddress && firmwareAddress) {
                                                var hardwareValues = [];
                                                var firmwareValues = [];
                                                var signature = '';

                                                for (var i = 2; i < hardwareAddress.length; i = i + 2) {
                                                    signature = hardwareAddress.substr(i, 2);
                                                    hardwareValues.push('0x' + signature + ':' + configuration.getPageContents(signature));
                                                }
                                                hardwareSignature = hardwareValues.join();

                                                signature = '';
                                                for (var x = 2; x < firmwareAddress.length; x = x + 2) {
                                                    signature = firmwareAddress.substr(x, 2);
                                                    firmwareValues.push('0x' + signature + ':' + configuration.getPageContents(signature));
                                                }
                                                firmwareSignature = firmwareValues.join();
                                            }

                                            Wmoapi
                                                .rsuGetInformation(manufacturerId, hardwareSignature, firmwareSignature)
                                                .success(function (response) {
                                                    var pos = detectedDevices.findIndex(function(e) {
                                                        return e.bootloader.moduleType === '4';
                                                    });
                                                    Object.assign(detectedDevices[pos], response);
                                                    detectedDevices[pos].serial = constants.RSU_BLANK_SERIAL;
                                                    deferred.resolve(detectedDevices);
                                                })
                                                .catch(function(error) {
                                                    deferred.reject(error);
                                                });
                                        })
                                        .catch(function(error) {
                                            deferred.reject(error);
                                        });
                                }, function(error) {
                                    console.error('error in wu.readConfiguration:', error);
                                    console.error('Read RSU Config failed!');
                                    deferred.reject(error);
                                });

                            read.start();
                            console.log('Read RSU Config Started.....');

                        } else {

                            detectedDevices = _.filter(result.devices, function(device) {return !(device.serial === 'FFFFFFFFFF' || !device.serial);});

                            if (result.devices.length !== detectedDevices.length) {
                                var errorObj = {};

                                console.error('error in wu.detect : some devices did not provide serial number or bootloader');
                                $rootScope.hasInvalidDevice = true;
                                errorObj.errorMsg = 'some devices did not provide serial number or bootloader';
                                errorObj.errorCode = '';
                                deferred.reject(errorObj);
                            } else {
                                deferred.resolve(detectedDevices);

                            }
                        }
                    },
                    function (err, errorCode) {
                        var errorObj = {};
                        console.error('error in wu.detect:', err);
                        errorObj.errorMsg = err && (err.data ? (err.data.error || 'detection process failed') : typeof err === 'string' ? err : 'detection process failed');
                        errorObj.errorCode = err && err.status ? err.status : errorCode ? errorCode : '';
                        deferred.reject(errorObj);
                    }

                );
                wu.getAppVersion(deviceInteractionImpl).then(
                    function (success) {
                        AppService.setFlashConnectionType(success);
                        AppService.setIsHub(success);
                        AppService.setRequiresReboot(false);
                        $rootScope.hasNoFirmware = false;
                        $rootScope.forcedUpdate = false;
                        if (isInWeblinkDesktop) {
                            if (success.isHub) {
                                var hubDevices = [];
                                var onDeviceInfoComplete = function (device) {
                                    var hub = device.data[0].device;
                                    if (deviceIdentifier.hasFirmwareUpdate(hub).hasUpdate) {
                                        AppService.setRequiresReboot(true);
                                    }

                                    if(success.hubSerial[0] === hub.serial) {
                                        AppService.setHubFirmwareId(hub.firmwareID);
                                        $rootScope.forcedUpdate = hub.isFirmwareUpdateForced;
                                    }

                                    $rootScope.hasNoFirmware = deviceIdentifier.hasFirmwareUpdate(hub).hasBlankFirmware && success.hubSerial[0] === hub.serial;
                                    hubDevices.push(hub);
                                };

                                var onDeviceInfoError = function (error) {
                                    AppService.setWeblinkDesktopStatus(null);
                                    console.error('getDeviceInfoBySerial error:', error);
                                    if (error.status === 401) {
                                        $location.path('login');
                                    }
                                };

                                var range = _.range(success.hubSerial.length);
                                AppService.setHubSerial(success.hubSerial[0]);
                                Promise.all(range.map(function(i){
                                    return Wmoapi.getDeviceInfoBySerial(success.hubSerial[i]).then(
                                        onDeviceInfoComplete,
                                        onDeviceInfoError
                                    );
                                })).then(function(){
                                    if ($rootScope.globalUser && $rootScope.globalUser.profile) {

                                        if ($rootScope.globalUser.profile.userRoleId === constants.INTERNAL_USER_ROLE_ID && !$rootScope.hasNoFirmware) {
                                            result.start();
                                        } else if ($rootScope.globalUser.profile.userRoleId === constants.BETA_USER_ROLE_ID && !$rootScope.hasNoFirmware && !$rootScope.forcedUpdate) {
                                            result.start();
                                        } else if (AppService.getRequiresReboot()) {
                                            deferred.resolve(hubDevices);
                                        } else {
                                            result.start();
                                        }

                                    } else {
                                        if (AppService.getRequiresReboot()) {
                                            deferred.resolve(hubDevices);
                                        } else {
                                            result.start();
                                        }
                                    }
                                }).catch(function (error) {
                                    console.error('getDeviceInfoBySerial error:', error);
                                });
                            } else {
                                result.start();
                            }

                            Wmoapi
                                .validateWdVersion(success.version)
                                .success(function (response) {
                                    AppService.setWeblinkDesktopStatus(response);
                                })
                                .error(function (error) {
                                    AppService.setWeblinkDesktopStatus(null);
                                    console.error('validateWdVersion error:', error);
                                });
                        } else {
                            result.start();
                        }
                    }, function (error) {
                        console.error('error in wu.getAppVersion ', error);
                        result.start();
                    });




            }
            catch(ea) {
                deferred.reject(ea);
            }

            return deferred.promise;
        };


        deviceDetecter.manageUserPlugin = function() {
            var devMode = AppService.isDemoMode();

            var deferred = $q.defer();
            var activeXObj, wu, deviceInteractionImpl, result;

            try {
                wu = weblinkupdater.weblinkupdater;
                wu.mockEnabled = devMode;
                activeXObj = wu.weblinkActiveXObject();
                deviceInteractionImpl = wu.deviceInteractionImpl(activeXObj);
            } catch(e) {
                deferred.reject('DETECT_NO_PLUGIN_DETECTED');
            }

            Wmoapi
                .getUserPlugin()
                .success(function(response){
                    deferred.resolve(response);
                })
                .error(function(error) {//no key in session

                if(isInWeblinkDesktop) {

                    wu.getAuthKey(deviceInteractionImpl).then(
                        function (response) {

                            var key = response;
                            if (key === '') { //no key in activex
                                Wmoapi
                                    .addUserPlugin()
                                    .success(function (response) {
                                        key = response.key;
                                        if (!wu.setAuthKey(deviceInteractionImpl, key)) {
                                            deferred.reject('HOME_ERROR_SETAUTHKEY');
                                        } else {
                                            deferred.resolve(response);
                                        }
                                    })
                                    .error(function (error) {
                                        deferred.reject('HOME_ERROR_ADDUSERPLUGIN');
                                    });

                            } else { //set key to session

                                Wmoapi
                                    .setUserPlugin(key)
                                    .success(function (response) {
                                        deferred.resolve(response);
                                    })
                                    .error(function (error) {
                                        deferred.reject('HOME_ERROR_SETUSERPLUGIN');
                                    });
                            }


                        },
                        function (error) {
                            console.error('getAuthKey error:', error);
                        });
                } else {
                    var activexKey = wu.getAuthKey(deviceInteractionImpl);
                    if (activexKey === '') { //no key in activex

                        Wmoapi
                            .addUserPlugin()
                            .success(function (response) {

                                activexKey = response.key;

                                if (!wu.setAuthKey(deviceInteractionImpl, activexKey)) {
                                    deferred.reject('HOME_ERROR_SETAUTHKEY');
                                } else {
                                    deferred.resolve(response);
                                }

                            })
                            .error(function (error) {
                                deferred.reject('HOME_ERROR_ADDUSERPLUGIN');
                            });
                    } else { //set key to session

                        Wmoapi
                            .setUserPlugin(activexKey)
                            .success(function (response) {
                                deferred.resolve(response);
                            })
                            .error(function (error) {
                                deferred.reject('HOME_ERROR_SETUSERPLUGIN');
                            });
                    }
                }

                });
                return deferred.promise;
        };

        return deviceDetecter;
    }]
);
