'use strict';

var HARNESS_SERIAL_REGEXP = /^K[A-F0-9]{9}$|^P[A-F0-9]{9}$|^[A-F0-9]{10}$/;
angular.module('core').directive('harnessSerial', [ function() {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {

            ctrl.$parsers.push(function(value) {

                var transformedInput = value.substring(0, 1).toUpperCase().replace(/[^0-9A-FPK]*/g, '');
                transformedInput += value.substring(1).toUpperCase().replace(/[^0-9A-F]*/g, '');

                if (transformedInput !== value) {
                    ctrl.$setViewValue(transformedInput);
                    ctrl.$render();
                }
                return transformedInput;
            });

            ctrl.$validators.serial = function(modelValue, viewValue) {
                return HARNESS_SERIAL_REGEXP.test(modelValue);
            };
        }
    };
}]);
