'use strict';

angular.module('core').factory('AppService', ['$http', 'constants',
    function ($http, constants) {

        var app_version = '';
        var demo_mode = false;
        var is_in_dev = false;
        var is_hub = false;
        var hub_serial = '';
        var hub_firmware_id = '';
        var requires_reboot = false;
        var firmware_skipped = false;
        var flex_upgrade_active = false;
        var announcement_klon_outage_active = false;
        var announcement_3_lock_active = false;
        var announcement_ie_migration_active = false;
        var flash_from_favorite_active = false;

        var virtual_device = '';
        var flash_connection_type = '';
        var remotes_skipped = false;
        var standaloneFlags = {isRf: false, isTelematic: false};
        var weblinkDesktopRes = {hasUpdate: false , isCritical:false};
        /* globals BASE_URL */
        var base_url = BASE_URL + '/' || '';
        var facebook_enable = false;
        var facebook_app_id = '';

        return {
            getStatus: function (baseUrl) {
                return $http.get(baseUrl + 'app/getStatus');
            },
            setStatus: function (response) {
                if (response) {
                    is_in_dev = response.is_in_dev;
                    app_version = response.app_version;
                    demo_mode = response.demo_mode;
                    flex_upgrade_active = response.flex_upgrade_active;
                    announcement_klon_outage_active = response.announcement_klon_outage_active;
                    announcement_3_lock_active = response.announcement_3_lock_active;
                    announcement_ie_migration_active = response.announcement_ie_migration_active;
                    flash_from_favorite_active = response.flash_from_favorite_active;
                    virtual_device = response.virtual_device;
                    facebook_enable = response.facebook_enable;
                    facebook_app_id = response.facebook_app_id;
                }
            },
            getFacebookEnable: function () {
                return facebook_enable;
            },
            getFacebookAppId: function () {
                return facebook_app_id;
            },
            getAppVersion: function () {
                return app_version;
            },
            getVirtualDevice: function () {
                return virtual_device;
            },
            isDemoMode: function () {
                return demo_mode;
            },
            isInDev: function () {
                return is_in_dev;
            },
            isFlexUpgradeActive: function () {
                return flex_upgrade_active;
            },
            isAnnouncementIEMigrationActive: function () {
                return announcement_ie_migration_active;
            },
            isAnnouncementKlonOutageActive: function () {
                return announcement_klon_outage_active;
            },
            isAnnouncement3LockActive: function () {
                return announcement_3_lock_active;
            },
            isFavoriteActive: function () {
                return flash_from_favorite_active;
            },
            isFirmwareSkipped: function () {
                return firmware_skipped;
            },
            setFirmwareSkipped: function (firmwareSkipped) {
                firmware_skipped = firmwareSkipped;
            },

            usedAsAddOn: function () {
                return remotes_skipped;
            },
            setAddOn: function (skipRemotes) {
                remotes_skipped = skipRemotes;
            },

            getStandaloneFlags: function () {
                return standaloneFlags;
            },
            setStandaloneFlags: function (flag) {
                remotes_skipped =  false;
                standaloneFlags = {isRf: false, isTelematic: false};
                standaloneFlags[flag] = true;
            },



            isPramanthaObjectDetected: function () {
                return typeof window.pramantha === 'object';
            },
            getBaseUrl: function () {
                return base_url;
            },
            getFlashConnectionType: function () {
                return flash_connection_type;
            },
            setFlashConnectionType: function (object) {

                switch (object.connectionType) {
                    case constants.ACTIVEX:
                        flash_connection_type = constants.ACTIVEX;
                        break;
                    case constants.DEMO_MODE:
                        flash_connection_type = constants.DEMO_MODE;
                        break;
                    case constants.WEBLINK_UPDATER:
                        flash_connection_type = constants.WEBLINK_UPDATER;
                        break;
                    case constants.USB:
                        flash_connection_type = constants.USB;
                        break;
                    case constants.HUB:
                        flash_connection_type = constants.HUB;
                        break;
                    default:
                        flash_connection_type = '';
                }
            },
            getIsHub: function () {
                return is_hub;
            },
            setIsHub: function (object) {
                is_hub = object.isHub;
            },
            getHubSerial: function () {
                return hub_serial;
            },
            setHubSerial: function (value) {
                hub_serial = value;
            },
            getHubFirmwareId: function () {
                return hub_firmware_id;
            },
            setHubFirmwareId: function (value) {
                hub_firmware_id = value;
            },
            getRequiresReboot: function(){
                return requires_reboot;
            },
            setRequiresReboot: function(value){
                requires_reboot = value;
            },
            setWeblinkDesktopStatus: function (object) {
                weblinkDesktopRes = object;
            },
            getWeblinkDesktopStatus: function () {
                return weblinkDesktopRes;
            },
            getBrowserType: function () {
                switch (true) {
                    case test(/edg/i):
                        return 'Edge';
                    case test(/trident/i):
                        return 'Internet Explorer';
                    case test(/firefox|fxios/i):
                        return 'Firefox';
                    case test(/opr\//i):
                        return 'Opera';
                    case test(/ucbrowser/i):
                        return 'UC Browser';
                    case test(/samsungbrowser/i):
                        return 'Samsung Browser';
                    case test(/chrome|crios/i):
                        return 'Chrome';
                    case test(/chromium/i):
                        return 'Chromium';
                    case test(/safari/i):
                        return 'Safari';
                    default:
                        return 'Other';
                }
                
                function test (regexp) {
                    return regexp.test(window.navigator.userAgent);
                }
            },
        };
    }
]);
