'use strict';

angular.module('core').controller('PartialsFavoritesController', ['$scope', 'Wmoapi','messageBox', '$rootScope', 'alertBar', '$q', 'DeviceService', 'sidebarManager', 'deviceIdentifier', 'ngDialog', 'Request', '_', 'guidesService', '$sanitize',
    function($scope, Wmoapi, messageBox, $rootScope, alertBar, $q, DeviceService, sidebarManager, deviceIdentifier, ngDialog, Request, _, guidesService, $sanitize) {

        $scope.favorites = [];
        $scope.configOnly = false;
        $scope.backSkipStep = 'device';
        $scope.subtitle = 'FAVORITE_LIST_SUBTITLE';
        $scope.hideIncompatibleFavorites = true;
        $scope.hasIncompatibleFavorites = false;
        var mainDevice = DeviceService.getFlashableDevice();
        var rsuDevice = DeviceService.getRsuDevice();

        $scope.initFavorites = function () {
            $scope.configOnly = $scope.$parent.configurationParameters.flashFromFavoriteConfig;
            const favoriteType = $scope.configOnly ? 'feature_configuration' : 'flash_vehicle';
            $scope.backSkipStep = $scope.configOnly ? 'oem-remote-start' : 'device';
            $scope.subtitle = $scope.configOnly ? 'FAVORITE_CONFIG_LIST_SUBTITLE' : 'FAVORITE_LIST_SUBTITLE';

            // TODO: Sort by compatible & recently used

            $rootScope.isGlobalLoading = true;
            alertBar.hide();
            var device = DeviceService.getFlashableDevice();

            Wmoapi.getFavoriteList(device.serial, favoriteType).then(
                function (response){
                    $scope.favorites = response.data.favorites;
                    for (var i = 0; i < $scope.favorites.length; ++i){
                        $scope.favorites[i].createdDate = Date.parse($scope.favorites[i].createdDate);
                        $scope.favorites[i].lastUpdate = Date.parse($scope.favorites[i].lastUpdate);
                        if ($scope.favorites[i].vehicle){
                            $scope.favorites[i].vehicle.description = `${$scope.favorites[i].vehicle.vehicleMake} ${$scope.favorites[i].vehicle.vehicleModel} ${$scope.favorites[i].vehicle.vehicleYear} - ${$scope.favorites[i].vehicle.vehicleProperties}`;
                        }
                    }

                    $scope.favorites.sort(function(a, b) {
                        return b.isCompatible - a.isCompatible;
                    });

                    $scope.hasIncompatibleFavorites = $scope.favorites.filter(favorite => favorite.isCompatible).length !== $scope.favorites.length;
                    $rootScope.isGlobalLoading = false;
                },
                function(error) {
                    alertBar.show( 'error', 'FAVORITE_LIST_ERROR', error );
                    $rootScope.isGlobalLoading = false;
                }
            );


        };

        $scope.clickFavorite = function(id) {
            $rootScope.isGlobalLoading = true;
            alertBar.hide();

            if ($scope.configOnly) {
                useFavoriteConfig(id);
            } else {
                checkForOutdatedFavorite(id);
            }

        };

        function useFavoriteConfig(id) {
            Wmoapi.favoriteLoadFeatureConfig($scope.$parent.request.configModuleRequestId, id).then(function (response) {
                if(response.data.result === 1) {
                    $scope.$parent.loadFeaturePartial();
                }
            }, function (error) {
                alertBar.show('error', 'FAVORITE_FLASH_ERROR', error);
            });
        }

        function checkForOutdatedFavorite (id) {

            var favorite = $scope.favorites.find(favorite => favorite.id === id);

            if (favorite && favorite.hasFirmwareUpdate) {
                $scope.updateFavorite(id);
            } else if (favorite) {
                flashFromFavorite(id);
            } else {
                alertBar.show('error', 'FAVORITE_FLASH_ERROR');
            }
        }

        function flashFromFavorite (id) {
            var detectedDevice = DeviceService.getFlashableDevice();
            Wmoapi.flashVehicleFromFavorite(id, detectedDevice.serial, detectedDevice.bootloader.version).then(
                function (response){

                    $rootScope.isGlobalLoading = false;
                    var vehicle = response.data.vehicle;
                    var firmware = response.data.firmware;
                    var telematic = response.data.telematic;
                    var oemRemoteControlConfig = response.data.oemRemoteControlConfig;

                    Request.setRequestDevice(response.data.requestId, detectedDevice.bootloader, detectedDevice.serial);
                    $scope.$parent.request.id = response.data.requestId;
                    $scope.$parent.request.configModuleRequestId = response.data.requestConfigureId;
                    $scope.$parent.configurationParameters.flashFromFavorite = true;

                    $scope.$parent.selectedVehicle.makeId = vehicle.vehicleMakeId;
                    $scope.$parent.selectedVehicle.makeName = vehicle.vehicleMake;
                    $scope.$parent.selectedVehicle.year = vehicle.vehicleYear;
                    $scope.$parent.selectedVehicle.yearName = vehicle.vehicleYear;
                    $scope.$parent.selectedVehicle.modelId = vehicle.vehicleModelId;
                    $scope.$parent.selectedVehicle.modelName = vehicle.vehicleModel;
                    $scope.$parent.selectedVehicle.modelImage = response.data.modelImage;
                    $scope.$parent.selectedVehicle.vehicleId = vehicle.vehicleId;
                    $scope.$parent.selectedVehicle.vehicleName = vehicle.vehicleProperties;
                    $scope.$parent.selectedVehicle.firmwareFamilyId = firmware.familyId;
                    $scope.$parent.selectedVehicle.firmwareFamilyName = firmware.familyName;
                    $scope.$parent.selectedVehicle.firmwareId = firmware.id;
                    $scope.$parent.selectedVehicle.firmwareName = firmware.name;
                    $scope.$parent.selectedVehicle.firmwareVersion = firmware.version;

                    $scope.$parent.userSelection.selectedFirmware = firmware;
                    $scope.$parent.responseData.currentFirmware = firmware;

                    if (telematic) {
                        $scope.$parent.userSelection.telematicId = telematic.id;
                        $scope.$parent.userSelection.telematicName = telematic.name;
                        $scope.$parent.userSelection.telematicImagePhone = telematic.images.phone;
                    }

                    if (oemRemoteControlConfig) {
                        $scope.$parent.userSelection.oemRemote = {
                            id: oemRemoteControlConfig.feature_configuration_id,
                            name: oemRemoteControlConfig.feature_configuration_option_name
                        };
                    }

                    var ownerLink = '';
                    if (guidesService.ownerGuidesHash[mainDevice.productId]){
                        ownerLink = guidesService.ownerGuidesHash[mainDevice.productId];
                    }

                    if (!$scope.$parent.isRusWithNoBlade) {
                        var rsuId = rsuDevice ? rsuDevice.Product.id : 0;

                        Wmoapi.showButtonRsuGuide(mainDevice.serial, rsuId).then(function(rsuGuideResponse){
                            $scope.hasRsuGuides = rsuGuideResponse.data;
                            parseDeviceGuides(response.data.installGuideUrl, ownerLink, mainDevice.productGuideDownloadUrl, mainDevice.productName);
                        }, function(error){
                            console.error('error in checkRsuGuides:', error);
                        });
                    } else {
                        parseDeviceGuides(response.data.installGuideUrl, ownerLink, mainDevice.productGuideDownloadUrl, mainDevice.productName);
                    }


                    getAccessories($scope.$parent.request.id, $scope.$parent.selectedVehicle.firmwareId).then(
                        function (data) {
                            var availableTHarnesses = getAvailableTHarnesses();
                            if (availableTHarnesses.length > 0) {
                                showModalBoxTHarness(availableTHarnesses);
                            } else {
                                getFirmwarePosts();
                            }
                        }
                    );

                },
                function(error) {
                    alertBar.show('error', 'FAVORITE_FLASH_ERROR', error);
                }
            );
        }

        $scope.updateFavorite = function (id) {

            alertBar.hide();
            var favorite = $scope.favorites.find(favorite => favorite.id === id);

            if(favorite) {
                ngDialog.openConfirm({
                    template: 'modules/core/views/partials/favorites/modals/update-favorite.modal.client.view.html',
                    className:'ngdialog-theme-plain favorites-modal',
                    controller: ['$scope', function ($scope) {
                        $scope.name = favorite.name;
                        $scope.id = favorite.id;

                        $scope.onFavoriteDelete = function (id) {
                            deleteFavorite(id);
                            $scope.closeThisDialog();
                        };
                    }]
                }).then(flashFromFavorite, $scope.initFavorites);
            } else {
                alertBar.show('error', 'FAVORITE_FLASH_ERROR');
            }

        };

        $scope.editFavorite = function(id) {
            alertBar.hide();
            var allowNameEdit = $scope.configOnly;
            var favorite = $scope.favorites.find(favorite => favorite.id === id);

            if (favorite) {
                ngDialog.openConfirm({
                    template: 'modules/core/views/partials/favorites/modals/edit-favorite.modal.client.view.html',
                    className: 'ngdialog-theme-plain favorites-modal',
                    controller: ['$scope', function ($scope) {
                        $scope.defaultName = $sanitize(favorite.name);
                        $scope.name = $sanitize(favorite.name);
                        $scope.description = $sanitize(favorite.description);
                        $scope.id = favorite.id;
                        $scope.disableNameField = !allowNameEdit;
                        $scope.proceedToFlash = false;

                        $scope.submitForm = function (form) {
                            if (form.$valid) {
                                $scope.confirm({name: $scope.name, description: $scope.description, id: $scope.id});
                            }
                        };
                    }]
                }).then(editFavorite, $scope.initFavorites);
            } else {
                alertBar.show('error', 'FAVORITE_EDIT_ERROR');
            }
        };

        $scope.deleteFavorite = function(id) {
            alertBar.hide();
            var favorite = $scope.favorites.find(favorite => favorite.id === id);

            if (favorite) {
                ngDialog.openConfirm({
                    template: 'modules/core/views/partials/favorites/modals/delete-favorite.modal.client.view.html',
                    className:'ngdialog-theme-plain',
                    controller: ['$scope', function ($scope) {
                        $scope.name = favorite.name;
                        $scope.id = favorite.id;
                    }]
                }).then(deleteFavorite, $scope.initFavorites);
            } else {
                alertBar.show('error', 'FAVORITE_DELETE_ERROR');
            }

        };

        function editFavorite (result) {
            $rootScope.isGlobalLoading = true;
            Wmoapi.updateFavorite(result.id, result.name, result.description).then(
                function (response) {
                    if (response.data.result === 1) {
                        $scope.initFavorites();
                    } else {
                        throw response.data;
                    }
                },
                function (error){
                    alertBar.show('error', 'FAVORITE_EDIT_ERROR', error);
                }
            );
        }

        function deleteFavorite(id) {
            $rootScope.isGlobalLoading = true;
            Wmoapi.deleteFavorite(id).then(
                function (response){
                    if (response.data.result === 1){
                        $scope.initFavorites();
                    } else {
                       throw response.data;
                    }
                },
                function(error) {
                    alertBar.show('error', 'FAVORITE_DELETE_ERROR', error);
                }
            );
        }

        function showModalBoxTHarness (harnesses) {
            var analogHarness = $scope.$parent.responseData.currentAccessories.isHardwired;
            ngDialog.openConfirm({
                template: 'modules/core/views/partials/firmware/directives/tharnessselection.directive.view.html',
                className: 'ngdialog-theme-plain',
                closeByDocument: false,
                showClose: false,
                controller: ['$scope', function ($scope) {
                    $scope.harnessOptions = harnesses;
                    $scope.analogHarness = analogHarness;
                    $scope.selectedHarness = 0;

                    $scope.harnessClick = function (harnessId) {
                        $scope.selectedHarness = harnessId;
                    };

                    $scope.event = function () {
                        setTHarnessSelection($scope.selectedHarness === 99999 ? null : $scope.selectedHarness, $scope.selectedHarness === 99999);
                    };
                }]
            }).then(function (result) {
                // user did something else
            }, function (error) {
                // user closed dialog - do nothing
            });
        }

        function getAccessories(parentRequestId, firmwareId) {
            var deferred = $q.defer();
            Wmoapi.getAccessories(parentRequestId, firmwareId)
                .then(function(success) {
                    $scope.$parent.responseData.currentAccessories = success.data;
                    deferred.resolve(success);
                }, function(error) {
                    deferred.reject(error);
                });


            return deferred.promise;
        }

        function getAvailableTHarnesses() {
            return _.filter(_.concat($scope.$parent.responseData.currentAccessories.optional, $scope.$parent.responseData.currentAccessories.required), ['isProductCategoryTHarness' , true]);
        }

        function setTHarnessSelection(accessoryId, isHardwired){
            $rootScope.isGlobalLoading = true;
            var parentRequestId = $scope.$parent.request.id;
            Wmoapi.setTHarness(parentRequestId, accessoryId, isHardwired).then(
                function () {
                    ngDialog.closeAll();
                    $rootScope.isGlobalLoading = false;
                    getFirmwarePosts();
                });
        }

        function getFirmwarePosts () {
            var parentRequestId = $scope.$parent.request.id;

            Wmoapi
                .getFirmwarePosts(parentRequestId)
                .success(function (response) {
                    $scope.$parent.responseData.firmwarePosts = response;
                })
                .error(function (error) {
                    console.error('error getFirmwarePosts in favorites', error);
                    $scope.$parent.responseData.firmwarePosts = [];
                }).finally(function () {
                $rootScope.isGlobalLoading = false;
                $scope.$parent.loadFlashPartial(false, false);
            });
        }

        function parseDeviceGuides (installLink, ownerLink, productLink, productName) {
            var guides = [], guideObj;

            if(installLink && installLink !== '') {
                guideObj = guidesService.extractInstallGuideFromUrl(installLink);
                guides.push(
                    {
                        'id': '1',
                        'name': '',
                        'type': 'GUIDES_TYPE_INSTALLGUIDE',
                        'filename': guideObj.filename,
                        'version': guideObj.version,
                        'link': guideObj.url,
                        'isNewVersion': 0,
                        'language': guideObj.language
                    }
                );
            }
            if(ownerLink){
                guideObj = guidesService.extractOwnerGuideFromUrl(ownerLink);
                guides.push({
                    'id': '2',
                    'name': '',
                    'type': 'GUIDES_TYPE_OWNERMANUAL',
                    'filename': guideObj.filename,
                    'version': '',
                    'link': guideObj.url,
                    'isNewVersion': 0,
                    'language': ''
                });
            }
            if(productLink){
                guides.push({
                    'id': '3',
                    'name': '',
                    'type': 'GUIDES_TYPE_PRODUCTGUIDE',
                    'filename': productName,
                    'version': '',
                    'link': productLink,
                    'isNewVersion': 0,
                    'language': ''
                });
            }
            $scope.$parent.responseData.deviceGuides = guides;
        }

    }]);
