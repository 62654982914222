'use strict';

angular.module('core').factory('Wmoapi', ['$http', 'AppService', 'constants',
    function ($http, AppService, constants) {

        function addFirmwareHubId(data) {
            if (AppService.getIsHub()) {
                data.hub_firmware_id = AppService.getHubFirmwareId();
            }
        }

        var wmoapi = {};

        var headersNoCache = {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        };


        wmoapi.createRequest = function (bootloader, serial) {

            var data = headersNoCache;
            data.bootloader = bootloader;
            data.serial = serial;
            data.type = 'flash-module-by-vehicle';
            data.flash_connection_type = AppService.getFlashConnectionType();

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

        wmoapi.createRequestFlashByFw = function (bootloader, serial, parentRequestId) {

            var data = {
                parent_request_id: parentRequestId,
                bootloader: bootloader,
                serial: serial,
                type: 'flash-module-by-firmware',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);
        };

        wmoapi.createRequestFlashByFwNoParent = function (bootloader, serial) {

            var data = {
                bootloader: bootloader,
                serial: serial,
                type: 'flash-module-by-firmware',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);
        };

        /**
         * Create Request for trial activation mode
         * @param flashRequestId main antenna flash request id
         * @returns {HttpPromise} requestId
         */
        wmoapi.createRequestTrialActivation = function (antennaFlashRequestId, email) {

            var data = {
                flash_request_id: antennaFlashRequestId,
                type: 'configure-trial-activation',
                email: email,
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };


        /**
         * Create Request for Klon
         *
         * @param bootloader
         * @param serial
         * @param flashRequestId latest request id
         * @param flashRequestType latest request type
         * @returns {HttpPromise} klon RequestId
         */
        wmoapi.createRequestKlon = function (bootloader, serial, flashRequestId, flashRequestType) {

            var data = {
                bootloader: bootloader,
                serial: serial,
                request_id: flashRequestId,
                type: flashRequestType + '-klon',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

        /**
         * Create Request for Configure Remotes
         * @param flashRequestId parent request id
         * @returns {HttpPromise} remotesRequestId
         */
        wmoapi.createRequestConfigureRemotes = function (flashRequestId) {

            var data = {
                flash_request_id: flashRequestId,
                type: 'configure-remotes',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

        /**
         * Create Request for Configure Remotes without flash process
         * @param bootloader
         * @param serial
         * @returns {HttpPromise}
         */
        wmoapi.createRequestConfigureRemotesSolo = function (bootloader, serial) {

            var data = {
                bootloader: bootloader,
                serial: serial,
                type: 'configure-remotes',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };


        /**
         * Create Request for Configure Remotes without flash process
         * @param bootloader
         * @param serial
         * @param requestId
         * @returns {HttpPromise}
         */
        wmoapi.createRequestConfigureAntenna = function (bootloader, serial, requestId) {

            var data = {
                bootloader: bootloader,
                serial: serial,
                type: 'flash-module-by-firmware',
                parent_request_id: requestId,
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };


        /**
         * Create Request for Configure Remotes without flash process
         * @param bootloader
         * @param serial
         * @param packageChangeId
         * @returns {HttpPromise}
         */
        wmoapi.createRequestFlexAntennaUpgrade = function (bootloader, serial, packageChangeId) {

            var data = {
                bootloader: bootloader,
                serial: serial,
                type: 'flash-module-by-firmware',
                package_change_id: packageChangeId,
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };


        /**
         * Create Request for Configure Remotes without flash process
         * @param bootloader
         * @param serial
         * @param packageChangeId
         * @returns {HttpPromise}
         */
        wmoapi.createRequestBootloaderUpgrade = function (bootloader, serial) {

            var data = {
                bootloader: bootloader,
                serial: serial,
                type: 'update-bootloader',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

        /**
         * Create Request for firmware update
         * @param bootloader
         * @param serial
         * @returns {HttpPromise}
         */
        wmoapi.createRequestFirmwareUpdate = function (bootloader, serial) {

            var data = {
                bootloader: bootloader,
                serial: serial,
                type: 'update-module',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

         /**
         * Create Request for rsu firmware update
         * @param rsuManufacturer
         * @param rsuProduct
         * @returns {HttpPromise}
         */
        wmoapi.createRequestRsuFirmwareUpdate = function (rsuManufacturerId, rsuProductId) {

            var data = {
                rsuManufacturer: rsuManufacturerId,
                rsuProduct: rsuProductId,
                type: 'flash-starter',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

        /**
         * Create Request for Configure Module
         * @param flashRequestId parent request id
         * @returns {HttpPromise} requestId
         */
        wmoapi.createRequestConfigureModule = function (flashRequestId) {

            var data = {
                flash_request_id: flashRequestId,
                type: 'configure-module',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

        /**
         * Create Request for Configure Module
         * @param flashRequestId parent request id
         * @param pageReadings array of eeprom page and the reading ie: [ { '08':'FFFFFFFFFFFFFFFFFFFFFFFFFF0DFFFF' }, { '09':'15F830A3004C769823A13355029F0CF2' } ]
         * @returns {HttpPromise} requestId
         */
        wmoapi.createRequestConfigureModuleFlashBase = function (flashRequestId, pageReadings) {

            var data = {
                flash_request_id: flashRequestId,
                type: 'configure-module',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            var eeprompage, eepromval;

            for (var i = 0; i < pageReadings.length; i++) {
                var obj = pageReadings[i];

                for (var key in obj) {
                    eeprompage = key;
                    eepromval = obj[key].toString();
                }
                data['eeprom-pages[' + eeprompage + ']'] = eepromval;
            }

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

        /**
         * Create Request for Configure RSU
         * @param rsuManufacturerId
         * @param rsuProductId
         * @param rsuFirmwareId
         * @returns {HttpPromise} requestId
         */
        wmoapi.createRequestConfigureRsu = function (rsuManufacturerId, rsuProductId, rsuFirmwareId) {

            var data = {
                rsuManufacturer: rsuManufacturerId,
                rsuProduct: rsuProductId,
                rsuFirmware: rsuFirmwareId,
                type: 'configure-starter',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

        /**
         * Create Request for Configure RSU
         * @param rsuManufacturerId
         * @param rsuProductId
         * @param rsuFirmwareId
         * @returns {HttpPromise} requestId
         */
        wmoapi.createRequestFlashExternalMemory = function (serial, flashRequestId) {

            var data = {
                serial: serial,
                flash_request_id: flashRequestId,
                type: 'flash-external-memory',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

         /**
         * Set starter config
         * @param requestId
         * @param updates
         * @returns {HttpPromise} requestId
         */
        wmoapi.setStarterConfigRequest = function (requestId, updates) {

            return $http.get(AppService.getBaseUrl() + 'request/setStarterConfigRequest?request_id='+requestId+'&updates='+updates, headersNoCache);

        };

        /**
         * Get RSU feature addresses
         * @param requestId
         * @param rusFirmwareId
         * @returns {HttpPromise} requestId
         */
        wmoapi.getRsuFeatureAddresses = function (requestId, rusFirmwareId) {

            return $http.get(AppService.getBaseUrl() + 'starter/getFeatureAddresses?request_id=' + requestId, headersNoCache);
        };

        /**
         * Get RSU features
         * @param requestId
         * @param eeprom
         * @returns {HttpPromise} requestId
         */
        wmoapi.getRsuFeatures = function (requestId, eeprom) {

            return $http.get(AppService.getBaseUrl() + 'starter/getFeatures?request_id=' + requestId + '&eeprom=' + eeprom, headersNoCache);
        };

        /**
         * Create Request for Configure Module
         * @param bootloader
         * @param serial
         * @param pageReadings array of eeprom page and the reading ie: [ { '08':'FFFFFFFFFFFFFFFFFFFFFFFFFF0DFFFF' }, { '09':'15F830A3004C769823A13355029F0CF2' } ]
         * @returns {HttpPromise} requestId
         */
        wmoapi.createRequestConfigureModuleSolo = function (bootloader, serial, pageReadings) {

            var data = {
                bootloader: bootloader,
                serial: serial,
                type: 'configure-module',
                flash_connection_type: AppService.getFlashConnectionType()
            };

            addFirmwareHubId(data);

            var eeprompage, eepromval;

            for (var i = 0; i < pageReadings.length; i++) {
                var obj = pageReadings[i];

                for (var key in obj) {
                    eeprompage = key;
                    eepromval = obj[key].toString();
                }
                data['eeprom-pages[' + eeprompage + ']'] = eepromval;
            }

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

        /**
         * Create Request for Configure Module after Update Module
         * @param bootloader
         * @param serial
         * @param pageReadings array of eeprom page and the reading ie: [ { '08':'FFFFFFFFFFFFFFFFFFFFFFFFFF0DFFFF' }, { '09':'15F830A3004C769823A13355029F0CF2' } ]
         * @param updateModuleRequestId the parent request id of the type: update-module
         * @returns {HttpPromise} requestId
         */
        wmoapi.createRequestConfigureModuleSoloFwUpdate = function (bootloader, serial, pageReadings, updateModuleRequestId) {

            var data = {
                bootloader: bootloader,
                serial: serial,
                type: 'configure-module',
                flash_connection_type: AppService.getFlashConnectionType(),
                update_module_request_id: updateModuleRequestId
            };

            addFirmwareHubId(data);

            var eeprompage, eepromval;

            for (var i = 0; i < pageReadings.length; i++) {
                var obj = pageReadings[i];

                for (var key in obj) {
                    eeprompage = key;
                    eepromval = obj[key].toString();
                }
                data['eeprom-pages[' + eeprompage + ']'] = eepromval;
            }

            return $http.post(AppService.getBaseUrl() + 'request/create', data);

        };

        wmoapi.login = function (username, password, language) {
            var data = {
                username: username,
                password: password,
                language: language,
                isInWeblinkDesktop: AppService.isPramanthaObjectDetected()
            };
            return $http.post(AppService.getBaseUrl() + 'login', data);
        };

        wmoapi.preLogin = function () {
            return $http.get(AppService.getBaseUrl() + 'pre-login');
        };

        wmoapi.socialLogin = function (type, token, language) {

            var data = headersNoCache;
            var strongconnector = {};
            strongconnector.type = type;
            strongconnector.token = token;
            data.strongconnector = strongconnector;
            data.language = language;
            data.isInWeblinkDesktop =  AppService.isPramanthaObjectDetected();
            return $http.post(AppService.getBaseUrl() + 'login', data);
        };

        wmoapi.consumeToken = function (token, language, srcsite) {

            var data = headersNoCache;
            data.token = token;
            data.language = language;
            data.srcsite = srcsite;
            data.isInWeblinkDesktop = AppService.isPramanthaObjectDetected();

            return $http.post(AppService.getBaseUrl() + 'user/consumeToken', data);

        };

        wmoapi.logout = function () {
            return $http.post(AppService.getBaseUrl() + 'logout');
        };

        wmoapi.addUserPlugin = function () {

            return $http.post(AppService.getBaseUrl() + 'user/addUserPlugin');

        };

        wmoapi.setUserPlugin = function (key) {

            var data = {
                key: key
            };

            return $http.post(AppService.getBaseUrl() + 'user/setUserPlugin', data);

        };

        wmoapi.setActivationCode = function (serial, activationCode) {

            var data = {
                serial: serial,
                activation_code: activationCode
            };

            return $http.post(AppService.getBaseUrl() + 'activation/setActivationCode', data);

        };

        wmoapi.getAccessories = function (requestId, firmwareId) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'flash/getAccessories?request_id=' + requestId + '&firmware_id=' + firmwareId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getAccessoriesWithAccRfid = function (requestId) {

            return $http.get(AppService.getBaseUrl() + 'flash/getAccessoriesWithAccRfid?request_id=' + requestId, headersNoCache);
        };

        wmoapi.getUserPlugin = function () {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'user/getUserPlugin?ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getUserProfile = function () {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'user/getUserProfile?ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getHarnessCredit = function () {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'user/getHarnessCredit?ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.setLanguage = function (langcode) {

            var data = {
                language: langcode
            };

            return $http.post(AppService.getBaseUrl() + 'user/setLanguage', data);

        };

        wmoapi.getDeviceInfo = function (bootloader, serial) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'device/getDeviceInfo?device_info[0][bootloader]=' + bootloader + '&device_info[0][serial]=' + serial + '&ts=' + date.getTime(), headersNoCache);

        };

        wmoapi.getFirmwareByVehicle = function (serial, vehicle_id) {

            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'device/getFirmwareByVehicle?serial=' + serial + '&vehicle_id=' + vehicle_id + '&ts=' + date.getTime(), headersNoCache);

        };

        wmoapi.getDeviceInfoBySerial = function (serial) {

            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'device/getDeviceInfo?device_info[0][serial]=' + serial + '&ts=' + date.getTime(), headersNoCache);

        };

        wmoapi.getVehicleMakes = function (requestId, showAllMakes) {

            return $http.get(AppService.getBaseUrl() + 'flash/getVehicleMakes?request_id=' + requestId + '&show_all_vehicle=' + showAllMakes, headersNoCache);
        };

        wmoapi.getVehicleYears = function (requestId, vehicleMakeId, showAllMakes) {

            return $http.get(AppService.getBaseUrl() + 'flash/getVehicleYears?request_id=' + requestId + '&vehicle_make_id=' + vehicleMakeId + '&show_all_vehicle=' + showAllMakes, headersNoCache);
        };

        wmoapi.getVehicleModels = function (requestId, vehicleMakeId, vehicleYearId, showAllMakes) {

            return $http.get(AppService.getBaseUrl() + 'flash/getVehicleModels?request_id=' + requestId + '&vehicle_make_id=' + vehicleMakeId + '&vehicle_year_id=' + vehicleYearId + '&show_all_vehicle=' + showAllMakes, headersNoCache);

        };

        wmoapi.getVehicles = function (requestId, vehicleMakeId, vehicleYearId, vehicleModelId, showAllMakes) {

            return $http.get(AppService.getBaseUrl() + 'flash/getVehicles?request_id=' + requestId + '&vehicle_make_id=' + vehicleMakeId + '&vehicle_year_id=' + vehicleYearId + '&vehicle_model_id=' + vehicleModelId + '&show_all_vehicle=' + showAllMakes, headersNoCache);

        };

        wmoapi.setVehicle = function (requestId, vehicleId, showAllMakes) {

            var data = {
                request_id: requestId,
                vehicle_id: vehicleId,
                show_all_vehicle: showAllMakes
            };

            return $http.post(AppService.getBaseUrl() + 'request/setVehicle', data);

        };

        wmoapi.setBootloaderVersion = function (serial, bootloader) {

            var data = {
                serial: serial,
                bootloader: bootloader
            };

            return $http.post(AppService.getBaseUrl() + 'device/setBootloaderVersion', data);

        };

        wmoapi.getFirmwareFamilies = function (requestId) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'flash/getFirmwareFamilies?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getFirmwareFamiliesNoProtocol = function (requestId) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'flash/getFirmwareFamiliesNoProtocol?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.setFirmwareFamily = function (requestId, firmwareFamilyId) {

            var data = {
                request_id: requestId,
                firmware_family_id: firmwareFamilyId
            };

            return $http.post(AppService.getBaseUrl() + 'request/setFirmwareFamily', data);

        };

        wmoapi.getCurrentFirmware = function (requestId) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'flash/getCurrentFirmware?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getFirmwarePosts = function (requestId) {

            return $http.get(AppService.getBaseUrl() + 'flash/getFirmwarePosts?request_id=' + requestId, headersNoCache);
        };

        wmoapi.getFirmwares = function (requestId) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'flash/getFirmwares?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.setFirmware = function (requestId, firmwareId) {

            var data = {
                request_id: requestId,
                firmware_id: firmwareId
            };

            return $http.post(AppService.getBaseUrl() + 'request/setFirmware', data);

        };

        wmoapi.getProtocols = function (requestId) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'flash/getProtocols?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);

        };

        wmoapi.getTelematics = function (requestId) {

            return $http.get(AppService.getBaseUrl() + 'flash/getTelematics?request_id=' + requestId, headersNoCache);

        };

        /**
         * @param requestId configure module request id
         * @returns {HttpPromise} oem remote feature configuration
         */
        wmoapi.getOemRemoteConfig = function (requestId) {
            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'flash/getOemRemoteConfig?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);

        };

        wmoapi.getDeviceFeatures = function (requestId) {
            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'flash/getDeviceFeatures?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);

        };

        wmoapi.getEepromAddress = function () {
            return $http.get(AppService.getBaseUrl() + 'flash/getEepromAddress');
        };

        wmoapi.getRemoteEepromAddress = function () {
            return $http.get(AppService.getBaseUrl() + 'flash/getRemoteEepromAddress');
        };

        wmoapi.setOemRemoteConfig = function (configRequestId, feature_config_id, optionId) {

            var data = {
                request_id: configRequestId,
                feature_config_id: feature_config_id,
                option_id: optionId
            };

            return $http.post(AppService.getBaseUrl() + 'request/setOemRemoteConfig', data);

        };

        wmoapi.setProtocol = function (requestId, protocolId) {

            var data = {
                request_id: requestId,
                protocol_id: protocolId
            };

            return $http.post(AppService.getBaseUrl() + 'request/setProtocol', data);

        };

        wmoapi.setTelematic = function (requestId, telematicId) {

            var data = {
                request_id: requestId,
                telematic_id: telematicId
            };

            return $http.post(AppService.getBaseUrl() + 'request/setTelematic', data);

        };

        wmoapi.setDeviceConfigRequest = function (configRequestId, updates) {

            var data = {
                request_id: configRequestId,
                updates: updates
            };

            return $http.post(AppService.getBaseUrl() + 'request/setDeviceConfigRequest', data);

        };

        wmoapi.download = function (requestId) {

            var data = {
                request_id: requestId
            };

            return $http.post(AppService.getBaseUrl() + 'request/download', data);

        };

        wmoapi.cancel = function (requestId) {

            var data = {
                request_id: requestId
            };

            return $http.post(AppService.getBaseUrl() + 'request/cancel', data);

        };

        wmoapi.confirm = function (requestId, downloadId) {

            var data = {
                request_id: requestId,
                download_id: downloadId
            };

            return $http.post(AppService.getBaseUrl() + 'request/confirm', data);

        };


        wmoapi.getRemotes = function (remotesRequestId, pageReadings, sendEmptyReading) {

            var params = {
                params: {
                    request_id: remotesRequestId
                }
            };

            var eeprompage, eepromval;

            for (var i = 0; i < pageReadings.length; i++) {
                var obj = pageReadings[i];

                for (var key in obj) {
                    if(obj.hasOwnProperty(key)){
                        eeprompage = key;
                        eepromval = sendEmptyReading ? 'FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF' : obj[key].toString();
                    }
                }
                params.params['page' + eeprompage] = eepromval;
            }

            return $http.get(AppService.getBaseUrl() + 'flash/getRemotes', params);

        };


        wmoapi.getDeviceRemotes = function (deviceSerial, pageReadings) {

            var params = {
                params: {
                    serial: deviceSerial
                }
            };

            var eeprompage, eepromval;

            for (var i = 0; i < pageReadings.length; i++) {
                var obj = pageReadings[i];

                for (var key in obj) {
                    if(obj.hasOwnProperty(key)){
                        eeprompage = key;
                        eepromval = obj[key].toString();
                    }
                }
                params.params['page' + eeprompage] = eepromval;
            }

            return $http.get(AppService.getBaseUrl() + 'device/getDeviceRemotes', params);

        };

        wmoapi.getRemoteInfo = function (esn) {

            var params = {
                params: {
                    esn: esn
                }
            };

            return $http.get(AppService.getBaseUrl() + 'device/getRemoteInfo', params);

        };

        wmoapi.getRfDecoder = function (serial) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'device/getRfDecoder?serial=' + serial + '&ts=' + date.getTime(), headersNoCache);

        };

        wmoapi.showButtonRsuGuide = function (serial, rsuProductId, requestId) {

            var date = new Date();

            var requestIdParam = '';

            if (requestId) {
                requestIdParam = '&request_id=' + requestId;
            }

            return $http.get(AppService.getBaseUrl() + 'device/showButtonRsuGuide?serial=' + serial +'&rsu_product_id=' + rsuProductId + requestIdParam + '&ts=' + date.getTime(), headersNoCache);

        };

        /**
         *
         * @param remotesRequestId
         * @param esns [ 'esn1', 'esn2', 'esn3' ]
         * @param pageReadings  array of eeprom page and the reading ie: [ { '63':'FFFFFFFFFFFFFFFFFFFFFFFFFF0DFFFF' }, { '62':'15F830A3004C769823A13355029F0CF2' } ]
         * @returns {HttpPromise}
         */
        wmoapi.setRemotes = function (remotesRequestId, esns, pageReadings) {

            var data = {
                request_id: remotesRequestId,
                esns: esns
            };
            var eeprompage, eepromval;

            for (var i = 0; i < pageReadings.length; i++) {
                var obj = pageReadings[i];

                for (var key in obj) {
                    eeprompage = key;
                    eepromval = obj[key].toString();
                }
                data['page' + eeprompage] = eepromval;
            }

            return $http.post(AppService.getBaseUrl() + 'request/setRemotes', data);

        };

        /**
         * @param requestId flash/master request id
         * @returns {HttpPromise}
         */
        wmoapi.getGuides = function (serial) {

            var params = {
                params: {
                    serial: serial
                }
            };

            return $http.get(AppService.getBaseUrl() + 'device/getGuides', params);

        };

        /**
         * @param serial
         * @returns {HttpPromise}
         */
        wmoapi.getSuspendedRequest = function (serial) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'request/getSuspendedRequest?serial=' + serial + '&ts=' + date.getTime(), headersNoCache);

        };
        /**
         * @param bootloader
         * @param serial
         * @returns {HttpPromise}
         */
        wmoapi.getLatestRequest = function (bootloader, serial) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'request/getLatestRequest?bootloader=' + bootloader + '&serial=' + serial + '&ts=' + date.getTime(), headersNoCache);
        };

        /**
         * @param serial
         * @returns {HttpPromise} {'page' : '0F'}
         */
        wmoapi.getMainPage = function (serial) {

            var params = {
                params: {
                    serial: serial
                }
            };

            return $http.get(AppService.getBaseUrl() + 'clone/getMainPage', params);

        };

        /**
         * @param mainPage
         * @returns {HttpPromise} {'logName': string, 'hasHlsLog': boolean}
         */
        wmoapi.hasHlsLog = function (mainPage) {

            var params = {
                params: {
                    mainPage: mainPage
                }
            };

            return $http.get(AppService.getBaseUrl() + 'clone/hasHlsLog', params);

        };
        /**
         * @param serial
         * @returns {HttpPromise} ['0F','10','11','12']
         */
        wmoapi.getRequiredPages = function (serial, mainPageContent) {

            var params = {
                params: {
                    serial: serial,
                    mainPage: mainPageContent
                }
            };

            return $http.get(AppService.getBaseUrl() + 'clone/getRequiredPages', params);

        };

        /**
         * @param requestId
         * @returns {HttpPromise} "waitTime": 72,"ready": false
         */
        wmoapi.getStatusUpdate = function (requestId) {

            return $http.get(AppService.getBaseUrl() + 'clone/getStatusUpdate?request_id=' + requestId, headersNoCache);

        };

        /**
         * @param serial
         * @param pageReadings array of eeprom page and the reading ie: [ { '0F':'FFFFFFFFFFFFFFFFFFFFFFFFFF0DFFFF' }, { '10':'15F830A3004C769823A13355029F0CF2' } ]
         * @returns {HttpPromise}
         */
        wmoapi.isDevicePrepared = function (serial, pageReadings) {

            var paramString = 'serial=' + serial;
            var eeprompage, eepromval;

            for (var i = 0; i < pageReadings.length; i++) {
                var obj = pageReadings[i];

                for (var key in obj) {
                    eeprompage = key;
                    eepromval = obj[key].toString();
                }
                paramString += '&page' + eeprompage + '=' + eepromval;
            }

            return $http.get(AppService.getBaseUrl() + 'clone/isDevicePrepared?' + paramString, headersNoCache);

        };

        /**
         * @param klonRequestId
         * @param pageReadings  array of eeprom page and the reading ie: [ { '0F':'FFFFFFFFFFFFFFFFFFFFFFFFFF0DFFFF' }, { '10':'15F830A3004C769823A13355029F0CF2' } ]
         * @returns {HttpPromise}
         */
        wmoapi.sendReadings = function (klonRequestId, pageReadings) {

            var data = {
                request_id: klonRequestId
            };
            var eeprompage, eepromval;

            for (var i = 0; i < pageReadings.length; i++) {
                var obj = pageReadings[i];

                for (var key in obj) {
                    eeprompage = key;
                    eepromval = obj[key].toString();
                }
                data['page' + eeprompage] = eepromval;
            }

            return $http.post(AppService.getBaseUrl() + 'clone/sendReadings', data);

        };



        /**
         * @param vehicle_id, product_id
         * @returns {HttpPromise}
         */
        wmoapi.getVideos = function (vehicle_id, product_id) {

            return $http.get(AppService.getBaseUrl() + 'video/getVideos?vehicle_id=' + vehicle_id + '&product_id=' + product_id, headersNoCache);

        };

        /**
         * @param getPackages
         * @returns {HttpPromise} packages:[]
         */
        wmoapi.getPackages = function (requestId) {

            return $http.get(AppService.getBaseUrl() + 'flash/getPackages?request_id=' + requestId, headersNoCache);

        };

        /**
         * Set package
         * @param request_id
         * @param package_id
         * @returns {HttpPromise}
         */
        wmoapi.setPackage = function (request_id, package_id) {
            var data = {
                request_id: request_id,
                package_id: package_id
            };
            return $http.post(AppService.getBaseUrl() + 'request/setPackage', data);
        };



        wmoapi.getPackage = function (serial) {

            return $http.get(AppService.getBaseUrl() + 'device/getPackage?serial=' + serial, headersNoCache);

        };

        /**
         * @param antennaSerial
         * @returns {HttpPromise}
         */
        wmoapi.getPackageChange = function (antennaSerial) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'device/getPackageChange?serial=' + antennaSerial + '&ts=' + date.getTime(), headersNoCache);

        };

        /**
         * @param antennaSerial
         * @returns {HttpPromise}
         */
        wmoapi.getPackagesNoBill = function (antennaSerial) {

            var date = new Date();

            return $http.get(AppService.getBaseUrl() + 'device/getPackages?serial=' + antennaSerial + '&ts=' + date.getTime(), headersNoCache);

        };

        wmoapi.getLatestFirmwareInfo = function (serials) {

            var date = new Date();

            var qs = serials.map(function(serial, index) {
                return 'serial[' + index + ']=' + serial;
            }).join('&');

            return $http.get(AppService.getBaseUrl() + 'device/getLatestFirmwareInfo?' + qs + '&ts=' + date.getTime(), headersNoCache);
        };

        /**
         * Get RSU Remote Brands
         * @param requestId Main request id
         * @returns {HttpPromise}
         */
        wmoapi.getRsuRemoteBrands = function (requestId) {

            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'flash/getRsuRemoteBrands?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);

        };

        /**
         * Get RSU remotes by RSU remote brand id
         * @param requestId Main request id
         * @param rsuRemoteBrandId RSU remote brand id
         * @returns {HttpPromise}
         */
        wmoapi.getRsuRemotes = function (requestId, rsuRemoteBrandId) {

            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'flash/getRsuRemotes?request_id=' + requestId + '&remote_brand_id=' + rsuRemoteBrandId + '&ts=' + date.getTime(), headersNoCache);

        };

        /**
         * Get RSU guides
         * @param rsuProductId
         * @param serial
         * @returns {HttpPromise}
         */
        wmoapi.getRsuGuide = function (rsuProductId, serial) {

            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'flash/getRsuGuide?rsu_product_id=' + rsuProductId + '&serial=' + serial + '&ts=' + date.getTime(), headersNoCache);

        };

        /**
         * Get RSU guides by request id
         * @param requestId Main request id
         * @param rsuProductId
         * @param serial
         * @returns {HttpPromise}
         */
        wmoapi.getRsuGuideByRequestId = function (requestId, rsuProductId, serial) {

            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'flash/getRsuGuide?request_id=' + requestId + '&rsu_product_id=' + rsuProductId + '&serial=' + serial + '&ts=' + date.getTime(), headersNoCache);

        };

        /**
         * Set selected RSU remote
         * @param requestId Main request id
         * @param rsuRemoteId RSU remote id
         * @returns {HttpPromise}
         */
        wmoapi.setRsuRemote = function (requestId, rsuRemoteId, brandId) {
            var data = {
                request_id: requestId,
                rsu_remote_id: rsuRemoteId,
                rsu_brand_id: brandId
            };
            return $http.post(AppService.getBaseUrl() + 'request/setRsuRemote', data);
        };

        /**
         * Get Classic install options
         * @param requestId Main request id
         * @returns {HttpPromise}
         */
        wmoapi.getClassicInstallOptions = function (requestId) {

            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'flash/getClassicInstallOptions?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);

        };


        /**
         * validate weblink desktop version + status
         * @param userAppVersion
         * @returns {HttpPromise}
         */
        wmoapi.validateWdVersion = function (userAppVersion) {
            return $http.get(AppService.getBaseUrl() + 'weblink-desktop/validate-status?userAppVersion=' + (userAppVersion?userAppVersion:'0'));
        };


        /**
         * return quick configs values
         * @param requestId
         * @returns {HttpPromise}
         */
        wmoapi.getQuickConfigs = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'flash/getQuickConfigs?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        /**
         * Set selected RSU remote
         * @param requestId module config request id
         * @param configsStr string of updates
         * @returns {HttpPromise}
         */
        wmoapi.setQuickConfigs = function (requestId, configsStr) {
            var data = {
                request_id: requestId,
                updates: configsStr
            };
            return $http.post(AppService.getBaseUrl() + 'request/setQuickConfigs', data);
        };

         /**
         * Set selected RSU remote
         * @param requestId module config request id
         * @param configsStr string of updates
         * @returns {HttpPromise}
         */
        wmoapi.setQuickConfigs = function (requestId, configsStr) {
            var data = {
                request_id: requestId,
                updates: configsStr
            };
            return $http.post(AppService.getBaseUrl() + 'request/setQuickConfigs', data);
        };

        /**
         * Set selected T Harness
         * @param requestId module config request id
         * @param accessoryId accessory id
         * @param isHardwired is hardwired install flag
         * @param harnessSerialNumber harness serial number
         * @param noSerialProvided no serial provided flag
         * @returns {HttpPromise}
         */
        wmoapi.setTHarness = function (requestId, accessoryId, isHardwired, harnessSerialNumber, noSerialProvided) {
            var data = {
                request_id: requestId,
                is_hardwired: isHardwired ? 1 : 0,
                no_serial_provided: noSerialProvided ? 1 : 0
            };

            if (accessoryId) {
                data.accessory_id = accessoryId;
            }

            if (harnessSerialNumber) {
                data.hsn = harnessSerialNumber;
            }

            return $http.post(AppService.getBaseUrl() + 'request/setTHarness', data);
        };

        /**
         * Check if HSN is valid
         * @param requestId module config request id
         * @param productId product id
         * @param harnessSerialNumber harness serial number
         * @param isHardwired is hardwired install flag
         * @returns {HttpPromise}
         */
        wmoapi.isValidHsn = function (requestId, productId, isHardwired, harnessSerialNumber) {
            var data = {
                request_id: requestId,
                is_hardwired: isHardwired,
                product_id: productId,
                hsn: harnessSerialNumber
            };

            return $http.post(AppService.getBaseUrl() + 'flash/isValidHsn', data);
        };
        /**
         * validateHsnCredit
         * @param requestId module config request id
         * @param productId product id
         * @param harnessSerialNumber harness serial number
         * @param isHardwired is hardwired install flag
         * @param noSerialProvided no serial provided flag
         * @returns {HttpPromise}
         */
        wmoapi.validateHsnCredit = function (requestId, productId, isHardwired, harnessSerialNumber, noSerialProvided) {
            var data = {
                request_id: requestId,
                is_hardwired: isHardwired ? 1 : 0,
                product_id: productId,
                hsn: harnessSerialNumber,
                no_serial_provided: noSerialProvided ? 1 : 0
            };

            return $http.post(AppService.getBaseUrl() + 'flash/validateHsnCredit', data);
        };
        /**
         * Get bootloader
         *
         * @param bootloader
         * @returns {HttpPromise}
         */
        wmoapi.getBootloader = function (bootloaderVersion) {
            return $http.get(AppService.getBaseUrl() + 'device/getBootloader?bootloader='+bootloaderVersion, headersNoCache);
        };

        /**
         * Get manufacturer
         *
         * @param eeprom
         * @returns {HttpPromise} 
         */
        wmoapi.rsuGetManufacturer = function (eeprom) {

            return $http.get(AppService.getBaseUrl() + 'starter/getManufacturer?eeprom=0x81:'+eeprom, headersNoCache);
        };

        /**
         * Get information
         *
         * @param manufacturer_id
         * @param hardware_signature
         * @param firmware_signature
         * @returns {HttpPromise} 
         */
        wmoapi.rsuGetInformation = function (manufacturerId, hardwareSignature, firmwareSignature) {
            return $http.get(AppService.getBaseUrl() + 'starter/getInformation?manufacturer_id='+manufacturerId+'&hardware_signature='+hardwareSignature+'&firmware_signature='+firmwareSignature, headersNoCache);
        };

        /**
         * Get Terms of use
         *
         * @returns {HttpPromise} 
         */
        wmoapi.getAgreements = function () {
            return $http.get(AppService.getBaseUrl() + 'getAgreements', headersNoCache);
        };

        /**
         * Accept agreement
         * 
         * @returns {HttpPromise}
         */
        wmoapi.acceptAgreement = function () {
            var body = {
                browserType: AppService.getBrowserType()
            };

            $http.post(AppService.getBaseUrl() + 'acceptAgreement', body, headersNoCache);
        };

        // Weblink Hub Calls

        wmoapi.getWeblinkHubModeFrame = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getModeFrame?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.validateWeblinkHubModeFrameResponse = function (requestId, responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/validateModeFrameResponse?request_id=' + requestId + '&response_frame=' + responseFrame + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubKlonSteps = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getKlonSteps?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubKeyId = function (requestId, responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getKeyId?request_id=' + requestId + '&response_frame=' + responseFrame + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubIsKeyBricked = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/isKeyBricked?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubIsKeyCracked = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/isKeyCracked?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubPollProcedureStatusFrame = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getPollProcedureStatusFrame?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubPollProcedureStatusResponse = function (requestId, responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getPollProcedureStatusResponse?request_id=' + requestId + '&response_frame=' + responseFrame + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubReadKeyIdFrame = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getReadKeyIdFrame?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubReadResultFrame = function (requestId, page) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getReadResultFrame?request_id=' + requestId + '&page=' + page + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubRebootFrame = function () {
            return $http.get(AppService.getBaseUrl() + 'hub/getRebootFrame', headersNoCache);
        };

        wmoapi.validateWeblinkHubRebootFrameResponse = function (responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/validateRebootFrameResponse?response_frame=' + responseFrame+ '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubResultData = function (requestId, responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getResultData?request_id=' + requestId + '&response_frame=' + responseFrame + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubSubStepCount = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getSubStepCount?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubStartProcedureFrame = function (requestId, subStep) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getStartProcedureFrame?request_id=' + requestId + '&substep=' + subStep +'&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubStartProcedureFrameResponse = function (requestId, responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getStartProcedureFrameResponse?request_id=' + requestId + '&response_frame=' + responseFrame + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubPostValidateKeyFrame = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getPostValidateKeyFrame?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubPostValidateKeyResponse = function (requestId, responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getPostValidateKeyResponse?request_id=' + requestId + '&response_frame=' + responseFrame + '&ts=' + date.getTime(), headersNoCache);
        };
        wmoapi.getWeblinkHubPreValidateKeyFrame = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getPreValidateKeyFrame?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getWeblinkHubPreValidateKeyResponse = function (requestId, responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getPreValidateKeyResponse?request_id=' + requestId + '&response_frame=' + responseFrame + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getKlonHubStep1 = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getKlonHubStep1?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getKlonHubStep2 = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getKlonHubStep2?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getListReadResultFrame = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/getListReadResultFrame?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.getListResultData = function (requestId, responseFrames) {
            var date = new Date();

            var body = {
                request_id: requestId,
                response_frame: responseFrames,
                ts: date.getTime()
            };

            return $http.post(AppService.getBaseUrl() + 'hub/getListResultData', body, headersNoCache);
        };

        wmoapi.startSecureSession = function (requestId, hubSerial) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/startSecureSession?request_id=' + requestId + '&serial=' + hubSerial + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.startSecureSessionResponse = function (requestId, responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/startSecureSessionResponse?request_id=' + requestId + '&response_frame=' + responseFrame + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.authenticateStep1 = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/authenticateStep1?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.authenticateStep2 = function (requestId, responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/authenticateStep2?request_id=' + requestId + '&response_frame=' + responseFrame + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.authenticateStep3 = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/authenticateStep3?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.authenticateStep4 = function (requestId, responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/authenticateStep4?request_id=' + requestId + '&response_frame=' + responseFrame + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.stopSecureSession = function (requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/stopSecureSession?request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };
        wmoapi.stopSecureSessionResponse = function (requestId, responseFrame) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'hub/stopSecureSessionResponse?request_id=' + requestId + '&response_frame=' + responseFrame + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.weblinkHubSaveReadings = function (klonRequestId, pageReadings) {
            var date = new Date();

            var data = {
                request_id: klonRequestId,
                ts: date.getTime()
            };
            var eeprompage, eepromval;

            for (var i = 0; i < pageReadings.length; i++) {
                var obj = pageReadings[i];

                for (var key in obj) {
                    eeprompage = key;
                    eepromval = obj[key].toString();
                }
                data['page' + eeprompage] = eepromval;
            }
            return $http.post(AppService.getBaseUrl() + 'hub/saveReadings', data, headersNoCache);
        };

        wmoapi.weblinkHubSaveHLSReadings = function (klonRequestId, hlsReadings) {
            var date = new Date();

            var data = {
                request_id: klonRequestId,
                ts: date.getTime(),
                hlsLog: hlsReadings
            };

            return $http.post(AppService.getBaseUrl() + 'hub/saveReadings', data, headersNoCache);
        };

        wmoapi.getFavoriteList = function (serial, type) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'favorite/favoriteList?serial=' + serial + '&type=' + type + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.saveFavoriteFlash = function (name, description, requestId, configureRequestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'favorite/saveFavorite?name=' + name + '&description=' + description + '&type=flash_vehicle&request_id=' + requestId + '&configure_module_id=' + configureRequestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.saveFavoriteConfigureModule = function (name, description, configureRequestId, requestId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'favorite/saveFavorite?name=' + name + '&description=' + description + '&type=feature_configuration&configure_module_id=' + configureRequestId + '&request_id=' + requestId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.updateFavorite = function (favoriteId, name, description) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'favorite/updateFavorite?favorite_id=' + favoriteId + '&name=' + name + '&description=' + description + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.deleteFavorite = function (favoriteId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'favorite/deleteFavorite?favorite_id=' + favoriteId + '&ts=' + date.getTime(), headersNoCache);
        };

        wmoapi.flashVehicleFromFavorite = function (favoriteId, serial, bootloader) {
            var date = new Date();

            var data = {
                favorite_id: favoriteId,
                ts: date.getTime(),
                serial: serial,
                bootloader: bootloader
            };
            return $http.post(AppService.getBaseUrl() + 'favorite/flashVehicleFromFavorite', data, headersNoCache);
        };

        wmoapi.favoriteLoadFeatureConfig = function (requestConfigureId, favoriteId) {
            var date = new Date();
            return $http.get(AppService.getBaseUrl() + 'favorite/loadFeatureConfig?favorite_id=' + favoriteId + '&request_configure_id=' + requestConfigureId + '&ts=' + date.getTime(), headersNoCache);
        };
        return wmoapi;
    }
]);
