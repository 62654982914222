(function() {
    'use strict';

    angular
        .module('core')
        .controller('PartialsFirmwareController', PartialsFirmwareController);

    PartialsFirmwareController.$inject = ['$scope', '$rootScope', 'alertBar', 'Wmoapi', '$q', 'ngDialog', 'constants', 'DeviceService', 'deviceIdentifier', '_'];

    function PartialsFirmwareController($scope, $rootScope, alertBar, Wmoapi, $q, ngDialog, constants, DeviceService, deviceIdentifier, _) {

        var vm = this;

        // instantiate these vars from isolate scope.
        vm.loadControlsPartial = $scope.loadControlsPartial;
        vm.loadDevicePartial = $scope.loadDevicePartial;
        vm.loadFeaturePartial = $scope.loadFeaturePartial;
        vm.loadOemRemoteStartPartial = $scope.loadOemRemoteStartPartial;
        vm.firmwareSet = false;
        var mainDevice = DeviceService.getFlashableDevice();
        var isDetectedDeviceIsClassic = deviceIdentifier.isDetectedDeviceIsClassic(mainDevice.productPartOptions);
        var popupAlreadyShown = deviceIdentifier.doesDetectedDeviceUseAccRfid(mainDevice.productPartOptions);
        var hsnExcluded = deviceIdentifier.isDetectedDeviceExcludedFromHSN(mainDevice.productPartOptions);
        var hsnMandatory = deviceIdentifier.doesDetectedDeviceRequireHSN(mainDevice.productPartOptions);
        var canModifyFeatures = deviceIdentifier.isConfigureModuleAllowed(mainDevice.allowedServices);
        var isDeviceAccessory = deviceIdentifier.isDeviceAccessory(mainDevice.deviceFunctionId);
        var isDeviceRfid = deviceIdentifier.isDeviceRfid(mainDevice.deviceFunctionId);
        vm.backSkipStep = 'telematics';

        // define what we are adding to the scope (vars / functions)
        vm.setFirmware = setFirmware;

        $scope.$on('PartialsFirmware_OnSaveFirmwareFlashMode', function() {
            saveSelectedFirmware();
        });

        init();

        function init() {
            var parentRequestId = vm.request.id;
            // if firmware hasn't been set or has been set but for the wrong firmware family
            var firmwareChanged = (!vm.userSelection.selectedFirmware.id || vm.userSelection.selectedFirmware.familyId !== vm.vehicleSelection.firmwareFamilyId);
            vm.userSelection.isFwChanged = false;
            vm.firmwareSet = angular.isDefined(vm.responseData.setFirmware.result) && !firmwareChanged;
            vm.backSkipStep = (isDetectedDeviceIsClassic)? 'protocols' : (!canModifyFeatures && (isDeviceAccessory || isDeviceRfid)) ? 'vehicle.trims' : 'telematics';

            if (!vm.firmwareSet) {
                vm.userSelection.selectedFirmware = {};
                vm.userSelection.selectedAccessories = {};
                vm.responseData.deviceOemRemoteConfig = {};
                vm.userSelection.oemRemote = '';
            }
            if (firmwareChanged) {
                vm.responseData.vehicleFirmwares = [];
                vm.responseData.currentAccessories = [];
                vm.responseData.deviceOemRemoteConfig = {};
                vm.userSelection.oemRemote = '';

            }
            alertBar.hide();
            if (parentRequestId === '' || typeof parentRequestId === 'undefined') {
                vm.loadDevicePartial()(false);
            } else {
                getCurrentFirmware(parentRequestId);
            }
        }

        function getCurrentFirmware(parentRequestId) {

            $rootScope.isGlobalLoading = true;
            getFirmware(parentRequestId)
                .then(function(response) {
                    vm.userSelection.selectedFirmware = response.data;
                    const firmwareUsesAccRfid = vm.userSelection.selectedFirmware.isRfidRequired  && vm.responseData.firmwareFamily.isKlonHubRequired;
                    if (firmwareUsesAccRfid && !popupAlreadyShown) {
                        ngDialog.openConfirm({
                            template: 'modules/core/views/partials/firmware/directives/rfidrequired.modal.client.directive.html',
                            className:'ngdialog-theme-plain',
                            closeByDocument: false,
                            showClose: false
                        });
                    }
                    getCurrentAccessories(parentRequestId, vm.userSelection.selectedFirmware.id);
                }, function() {
                    $rootScope.isGlobalLoading = false;
                    alertBar.show( 'error', 'TELEMATICS_ERROR_GET_FIRMWARE' );
                });
        }

        function getFirmware(parentRequestId) {
            var deferred = $q.defer();
            // check if it's the same vehicle.
            if (!vm.userSelection.selectedFirmware.id || vm.userSelection.selectedFirmware.familyId !== vm.vehicleSelection.firmwareFamilyId) {
                Wmoapi.getCurrentFirmware(parentRequestId)
                    .then(function(success) {
                        vm.responseData.currentFirmware = success.data;
                        //vm.userSelection.selected
                        deferred.resolve(success);
                    }, function(error) {
                        deferred.reject(error);
                    });
            } else {
                deferred.resolve({ 'data': vm.userSelection.selectedFirmware });
            }

            return deferred.promise;
        }

        function getCurrentAccessories(parentRequestId, firmwareId) {

            $rootScope.isGlobalLoading = true;
            getAccessories(parentRequestId, firmwareId)
                .then(function(response) {
                    $rootScope.isGlobalLoading = false;
                    vm.userSelection.selectedAccessories = response.data.accessories;
                }, function() {
                    $rootScope.isGlobalLoading = false;
                    alertBar.show( 'error', 'FIRMWARES_ERROR_GET_ACCESSORIES' );
                });
        }

        function getAccessories(parentRequestId, firmwareId) {
            var deferred = $q.defer();
            // check if it's the same vehicle.
            if ( vm.userSelection.selectedFirmware.id !== vm.vehicleSelection.firmwareId|| vm.userSelection.selectedFirmware.id !== vm.vehicleSelection.firmwareId) {
                Wmoapi.getAccessories(parentRequestId, firmwareId)
                    .then(function(success) {
                        vm.responseData.currentAccessories = success.data.accessories;
                        vm.responseData.hsnOverride = success.data.hsnOverride;
                        //vm.userSelection.selected
                        deferred.resolve(success);
                    }, function(error) {
                        deferred.reject(error);
                    });
            } else {
                deferred.resolve({ 'data': vm.userSelection.selectedAccessories });
            }

            return deferred.promise;

        }

        $scope.loadNextPartial = loadNextPartial;

        function loadNextPartial() {
            if(!canModifyFeatures && (isDeviceAccessory || isDeviceRfid)) {
                $scope.$parent.loadGuidesPartial();
                ngDialog.closeAll();
            } else {
                vm.loadOemRemoteStartPartial()(false);
            }
        }

        function saveSelectedFirmware() {
            setFirmware(vm.userSelection.selectedFirmware.id);
        }

        function setFirmware(firmwareId) {
            var fw = vm.userSelection.selectedFirmware;
            var parentRequestId = vm.request.id;

            vm.responseData.currentFirmware = fw;
            $rootScope.isGlobalLoading = true;

            Wmoapi.setFirmware(parentRequestId, firmwareId)
                .then(function(response) {
                    vm.responseData.setFirmware = response.data;
                    $rootScope.isGlobalLoading = false;
                    vm.prevVehicleSelection.firmwareId = vm.vehicleSelection.firmwareId === '' ?  vm.userSelection.selectedFirmware.id : vm.vehicleSelection.firmwareId;
                    vm.vehicleSelection.firmwareName = vm.userSelection.selectedFirmware.name;
                    vm.vehicleSelection.firmwareVersion = vm.userSelection.selectedFirmware.version;
                    vm.vehicleSelection.firmwareId = vm.userSelection.selectedFirmware.id;

                    checkIfAnalogThenGotoNextPartial(fw.familyIsAnalog);

                }, function() {
                    alertBar.show( 'error', 'TELEMATICS_ERROR_SET_FIRMWARE');
                    $rootScope.isGlobalLoading = false;
                });
        }

        function checkIfOtherVehicleSelected(selectedVehicle, otherObject) {
            if (parseInt(selectedVehicle.makeId) === parseInt(otherObject.OTHER_VEHICLE_MAKE_ID)  || parseInt(selectedVehicle.year) === parseInt(otherObject.OTHER_VEHICLE_YEAR)  || parseInt(selectedVehicle.modelId) === parseInt(otherObject.OTHER_VEHICLE_MODEL_ID) ) return true;
        }

        function checkIfAnalogThenGotoNextPartial(familyIsAnalog) {
            if (familyIsAnalog) showModalBoxAnalog();
            else checkIfTHarnessAvailable();
        }

        function checkIfTHarnessAvailable() {
            var tharnesses = _.filter(_.concat(vm.responseData.currentAccessories.optional, vm.responseData.currentAccessories.required), ['isProductCategoryTHarness' , true]);
            var aharnesses = _.filter(_.concat(vm.responseData.currentAccessories.optional, vm.responseData.currentAccessories.required), ['isProductCategoryAnalogHarness' , true]);
            var hsnOverride = vm.responseData.hsnOverride;

            var hasTharness = tharnesses.length > 0;
            var hasAnalogHarness = aharnesses.length > 0;

            if(hsnMandatory){
                if (hasTharness && hsnOverride) showModalBoxTHarness(tharnesses);
                else if (hasTharness) loadHarnessCredit();
                else if (hasAnalogHarness)  {
                    vm.responseData.currentAccessories.isHardwired = true;
                    loadHarnessCredit();
                }
                else loadNextPartial();
            }
            else if (hasTharness) showModalBoxTHarness(tharnesses);
            else loadNextPartial();

        }

        function loadHarnessCredit() {
            Wmoapi.getHarnessCredit()
                .success(function(data) {
                $rootScope.globalUser.harnessCredit = data.harness_credit;
                showModalBoxTHarnessWithSerial();
                }).error(function() {
                    alertBar.show( 'error', 'TELEMATICS_ERROR_SET_FIRMWARE');
                });
        }

        function setTHarnessSelection(accessoryId, isHardwired){
            $rootScope.isGlobalLoading = true;
            var parentRequestId = vm.request.id;
            Wmoapi.setTHarness(parentRequestId, accessoryId, isHardwired).then(loadNextPartial);
        }

        function showModalBoxAnalog() {
            var isOtherVehicleWasSelected = checkIfOtherVehicleSelected(vm.vehicleSelection, constants);
            ngDialog.openConfirm({
                template: 'modules/core/views/partials/configuration/directives/trimMessageBox.directive.view.html',
                className: 'ngdialog-theme-plain',
                closeByDocument: false,
                showClose: false,
                controller: ['$scope', function($scope) {
                    $scope.title =   isOtherVehicleWasSelected  ? 'TRIM_MESSAGE_MODAL_TITLE' : 'FIRMWARE_MESSAGE_MODAL_ANALOG_TITLE';
                    $scope.message = isOtherVehicleWasSelected  ? 'TRIM_MESSAGE_MODAL_TEXT'  : 'FIRMWARE_MESSAGE_MODAL_ANALOG_TEXT';
                    $scope.buttonTitle = 'FIRMWARE_MESSAGE_MODAL_ANALOG_BUTTON_TITLE';
                    $scope.event = function () {
                        checkIfTHarnessAvailable();
                    };
                }]
            }).then(function (result) {
                // user did something else
            }, function (error) {
                // user closed dialog - do nothing
            });
        }

        function showModalBoxTHarnessWithSerial ()  {
            ngDialog.openConfirm({
                template: 'modules/core/views/partials/firmware/directives/hsn.directive.view.html',
                className: 'ngdialog-theme-plain',
                closeByDocument: false,
                showClose: false,
                controller: 'PartialsHarnessSerialController',
                scope: $scope.$parent
            }).then(function (result) {
                loadNextPartial();
            }, function (error) {
                // user closed dialog - do nothing
            });
        }

        function showModalBoxTHarness (harnesses)  {
            var analogHarness = vm.responseData.currentAccessories.isHardwired;
            ngDialog.openConfirm({
                template: 'modules/core/views/partials/firmware/directives/tharnessselection.directive.view.html',
                className: 'ngdialog-theme-plain',
                closeByDocument: false,
                showClose: false,
                controller: ['$scope', function($scope) {
                    $scope.harnessOptions = harnesses;
                    $scope.analogHarness = analogHarness;
                    $scope.selectedHarness = 0;

                    $scope.harnessClick = function (harnessId) {
                        $scope.selectedHarness = harnessId;
                    };

                    $scope.event = function () {
                        setTHarnessSelection( $scope.selectedHarness === 99999 ? null : $scope.selectedHarness, $scope.selectedHarness === 99999);
                    };
                }]
            }).then(function (result) {
                // user did something else
            }, function (error) {
                // user closed dialog - do nothing
            });
        }
    }

})();
