'use strict';

var TAGS_REGEXP = /<.*?.*\/?>/g;
angular.module('core').directive('disallowTags', function() {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$validators.disallowTags = function(modelValue, viewValue) {

                var containsTags = TAGS_REGEXP.test(viewValue);
                return !containsTags;

            };
        }
    };
});
