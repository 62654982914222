'use strict';

angular.module('core').controller('PartialsFlashFlashController', [
    '$scope', '$window', 'Wmoapi', 'Request', 'messageBox', '$timeout', 'deviceIdentifier',
    'klonIdentifier', 'alertBar', '$interval', 'wu', '$rootScope', '$location',
    'anchorSmoothScroll', 'dataService', 'trialBox', 'deviceDetect', 'DeviceService', 'keyreading',
    'sidebarManager', 'RSUData', 'ngDialog','AppService', '_', 'guidesService',
    function ($scope, $window, Wmoapi, Request, messageBox, $timeout, deviceIdentifier,
              klonIdentifier, alertBar, $interval, wu, $rootScope, $location,
              anchorSmoothScroll, dataService, trialBox, deviceDetect, DeviceService, keyreading,
              sidebarManager, RSUData, ngDialog, AppService, _, guidesService) {

        $scope.flashing = false;
        $scope.showKlonStep = false;
        $scope.flashingStep2 = false;
        $scope.showAntennaStep = false;
        $scope.isAntennaUpdated = false;
        $scope.flashnumber = 1;
        $scope.totalflash = 2;
        $scope.steps = [1, 2, 3];
        $scope.activestep = 1;
        $scope.percentage = 0;
        $scope.waitTimeInSeconds = 0;
        $scope.showRestartButton = true;
        $scope.showStartConfigButton = false;
        $scope.showQuitFinishLater = true;
        $scope.flashExtProgError = false;
        $scope.hasFirmwarePost = false;
        $scope.showProgressMessage = false;
        $scope.showQuitProcessBtn = false;
        $scope.firmwareUpdateFlashing = false;
        $scope.showErrorNotificationBar = false;
        $scope.showErrorAntennaNotificationBar = false;
        $scope.progressBarActive = false;
        $scope.klonProgressBarActive = false;
        $scope.mainDevice = {};
        $scope.mainParentRequestId = '';
        $scope.showUpdateMessage = true;
        $scope.rsuDataSelectedBrand = RSUData.getSelectedRsuBrand();
        $scope.selectedRsu = RSUData.getSelectedRsu();
        var confirmConfigComplete = false;
        $scope.progress = {message : 'FLASH_PROGRESS_TITLE_ONE_STEP'};
        $scope.flashSuccess = false;
        var mainDevice = DeviceService.getFlashableDevice();
        var rsuDevice = DeviceService.getRsuDevice();
        var isDetectedDeviceIsClassic = deviceIdentifier.isDetectedDeviceIsClassic(mainDevice.productPartOptions);
        var canModifyFeatures = deviceIdentifier.isConfigureModuleAllowed(mainDevice.allowedServices);
        var isDeviceAccessory = deviceIdentifier.isDeviceAccessory(mainDevice.deviceFunctionId);
        var isDeviceRfid = deviceIdentifier.isDeviceRfid(mainDevice.deviceFunctionId);
        var usedAsAddOn = AppService.usedAsAddOn();
        $scope.isFavoriteActive = AppService.isFavoriteActive();
        var isDeviceUsedAsAddOn = isDetectedDeviceIsClassic && usedAsAddOn;
        $scope.hasRsuGuide = {
            showExtraGuide: false,
            extraGuideName: ''
        };
        $scope.isDeviceUsedAsAddOn = isDeviceUsedAsAddOn;
        $scope.flashQueue = [];
        $scope.flashParameters = {isNewModule: false};
        $scope.keyreadingFlash = false;
        $scope.keyreadingFlashDone = false;
        $scope.keyreadingFlashDialog = null;
        $scope.accRFIDRequired = false;
        $scope.klonSteps = [];
        $scope.mostRecentCompletedStep = {stepNumber: 0};


        angular.element(document.querySelector('#body')).removeClass('standard');
        angular.element(document.querySelector('#body')).addClass('body-trim');

        /**
         * activePageview
         * 0 : pre flash page
         * 1 : error page
         * 2 : success page
         */
        $scope.changePageView = function (index) {
            angular.element(document.querySelectorAll('.showVariable')).addClass('ng-hide');
            angular.element(document.querySelectorAll('.show' + index)).removeClass('ng-hide');
            $scope.activePageview = index;
            $window.scrollTo(0, 0);
        };


        $scope.initFlash = function () {
            checkRsuGuide();
            if ($scope.$parent.resumeKeyreading){
                alertBar.hide();
                $scope.mainDevice = DeviceService.getFlashableDevice();
                $scope.changePageView(0);
                $scope.controlsClass = 'col-xs-12 col-md-3';
                $scope.summaryHeaderClass = 'col-xs-3';
                $scope.telematicClass = 'telematic';
                $scope.flashClick();
            } else {
                $scope.getFlashFirmware();
                var fwpost = $scope.$parent.responseData.firmwarePosts;
                $scope.hasFirmwarePost = (fwpost && fwpost.length !== undefined && fwpost.length > 0);
            }

        };

        function checkRsuGuide() {
            var rsuId = rsuDevice ? rsuDevice.Product.id : 0;
            if (!$scope.$parent.isRusWithNoBlade) {
                Wmoapi.showButtonRsuGuide(mainDevice.serial, rsuId, $scope.$parent.request.id).then(
                    function (response) {
                        $scope.hasRsuGuide = response.data;
                    },
                    function (error) {
                        console.error('error in showButtonRsuGuide', error);
                        alertBar.show('error', 'FLASH_ERROR_GET_RSU_GUIDE');
                    });
            }
        }

        function getSelectedOemRemoteOption(featureConfigOptions) {

            var selectedOptionId = '';
            var defaultOptionId = '';

            angular.forEach(featureConfigOptions, function (value, key) {
                if (value.isSelected === 1) {
                    selectedOptionId = value.id;
                } else if (value.isDefault) {
                    defaultOptionId = value.id;
                }
            });

            return selectedOptionId === '' ? defaultOptionId : selectedOptionId;
        }

        $scope.getOemRemoteConfig = function (configModuleRequestId) {
            Wmoapi
                .getOemRemoteConfig(configModuleRequestId)
                .success(function (response) {
                    if (response.id) {
                        $scope.$parent.responseData.deviceOemRemoteConfig = response;
                        $scope.$parent.userSelection.oemRemote.optionId = getSelectedOemRemoteOption(response.option);
                    }
                })
                .error(function (error) {
                    console.error('error in getOemRemoteConfig', error);
                    alertBar.show('error', 'FLASH_ERROR_GET_OEM_REMOTE');

                }).finally(function () {
                $rootScope.isGlobalLoading = false;
            });
        };

        $scope.getFlashFirmware = function () {
            alertBar.hide();
            $scope.mainDevice = DeviceService.getFlashableDevice();
            $scope.changePageView(0);
            $scope.controlsClass = 'col-xs-12 col-md-3';
            $scope.summaryHeaderClass = 'col-xs-3';
            $scope.telematicClass = 'telematic';

            if (!$scope.$parent.flashForKlonOnly) {

                if ($scope.$parent.flashForFirmwareUpdateOnly && !$scope.$parent.flashForFlexUpgradeOnly) {

                    $scope.flashFirmwareUpdate();

                } else if ($scope.$parent.flashForFlexUpgradeOnly) {
                    if (dataService.upgradeBootloader) {
                        flashBootloderUpgrade();
                    } else {
                        flashFlexUpgrade();
                    }

                } else if ($scope.$parent.configurationParameters.flashByFirmware) {
                    $scope.flashFirmwareUpdate();
                } else if ($scope.$parent.configurationParameters.flashFromFavorite) {

                    $scope.mainParentRequestId = Request.getRequestDevice().id;
                    $scope.controlsClass = 'col-xs-12 col-md-4';
                    $scope.summaryHeaderClass = 'col-xs-4';

                    if ($scope.$parent.userSelection.oemRemote.optionId) {
                        $scope.getOemRemoteConfig($scope.$parent.request.configModuleRequestId);
                    }
                }else {
                    var parentRequestId = $scope.$parent.request.id;
                    var configModuleRequestId = $scope.$parent.request.configModuleRequestId;
                    var selectedFirmware = $scope.$parent.userSelection.selectedFirmware;
                    var skipControls = $scope.$parent.skipControls || isDetectedDeviceIsClassic;

                    $scope.mainParentRequestId = parentRequestId;

                    var title = 'FIRMWARE_CONFIGURE_MODULE';
                    var description = 'FIRMWARE_MUST_CONFIGURE';


                    if (skipControls || $scope.rsuDataSelectedBrand || isDeviceUsedAsAddOn) {
                        // skipControls = device prodPartOption has skip-remote-configuration

                        if(skipControls){
                            $scope.controlsClass = 'col-xs-12 col-md-4';
                            $scope.summaryHeaderClass = 'col-xs-4';
                        }

                        if (!canModifyFeatures && (isDeviceAccessory || isDeviceRfid) && (parentRequestId === '' || parentRequestId === undefined)) {
                            // Accessory or rfid with no configure-module
                            messageBox.show('FLASH_REQUEST_ERROR_TITLE', 'FLASH_REQUEST_ERROR_DESC');
                            $scope.$parent.loadDevicePartial();

                        } else if (canModifyFeatures && (parentRequestId === '' || parentRequestId === undefined ||
                            configModuleRequestId === '' || configModuleRequestId === undefined)
                        ) {

                            //TODO: decide what to do when requests are not valid
                            messageBox.show(title, description);
                            $scope.$parent.loadDevicePartial();

                        } else {

                            if (selectedFirmware === '' || selectedFirmware === undefined) {
                                messageBox.show('FLASH_FIRMWARE_ERROR_TITLE', 'FLASH_FIRMWARE_ERROR_DESC');
                                $scope.$parent.loadDevicePartial();
                            }
                        }

                    } else {
                        var configRemotesRequestId = $scope.$parent.request.configRemotesRequestId;

                        if (!canModifyFeatures && (isDeviceAccessory || isDeviceRfid) && (parentRequestId === '' || parentRequestId === undefined)) {
                            // Accessory with no configure-module (ie: acc-rfid)
                            messageBox.show('FLASH_REQUEST_ERROR_TITLE', 'FLASH_REQUEST_ERROR_DESC');
                            $scope.$parent.loadDevicePartial();

                        } else if ((canModifyFeatures || (!canModifyFeatures && !isDeviceAccessory && !isDeviceRfid)) && (parentRequestId === '' || parentRequestId === undefined ||
                            configModuleRequestId === '' || configModuleRequestId === undefined ||
                            configRemotesRequestId === '' || configRemotesRequestId === undefined)
                        ) {
                            // Devices with configure-module (ie: HC) or no configure-module and not an accessory (ie: Fresh HC with fw-id=0)
                            messageBox.show(title, description);
                            $scope.$parent.loadDevicePartial();

                        } else {
                            if (selectedFirmware === '' || selectedFirmware === undefined) {
                                messageBox.show('FLASH_FIRMWARE_ERROR_TITLE', 'FLASH_FIRMWARE_ERROR_DESC');
                                $scope.$parent.loadDevicePartial();
                            } else {
                                if ($scope.$parent.userSelection.oemRemote.optionId) {
                                    $scope.getOemRemoteConfig($scope.$parent.request.configModuleRequestId);
                                }
                            }
                        }
                    }
                }
            }
            else {
                if ($scope.$parent.hasSuspendedRequest) {
                    //resume keycloning
                    $scope.waitTimeInSeconds = 0;
                    $scope.percentage = 0;
                    $scope.processStep2();
                    $scope.getStatusUpdate();
                } else {
                    //start key cloning
                    if (!$scope.keyreadingFlash) {
                        $scope.showKlonStep = true;
                    }
                }
            }
        };

        $scope.openWarningModal = function(){
          $scope.warningModal = ngDialog.open({
            template: 'warningModal',
            className: 'warning-modal ngdialog-theme-plain',
            closeByDocument: false,
            showClose: true,
            scope: $scope
          });
        };

        $scope.flashClick = function () {
            alertBar.hide();
            var parentRequestId = $scope.$parent.request.id;
            $rootScope.keyreadingFlash = false;
            $scope.keyreadingFlash = false;
            $scope.klonSteps = [];
            $scope.mostRecentCompletedStep.stepNumber = 0;
            $rootScope.isGlobalLoading = true;
            if ($scope.responseData.currentFirmware.isKlonHubRequired || $scope.$parent.resumeKeyreading) {
                if ($scope.$parent.resumeKeyreading) {
                    parentRequestId = $scope.$parent.latestRequest.action.request_id;
                }

                Wmoapi.getWeblinkHubKlonSteps(parentRequestId).then(function(result){
                    var steps = result.data.steps.filter(function(step) {return !step.isCompleted;});
                    $scope.klonSteps = steps;
                    var localStepCounter = 0;
                    var noResume = _.some(steps, ['canonicalName', 'go-to-vehicle-read-readings']) && _.some(steps, ['canonicalName', 'plug-main-module']);

                    _.orderBy(steps,['stepOrder'], ['asc'] ).forEach(function(step){
                        if(step.canonicalName === 'key-reading'){
                            $scope.klonSteps[localStepCounter] = {stepOrder:localStepCounter+1, canonicalName:'warning-single-key'};
                            $scope.klonSteps[localStepCounter+1] = {stepOrder:localStepCounter+2, canonicalName:'warning-insert-key'};
                            $scope.klonSteps[localStepCounter+2] = {stepOrder:localStepCounter+3, canonicalName:'warning-key-radius'};
                            $scope.klonSteps[localStepCounter+3] = {stepOrder:localStepCounter+4, canonicalName: step.canonicalName};
                            $scope.klonSteps[localStepCounter+4] = {stepOrder:localStepCounter+5, canonicalName: 'remove-key'};

                            localStepCounter += 5;

                        } else if (step.canonicalName === 'flash-acc-rfid') {
                            $scope.klonSteps[localStepCounter] = {stepOrder:localStepCounter+1, canonicalName:'warning-acc-rfid'};
                            $scope.klonSteps[localStepCounter+1] = {stepOrder:localStepCounter+2, canonicalName: step.canonicalName};
                            localStepCounter += 2;
                        } else {
                            if (noResume && step.canonicalName === 'plug-main-module') {
                                $scope.klonSteps.splice(localStepCounter, 1);
                            } else {
                                $scope.klonSteps[localStepCounter] = {stepOrder:localStepCounter+1, canonicalName:step.canonicalName};
                                localStepCounter++;
                            }
                        }

                    });

                    var keyReadingStep = _.find(steps, ['canonicalName', 'key-reading']);

                    if (keyReadingStep) {
                        alertBar.hide();
                        $scope.$parent.showHasFlexPackageNoBillNotificationBar = false;
                        if ($scope.warningModal) {
                            $scope.warningModal.close();
                            $scope.closeDeviceNotificationBar();
                        }
                        $rootScope.keyreadingFlash = true;
                        $scope.keyreadingFlash = true;
                        $rootScope.isGlobalLoading = false;
                    } else {
                        $rootScope.keyreadingFlash = false;
                        flash();
                    }
                }, function (error) {
                    $rootScope.isGlobalLoading = false;
                    $scope.disableFlashButton = false;
                    $scope.showErrorMessage(false, 'FLASH_ERROR_GENERIC', error.data || error);
                });


            } else {
                flash();
            }
        };

        function flash () {
            alertBar.hide();
            $rootScope.isGlobalLoading = false;
            $scope.$parent.showHasFlexPackageNoBillNotificationBar = false;
            if($scope.warningModal) {
                $scope.warningModal.close();
                $scope.closeDeviceNotificationBar();
            }
            $scope.progress.message = 'FLASH_PROGRESS_TITLE_ONE_STEP';
            DeviceService.setDeviceToFlash(DeviceService.getMainDevice());
            $scope.disableFlashButton = true;
            $scope.flashParameters.isNewModule = true;

            if(isDeviceRfid) {
                flashFirmwareOnly();
            } else {
                download();
            }
        }

        $scope.cancelKeyreading = function() {
            //Flashing page does not have full information, should return to main page
            if ($scope.resumeKeyreading || $scope.mostRecentCompletedStep.stepNumber >= 1){
                $window.location.reload();
            }
            $rootScope.keyreadingFlash = false;
            $scope.keyreadingFlash = false;
            $rootScope.isGlobalLoading = true;

            //Hides digest cycle delay
            $timeout(function( ) {
                $rootScope.isGlobalLoading = false;
            }, 2000);

        };

        function download () {
            alertBar.hide();

            var parentRequestId = $scope.$parent.request.id;
            $rootScope.isGlobalLoading = true;
            Wmoapi
                .download(parentRequestId)
                .success(function (response) {
                    //download required code, configModule, configRemotes
                    //then start flash process
                    if (response.status === 'success') {
                        $scope.flashing = true;
                        $rootScope.flashInProgress = true;
                        $scope.percentage = 0;
                        $scope.$parent.responseData.firmwareDownload = response;
                        downloadConfigModule();
                    } else {
                        $rootScope.isGlobalLoading = false;
                        $scope.disableFlashButton = false;
                        $scope.showErrorMessage(false, 'FLASH_ERROR_DOWNLOAD', response.messages[0]);
                    }

                })
                .error(function (error) {

                    $rootScope.isGlobalLoading = false;
                    $scope.disableFlashButton = false;
                    $scope.showErrorMessage(false, 'FLASH_ERROR_DOWNLOAD', error);

                });

        }

        function downloadConfigModule() {

            var configModuleRequestId = $scope.$parent.request.configModuleRequestId;

            Wmoapi
                .download(configModuleRequestId)
                .success(function (response) {
                    $scope.$parent.responseData.configModuleDownload = response;
                    $scope.flashStart();
                })
                .error(function (error) {
                    if ($scope.keyreadingFlash){
                        $rootScope.$broadcast('flashError', 'FLASH_ERROR_DOWNLOAD');
                    } else {
                        $rootScope.isGlobalLoading = false;
                        $scope.disableFlashButton = false;
                        $scope.showErrorMessage(false, 'FLASH_ERROR_DOWNLOAD', error);
                    }
                });

        }

        $scope.flashStart = function () {
            alertBar.hide();
            $scope.percentage = 0;
            flashDevice($scope.$parent.responseData.firmwareDownload, $scope.flashComplete, $scope.flashError, $scope.flashProgress);
        };

        $scope.flashRestart = function () {
            deviceDetect.detect().then(
                function () {
                    alertBar.hide();
                    if($scope.warningModal) {
                        $scope.warningModal.close();
                        $scope.closeDeviceNotificationBar();
                    }
                    $rootScope.isGlobalLoading = true;
                    $scope.disableFlashButton = true;
                    $scope.flashing = true;
                    $scope.percentage = 0;
                    flashDevice($scope.$parent.responseData.firmwareDownload, $scope.flashComplete, $scope.flashError, $scope.flashProgress);
                }, function (error) {
                    if ($scope.keyreadingFlash){
                        $rootScope.$broadcast('flashError', 'DETECT_NO_DEVICE');
                    } else {
                        $scope.showErrorMessage(true, 'DETECT_NO_DEVICE', error.data || error);
                        $rootScope.isGlobalLoading = false;
                    }
                }
            );

        };

        function flashDevice(firmwareDownload, onSuccess, onError, onProgress) {
            var device = DeviceService.getFlashableDevice();
            var isRsuDevice = device && DeviceService.isRsuDevice(device);
            wu.flashDevice(device, firmwareDownload, isRsuDevice)
                .then(function (success) {
                    onSuccess(success);
                }, function (error) {
                    onError(error);
                }, function (update) {
                    onProgress(update);
                }).finally(function () {
                $scope.disableFlashButton = false;
            });
        }
        function flashImage(firmwareDownload, onSuccess, onError, onProgress) {
            var device = DeviceService.getFlashableDevice();
            wu.flashImage(device, firmwareDownload)
                .then(function (success) {
                    onSuccess(success);
                }, function (error) {
                    onError(error);
                }, function (update) {
                    onProgress(update);
                }).finally(function () {
                $scope.disableFlashButton = false;
            });
        }

        $scope.flashProgress = function (perc) {
            $rootScope.isGlobalLoading = false;
            $scope.percentage = Math.floor(perc);

            if(dataService.upgradeBootloader && perc >= 95 ) {
                $scope.percentage = 95;
            }

            if ($scope.percentage > 0) {
                $scope.progressBarActive = true;
            }

            var marginPercentage = 0;

            if ($scope.percentage > 94) {
                marginPercentage = 94;
            } else {
                marginPercentage = $scope.percentage;
            }
            if(perc >= 97  && $scope.keyreadingFlash) {
                $scope.percentage = 97;
            } else if(perc >= 99 ) {
                $scope.percentage = 99;
                $rootScope.isGlobalLoading = true;
            }

            $rootScope.$broadcast('flashProgress', $scope.percentage);

            $scope.progressBarPercentageStyle = {
                'margin-left': marginPercentage + '%'
            };

            $scope.progressBarPercentageKeyreadingStyle = {
                'left': 'calc(' + $scope.percentage + '% - 8px)'
            };

            $scope.progressBarGraphStyle = {
                'width': $scope.percentage + '%'
            };

        };

        $scope.flashError = function (e) {
            $rootScope.isGlobalLoading = false;
            console.error('error:' + e);
            $scope.flashing = false;
            $scope.percentage = 0;
            if ($scope.keyreadingFlash){
                $rootScope.$broadcast('flashError', e);
            } else {
                $scope.changePageView(1);
                $scope.gotoElement('body');
                $scope.showErrorMessage(true, 'FLASH_ERROR_FLASHING', e);
                $scope.apply();
            }
        };


        function triggerErrorView(key, error){
            console.error('error in:',key, 'err:',error);
            $rootScope.isGlobalLoading = false;
            $scope.flashing = false;
            $scope.percentage = 0;

            if ($scope.keyreadingFlash){
                $rootScope.$broadcast('flashError', key === 'FLASH_ERROR_FLASHING' ? error : key);
            } else {
                $scope.changePageView(1);
                $scope.gotoElement('body');
                $scope.showErrorMessage(true, key, error);
                $scope.apply();
            }

        }

        $scope.flashComplete = function () {
            $rootScope.isGlobalLoading = false;
            $scope.writeConfigModule();
        };

        $scope.writeConfigModule = function () {
            if ($scope.$parent.responseData.configModuleDownload.body){
                var pageContent = $scope.$parent.responseData.configModuleDownload.body.eepromMap;
                var device = DeviceService.getFlashableDevice();
                $scope.configResult = wu.getWriteModuleResult(device, pageContent, $scope.onWriteConfigModuleComplete, $scope.flashError);
                $scope.configResult.start();
            } else {
                $scope.onWriteConfigModuleError();
            }
        };

        $scope.onWriteConfigModuleComplete = function () {

            var skipControls = $scope.$parent.skipControls || isDetectedDeviceIsClassic;
            $rootScope.$broadcast('flashProgress', 98);

            if ($scope.$parent.configurationParameters.flashFromFavorite) {
                $scope.confirmAllRequests();
            } else {
                if (skipControls || $scope.rsuDataSelectedBrand || isDeviceUsedAsAddOn) {

                    var device = DeviceService.getFlashableDevice();
                    var currentFw = $scope.$parent.userSelection.selectedFirmware;

                    if ($scope.rsuDataSelectedBrand && device.isIdatastartRemotePairingCompatible && !currentFw.isIdatastartRemotePairingCompatible) {
                        console.log('---- setRemotes - clear remote eeprom ---- ');
                        $scope.setRemotes(true);
                    } else {
                        console.log('---- confirmAllRequests ---- ');
                        $scope.confirmAllRequests();
                    }
                } else {
                    console.log('---- setRemotes ---- ');
                    $scope.setRemotes(false);
                }
            }
        };

        $scope.onWriteConfigModuleError = function (e) {
            triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_FLASH',  e);
            console.error('error in onWriteConfigModuleError (writing features)' + e);
        };

        $scope.setRemotes = function (clearRemoteEeprom) {

            $rootScope.isGlobalLoading = true;

            var device = DeviceService.getFlashableDevice();
            var requiredPages = $scope.$parent.responseData.deviceControlPages;
            $rootScope.$broadcast('flashProgress', 99);

            if (requiredPages && requiredPages.length > 0) {

                //read remotes eeprom
                $scope.controlReadRequiredPagesResult = wu.getReadModuleResult(device, requiredPages,
                    function () {
                        //onReadRequiredPagesComplete

                        var readings = wu.transformPageReadingsToArray($scope.controlReadRequiredPagesResult.configuration);

                        $scope.$parent.responseData.controlRequiredPagesReadings = readings;

                        var esns = clearRemoteEeprom? [] : getSelectedEsn().esns;
                        var requestId = $scope.$parent.request.configRemotesRequestId;

                        Wmoapi
                            .setRemotes(requestId, esns, readings)
                            .success(function () {
                                $scope.downloadConfigRemotes();
                            })
                            .error(function (error) {
                                triggerErrorView('FLASH_ERROR_GENERIC',  error);
                                console.error('error in setRemotes:', error);
                            });

                    },
                    function (error) {
                        console.error('error wu.getReadModuleResult', error);
                        $timeout(function () {
                            $scope.flashing = false;
                            $scope.percentage = 0;
                        }, 0);
                        $scope.changePageView(1);
                        console.log('--- setRemotes');
                        triggerErrorView('FLASH_ERROR_FLASHING',  error);

                    });

                $scope.controlReadRequiredPagesResult.start();

            } else {
                console.error('Could not read remote settings.');
            }

        };

        $scope.downloadConfigRemotes = function () {

            var requestId = $scope.$parent.request.configRemotesRequestId;

            Wmoapi
                .download(requestId)
                .success(function (response) {
                    $scope.$parent.responseData.configRemotesDownload = response;
                    $scope.writeConfigRemotes();
                })
                .error(function (error) {
                    console.log('--- downloadConfigRemotes');
                    triggerErrorView('FLASH_ERROR_FLASHING',  error);
                });

        };

        $scope.writeConfigRemotes = function () {

            var pageContent = $scope.$parent.responseData.configRemotesDownload.body.eepromMap;
            var device = DeviceService.getFlashableDevice();

            $scope.configRemotesResult = wu.getWriteModuleResult(device, pageContent, $scope.onWriteConfigRemotesComplete, $scope.onWriteConfigRemotesError);
            $scope.configRemotesResult.start();
        };

        $scope.onWriteConfigRemotesComplete = function () {
            $scope.confirmAllRequests();
        };

        $scope.onWriteConfigRemotesError = function (e) {

            console.error('Error Remotes write to eeprom!', e);
            $rootScope.isGlobalLoading = false;

            if (e) {
                $timeout(function () {
                    $scope.flashing = false;
                    $scope.percentage = 0;
                }, 0);
                if ($scope.keyreadingFlash){
                    $rootScope.$broadcast('flashError', 'FLASH_ERROR_FLASHING');
                } else {
                    $scope.changePageView(1);
                }
            }
        };

        $scope.confirmAllRequests = function () {

            $rootScope.isGlobalLoading = true;

            var parentRequestId = $scope.$parent.request.id;
            var downloadId = $scope.$parent.responseData.firmwareDownload.downloadId;

            Wmoapi
                .confirm(parentRequestId, downloadId)
                .success(function () {
                    console.log('flash completed - confirming all req -');
                    $scope.confirmConfigModule();
                })
                .error(function (error) {
                    console.log('--- confirmAllRequests');
                    triggerErrorView('FLASH_ERROR_FLASHING',  error);
                });
        };

        $scope.confirmConfigModule = function () {

            $rootScope.isGlobalLoading = true;

            var configModuleRequestId = $scope.$parent.request.configModuleRequestId;
            var downloadModId = $scope.$parent.responseData.configModuleDownload.downloadId;
            var skipControls = $scope.$parent.skipControls || isDetectedDeviceIsClassic;

            // Create list queue
            if(!$scope.flashQueue.length) {
                var embeddedDevices = DeviceService.getEmbeddedDevices();
                if(embeddedDevices.length) {
                    embeddedDevices.map(function(device) {
                        if(deviceIdentifier.hasFirmwareUpdate(device).hasUpdate || deviceIdentifier.isDetectedDeviceFwDependant(device.productPartOptions) || deviceIdentifier.isDetectedDeviceVehicleDependantOnMainDevice(device.productPartOptions)) {
                            $scope.flashQueue.push(device.serial);
                        }
                    });
                }
                $scope.flashQueueCopy = angular.copy($scope.flashQueue);
            }

            // Setup Step
            $scope.currentFlashSerial = $scope.flashQueue[0];
            console.log('Flash queue: 1/'+ $scope.flashQueueCopy.length);

            // Setup UI
            $scope.flashnumber = 1;
            $scope.totalflash = $scope.flashQueueCopy.length;
            $scope.firmwareUpdateFlashing = true;
            
            var device = $scope.currentFlashSerial ? DeviceService.getDeviceBySerial($scope.currentFlashSerial) : false;
            var deviceFirmwareDet = device ? deviceIdentifier.hasFirmwareUpdate(device) : {};
            var deviceHasFwDependant = device ? deviceIdentifier.isDetectedDeviceFwDependant(device.productPartOptions) : false;
            var deviceHasVehicleDependant = device ? deviceIdentifier.isDetectedDeviceVehicleDependantOnMainDevice(device.productPartOptions) : false;

            Wmoapi
                .confirm(configModuleRequestId, downloadModId)
                .success(function () {
                    if (skipControls || isDeviceUsedAsAddOn || deviceHasFwDependant || deviceHasVehicleDependant || $scope.rsuDataSelectedBrand) {
                        if (deviceHasFwDependant || deviceHasVehicleDependant) {
                            console.log('start flashing HCF/FLRSBA/BA device...');
                            $rootScope.$broadcast('flashProgress', 98);
                            createRequestFlashByFw();
                        } else if (deviceFirmwareDet.hasUpdate) {
                            if($scope.flashQueue.length <= 1) {
                                confirmConfigComplete = true;
                            }
                            $scope.showUpdateMessage = false;
                            DeviceService.setDeviceToFlash(device);
                            createRequestFirmwareUpdate(device);
                        } else {
                            $scope.confirmConfigComplete();
                        }
                    } else {
                        if ($scope.$parent.configurationParameters.flashFromFavorite) {
                            $scope.confirmConfigComplete();
                        } else {
                            $scope.confirmConfigRemotes();

                        }
                    }
                })
                .error(function (error) {
                    console.log('--- confirmConfigModule');
                    triggerErrorView('FLASH_ERROR_FLASHING',  error);
                });
        };

        $scope.confirmConfigRemotes = function () {

            $rootScope.isGlobalLoading = true;

            var configRemotesRequestId = $scope.$parent.request.configRemotesRequestId;
            var downloadRemId = $scope.$parent.responseData.configRemotesDownload.downloadId;
            var embeddedDevice = DeviceService.getDeviceBySerial($scope.currentFlashSerial);
            var embeddedDeviceFirmwareDet = embeddedDevice ? deviceIdentifier.hasFirmwareUpdate(embeddedDevice) : {};

            Wmoapi
                .confirm(configRemotesRequestId, downloadRemId)
                .success(function () {
                    console.log('remotes write completed!');
                    if (embeddedDeviceFirmwareDet.hasUpdate) {
                        if($scope.flashQueue.length <= 1) {
                            confirmConfigComplete = true;
                        }
                        $scope.showUpdateMessage = false;
                        DeviceService.setDeviceToFlash(embeddedDevice);
                        createRequestFirmwareUpdate(embeddedDevice);
                    } else {
                        $scope.confirmConfigComplete();
                    }
                })
                .error(function (error) {
                    console.log('--- confirmConfigRemotes');
                    triggerErrorView('FLASH_ERROR_FLASHING',  error);
                });
        };

        $scope.confirmConfigComplete = function () {
            $rootScope.isGlobalLoading = false;
            $scope.$parent.sidebar.mode = 'flash-klon';
            $rootScope.$broadcast('flashProgress', 100);
            $scope.mostRecentCompletedStep.stepNumber++;
            $scope.$parent.sidebar.inGuides =  true;
            $scope.$parent.sidebar.currentIndex = isDetectedDeviceIsClassic ? 9 : 8;
            sidebarManager.setGuidesWiresSidebarStatus(true);
            $scope.$parent.showWiringSectio = true;
            $scope.gotoElement('summary');
            $scope.redetect();
            $scope.apply();
        };


        function getSelectedEsn () {
            var object = {
                brand:'iDatastart',
                esns: []
            };
            var deviceControlsArray = $scope.$parent.responseData.deviceControls;

            for (var i = 0; i < deviceControlsArray.length; i++) {
                object.esns.push(deviceControlsArray[i].esn);
            }
            return object;
        }

        $scope.getActivateRemotes = function () {

            $scope.selectedRemotes = getSelectedEsn();
        };




        $scope.redetect = function () {

            $scope.identifyDevice($scope.onRedetectComplete, $scope.onRedetectFailed);

        };

        $scope.onRedetectComplete = function (devicesResult) {

            DeviceService.setDevices(devicesResult);
            var device = DeviceService.getFlashableDevice();

            var detectedKlon = deviceIdentifier.hasKlonStep2Service(device.allowedServices);
            var isKlonHubRequired = $scope.responseData.currentFirmware.isKlonHubRequired;

            if (isKlonHubRequired) {
                // TODO: Determine the next steps via the Wmoapi.getWeblinkHubKlonSteps response
                if (device.hasProgram && !$scope.isAntennaUpdated) {
                    processStepAntenna(false);
                } else {
                    loadFlashSummaryPage();
                }

            } else if (detectedKlon === true) { //classic klon step 2
                $scope.showKlonStep = true;

                var guide = $scope.$parent.responseData.deviceGuides[0];
                $scope.installGuide = (guide) ? guide :
                    ((device.guideDownloadUrl) ? device.guideDownloadUrl : undefined);

                $timeout(function () {
                    $scope.flashing = false;
                }, 2000);
            } else {
                if (device.hasProgram && !$scope.isAntennaUpdated) {
                    processStepAntenna(false);
                } else {
                    loadFlashSummaryPage();
                }
            }
        };

        $scope.onHubRedetectComplete = function (devicesResult) {

            DeviceService.setDevices(devicesResult);
            var device = DeviceService.getFlashableDevice();
        };

        $scope.redetectAfterHubUpdate = function () {
            //$scope.identifyDevice($scope.onHubRedetectComplete, $scope.onRedetectFailed);
            $window.location.reload(true);
        };

        function getPackage(serial, fromKlon) {
            Wmoapi
                .getPackage(serial)
                .success(function (response) {

                    var pkg = response.package;

                    if (pkg === null || pkg === '' || pkg.packageId === '' || pkg.packageId === 0) {
                        showAntennaStep(fromKlon);
                    } else {
                        loadFlashSummaryPage();
                    }
                })
                .error(function (error) {
                    triggerErrorView('FLASH_KLON_ERROR_SUSPENDED_REQUEST_CHECK',  error);
                });
        }

        function showAntennaStep(fromKlon) {
            $scope.showAntennaStep = true;
            $timeout(function () {
                if (fromKlon)
                    $scope.flashingStep2 = false;
                else
                    $scope.flashing = false;
            }, 2000);
        }


        function loadFlashSummaryPage() {
            Request.clearRequestDevice();
            $timeout(function () {
                $rootScope.isGlobalLoading = false;
                $scope.flashingStep2 = false;
                $scope.flashing = false;
                $scope.percentage = 0;
            }, 0);
            $scope.flashSuccess = true;
            $scope.changePageView(2);
        }


        $scope.onRedetectFailed = function (e) {
            if (e) {
                $scope.flashErrorTitle = e;
                $scope.changePageView(1);
            }
        };

        $scope.getSuspendedRequest = function () {

            alertBar.hide();

            var device = DeviceService.getFlashableDevice();
            $rootScope.isGlobalLoading = true;


            $scope.SuspendedRequestResult = klonIdentifier.getSuspendedRequest(device.serial,
                function () { //onKlonGetSuspendedRequestComplete

                    var result = $scope.SuspendedRequestResult;
                    if (!result.hasSuspendedRequest) {
                        $rootScope.$broadcast('klonProgress', 10);
                        $scope.getMainPage();
                    } else {
                        $scope.$parent.responseData.klonSuspendedRequest = result.suspendedRequest;
                        $scope.$parent.request.klonRequestId = result.suspendedRequest.request_id;

                        $scope.getStatusUpdate();
                    }

                }, function (error) { //onKlonGetSuspendedRequestError
                    if ($scope.keyreadingFlash){
                        $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_SUSPENDED_REQUEST_CHECK',  error.data);
                    } else {
                        triggerErrorView('FLASH_KLON_ERROR_SUSPENDED_REQUEST_CHECK',  error.data);
                    }
                });
        };

        $scope.processStep2 = function () {
            $rootScope.isGlobalLoading = false;
            $scope.percentage = 0;
            $scope.flashingStep2 = true;
            $timeout(function () {
                $scope.showKlonStep = false;
            }, 2000);

        };

        $scope.getMainPage = function () {
            var device = DeviceService.getFlashableDevice();

            $scope.MainPageResult = klonIdentifier.getMainPage(device.serial,
                function () {
                    //onKlonGetRequiredPagesComplete

                    var result = $scope.MainPageResult;

                    if (result.hasMainPage) {
                        $scope.$parent.responseData.klonMainPage = result.mainPage;
                        $rootScope.$broadcast('klonProgress', 20);
                        $scope.readMainPage();
                    } else {
                        if ($scope.keyreadingFlash){
                            $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_NO_REQUIRED_PAGES');
                        } else {
                            triggerErrorView('FLASH_KLON_ERROR_NO_REQUIRED_PAGES',  '');
                        }
                    }

                }, function (error) {

                    //onKlonGetRequiredPagesError
                    if ($scope.keyreadingFlash){
                        $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_NO_REQUIRED_PAGES',  error.data);
                    } else {
                        triggerErrorView('FLASH_KLON_ERROR_NO_REQUIRED_PAGES',  error.data);
                    }
                });
        };

        $scope.readMainPage = function () {
            var mainPage = $scope.$parent.responseData.klonMainPage;

            if (mainPage.length > 0) {
                var device = DeviceService.getMainDevice();
                $scope.readMainPageResult = wu.getReadModuleResult(device, mainPage, $scope.onReadMainPageComplete, onReadMainPageError);
                $scope.readMainPageResult.start();
            } else {
                if ($scope.keyreadingFlash){
                    $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_NO_REQUIRED_PAGES');
                } else {
                    $scope.showErrorMessage(true, 'FLASH_KLON_ERROR_NO_REQUIRED_PAGES', '');
                }
            }

        };

        $scope.onReadMainPageComplete = function () {
            $rootScope.$broadcast('klonProgress', 30);
            $scope.$parent.responseData.klonMainPageReadings = wu.transformPageReadingsToArray($scope.readMainPageResult.configuration);
            $scope.checkIfHasHlsKlonLog();
        };

        $scope.checkIfHasHlsKlonLog = function () {
            var mainPage = $scope.$parent.responseData.klonMainPage;
            var mainPageContent = $scope.$parent.responseData.klonMainPageReadings[0][mainPage[0]];

            Wmoapi.hasHlsLog(mainPageContent).then(
                function(response){
                    var result = response.data;
                    if (result.hasHlsLog) {
                        $scope.readHlsLog(result.logName);
                    } else {
                        $scope.getRequiredPages();
                    }
                },
                function (error) {
                    if ($scope.keyreadingFlash){
                        $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_NO_REQUIRED_PAGES',  error.data);
                    } else {
                        triggerErrorView('FLASH_KLON_ERROR_NO_REQUIRED_PAGES',  error.data);
                    }
                }
            );
        };

        function onReadMainPageError (error) {
            if ($scope.keyreadingFlash){
                $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_READ_REQUIRED_PAGES', error);
            } else {
                $scope.showErrorMessage(true, 'FLASH_KLON_ERROR_READ_REQUIRED_PAGES', error);
            }
            $rootScope.isGlobalLoading = false;
        }

        $scope.readHlsLog = function (logName) {
            var device = DeviceService.getFlashableDevice();
            $scope.readHlsKlonLogResult = wu.getHlsKlonLog(device, logName, $scope.onGetHlsKlonLogComplete, onGetHlsKlonLogError);
            $scope.readHlsKlonLogResult.start();
        };

        $scope.onGetHlsKlonLogComplete = function () {
            $rootScope.$broadcast('klonProgress', 35);
            $scope.$parent.responseData.klonHlsLogContent = $scope.readHlsKlonLogResult.logContent;
            $scope.getLatestRequest();
        };

        function onGetHlsKlonLogError (error) {
            if ($scope.keyreadingFlash){
                $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_READ_KLON_HLS', error);
            } else {
                $scope.showErrorMessage(true, 'FLASH_KLON_ERROR_READ_KLON_HLS', error);
            }
            $rootScope.isGlobalLoading = false;
        }

        $scope.getRequiredPages = function () {

            var device = DeviceService.getFlashableDevice();
            var mainPage = $scope.$parent.responseData.klonMainPage;
            var mainPageContent = $scope.$parent.responseData.klonMainPageReadings[0][mainPage[0]];

            $scope.RequiredPagesResult = klonIdentifier.getRequiredPages(device.serial, mainPageContent,
                function () {
                    //onKlonGetRequiredPagesComplete

                    var result = $scope.RequiredPagesResult;

                    if (result.hasRequiredPages) {
                        $scope.$parent.responseData.klonRequiredPages = result.requiredPages;
                        $rootScope.$broadcast('klonProgress', 40);
                        $scope.readRequiredPages();
                    } else {
                        if ($scope.keyreadingFlash){
                            $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_NO_REQUIRED_PAGES');
                        } else {
                            triggerErrorView('FLASH_KLON_ERROR_NO_REQUIRED_PAGES',  '');
                        }
                    }

                }, function (error) {

                    //onKlonGetRequiredPagesError
                    if ($scope.keyreadingFlash){
                        $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_NO_REQUIRED_PAGES',  error.data);
                    } else {
                        triggerErrorView('FLASH_KLON_ERROR_NO_REQUIRED_PAGES',  error.data);
                    }
                });
        };

        $scope.readRequiredPages = function () {

            var requiredPages = $scope.$parent.responseData.klonRequiredPages;
            if (requiredPages.length > 0) {
                var device = DeviceService.getMainDevice();
                $scope.readRequiredPagesResult = wu.getReadModuleResult(device, requiredPages, $scope.onReadRequiredPagesComplete, onReadRequiredPagesError);
                $scope.readRequiredPagesResult.start();
            } else {
                if ($scope.keyreadingFlash){
                    $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_NO_REQUIRED_PAGES');
                } else {
                    $scope.showErrorMessage(true, 'FLASH_KLON_ERROR_NO_REQUIRED_PAGES', '');
                }
            }

        };

        $scope.onReadRequiredPagesComplete = function () {
            $rootScope.$broadcast('klonProgress', 60);
            var readings = wu.transformPageReadingsToArray($scope.readRequiredPagesResult.configuration);
            $scope.$parent.responseData.klonRequiredPagesReadings = readings;
            $scope.isDevicePrepared();

        };

        function onReadRequiredPagesError (error) {
            if ($scope.keyreadingFlash){
                $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_READ_REQUIRED_PAGES', error);
            } else {
                $scope.showErrorMessage(true, 'FLASH_KLON_ERROR_READ_REQUIRED_PAGES', error);
            }
            $rootScope.isGlobalLoading = false;
        }

        $scope.isDevicePrepared = function () {

            var device = DeviceService.getFlashableDevice();
            var pageReadings = $scope.$parent.responseData.klonRequiredPagesReadings;

            $scope.DevicePreparedResult = klonIdentifier.isDevicePrepared(device.serial, pageReadings,
                function () {

                    //onDevicePreparedComplete
                    $rootScope.$broadcast('klonProgress', 70);
                    $scope.$parent.responseData.klonDevicePrepared = $scope.DevicePreparedResult;
                    $scope.getLatestRequest();

                }, function (error) {
                    if ($scope.keyreadingFlash){
                        $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_DEVICE_PREPARED_CHECK', error.data);
                    } else {
                        $scope.showErrorMessage(true, 'FLASH_KLON_ERROR_DEVICE_PREPARED_CHECK', error.data);
                    }
                });
        };

        $scope.getLatestRequest = function () {

            var device = DeviceService.getFlashableDevice();

            $scope.LatestRequestResult = klonIdentifier.getLatestRequest(device.bootloader.version, device.serial,
                function () { //onKlonGetLatestRequestComplete

                    var result = $scope.LatestRequestResult;
                    if (result.hasLatestRequest) {

                        var latestRequest = result.latestRequest;
                        $scope.$parent.responseData.klonLatestRequest = latestRequest;
                        $scope.mainParentRequestId = latestRequest.action.request_id;
                        $rootScope.$broadcast('klonProgress', 75);
                        $scope.createRequestForKlon();

                    } else {
                        if ($scope.keyreadingFlash){
                            $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_LATEST_REQUEST_CHECK');
                        } else {
                            triggerErrorView('FLASH_KLON_ERROR_LATEST_REQUEST_CHECK', '');
                        }
                        triggerErrorView('FLASH_KLON_ERROR_LATEST_REQUEST_CHECK', '');
                    }

                }, function (error) { //onKlonGetLatestRequestError
                    if ($scope.keyreadingFlash){
                        $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_LATEST_REQUEST_CHECK', error.data);
                    } else {
                        triggerErrorView('FLASH_KLON_ERROR_LATEST_REQUEST_CHECK', error.data);
                    }
                });
        };

        $scope.createRequestForKlon = function () {

            var device = DeviceService.getFlashableDevice();
            var latestRequest = $scope.$parent.responseData.klonLatestRequest;
            $scope.percentage = 0;

            $scope.CreateRequestResult = klonIdentifier.createRequest(device.bootloader.version, device.serial, latestRequest,
                function () {

                    //onKlonCreateRequestComplete

                    var result = $scope.CreateRequestResult;

                    if (result.hasRequestId) {
                        $scope.$parent.request.klonRequestId = result.requestId;
                        if ($scope.keyreadingFlash){
                            $rootScope.$broadcast('klonProgress', 80);
                            $scope.hubSendReadings();
                        } else {
                            $scope.sendReadings();
                        }

                    } else {
                        if ($scope.keyreadingFlash){
                            $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_CREATE_REQUEST');
                        } else {
                            triggerErrorView('FLASH_KLON_ERROR_CREATE_REQUEST', '');
                        }
                    }

                }, function (error) {
                    if ($scope.keyreadingFlash){
                        $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_CREATE_REQUEST', error.data);
                    } else {
                        triggerErrorView('FLASH_KLON_ERROR_CREATE_REQUEST', error.data);
                    }

                });
        };

        $scope.hubSendReadings = function(){
            var klonRequestId = $scope.$parent.request.klonRequestId;
            var pageReadings = $scope.$parent.responseData.klonRequiredPagesReadings;
            var klonHlsLog = $scope.$parent.responseData.klonHlsLogContent;

            if(pageReadings.length > 0){
                Wmoapi
                    .weblinkHubSaveReadings(klonRequestId, pageReadings)
                    .success(function (response) {
                        $scope.mostRecentCompletedStep.stepNumber++;
                        $rootScope.$broadcast('klonProgress', 100);
                    })
                    .catch(function(error){
                        $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_SEND_READINGS', error.data || error.error || error);
                    });
            }

            if (klonHlsLog) {
                Wmoapi
                    .weblinkHubSaveHLSReadings(klonRequestId, klonHlsLog)
                    .success(function (response) {
                        $scope.mostRecentCompletedStep.stepNumber++;
                        $rootScope.$broadcast('klonProgress', 100);
                    })
                    .catch(function(error){
                        $rootScope.$broadcast('klonError', 'FLASH_KLON_ERROR_SEND_READINGS', error.data || error.error || error);
                    });
            }

        };

        $scope.sendReadings = function () {

            var klonRequestId = $scope.$parent.request.klonRequestId;
            var pageReadings = $scope.$parent.responseData.klonRequiredPagesReadings;
            $scope.percentage = 0;

            $scope.SendReadingsResult = klonIdentifier.sendReadings(klonRequestId, pageReadings,
                function () {

                    //onSendReadingsComplete
                    var result = $scope.SendReadingsResult;
                    $scope.$parent.responseData.klonSendReadings = result;

                    if (result.success) {
                        $scope.waitTimeInSeconds = 0;
                        $scope.processStep2();
                        $scope.showRestartButton = false;
                        $scope.showStartConfigButton = true;
                        if (result.ready) {
                            klonDownload();
                        } else {
                            $scope.startKlonCountdown(result.waitTimeInSeconds);
                        }

                    } else {
                        triggerErrorView('FLASH_KLON_ERROR_SEND_READINGS', '');
                    }

                }, function (error) {
                    console.error('error in sendReadings:', error.data || error);
                    triggerErrorView('FLASH_KLON_ERROR_SEND_READINGS', error.data || error);

                });
        };

        var stopKlonCount;
        $scope.startKlonCountdown = function (waitTimeInSeconds) {

            var fullTimeFormat = ('hh hours, mm minute(s), ss second(s)');
            var partTimeFormat = ('mm minute(s), ss second(s)');


            if (angular.isDefined(stopKlonCount)) return;

            var checkEvery = 0, elapsed = 0;

            if (waitTimeInSeconds === 0) {
                checkEvery = 15;
            } else if (waitTimeInSeconds > 60) {
                checkEvery = 60;
            } else {
                checkEvery = 30;
            }

            stopKlonCount = $interval(function () {
                if (waitTimeInSeconds > 0 && elapsed <= checkEvery) {
                    waitTimeInSeconds--;
                    elapsed++;
                    $scope.waitTimeInSeconds = waitTimeInSeconds;
                    $scope.waitTimeFormat = (waitTimeInSeconds >= 3600 ? fullTimeFormat : partTimeFormat);
                    console.log('klon countdown: ' + waitTimeInSeconds);
                    $rootScope.isGlobalLoading = false;
                } else {
                    $scope.waitTimeInSeconds = 0;
                    $scope.stopKlonCountdown();
                    $scope.getStatusUpdate();
                }
            }, 1000);

        };

        $scope.stopKlonCountdown = function () {
            console.log('-stopKlonCountdown-');
            if (angular.isDefined(stopKlonCount)) {
                $interval.cancel(stopKlonCount);
                stopKlonCount = undefined;
            }
        };

        $scope.getStatusUpdate = function () {

            var klonRequestId = $scope.$parent.request.klonRequestId;

            $scope.StatusUpdateResult = klonIdentifier.getStatusUpdate(klonRequestId,
                function () {

                    //onStatusUpdateComplete

                    var result = $scope.StatusUpdateResult;
                    $scope.$parent.responseData.klonStatusUpdate = result;

                    if (result.ready) {
                        klonDownload();
                    } else {
                        $scope.startKlonCountdown(result.waitTimeInSeconds);
                    }

                }, function (error) {
                    triggerErrorView('FLASH_KLON_ERROR_STATUS_UPDATE', error.data || error);
                });
        };

        function klonDownload () {
            alertBar.hide();
            $scope.percentage = 0;
            $scope.flashExtProgError = false;
            var klonRequestId = $scope.$parent.request.klonRequestId;


            Wmoapi
                .download(klonRequestId)
                .success(function (response) {
                    $scope.$parent.responseData.klonDownload = response;
                    $scope.showQuitFinishLater = false;
                    $rootScope.isGlobalLoading = false;
                    flashDevice(response, $scope.klonFlashComplete, $scope.klonFlashError, $scope.klonFlashProgress);

                })
                .error(function (error) {
                    $scope.flashExtProgError = true;
                    console.error('error in klonDownload:',error);
                    triggerErrorView('FLASH_KLON_ERROR_DOWNLOAD', error);
                });
        }
        $scope.klonFlashProgress = function (perc) {
            $rootScope.$broadcast('flashProgress', Math.floor(perc));
            $scope.percentage = Math.floor(perc);

            if ($scope.percentage > 0) {
                $scope.klonProgressBarActive = true;
            }

            var marginPercentage = 0;
            if ($scope.percentage > 94) {
                marginPercentage = 94;
            } else {
                marginPercentage = $scope.percentage;
            }

            $scope.progressBarPercentageKlonStyle = {
                'margin-left': marginPercentage + '%'
            };

            $scope.progressBarGraphKlonStyle = {
                'width': $scope.percentage + '%'
            };

            $scope.apply();
        };

        $scope.klonFlashComplete = function () {

            var klonRequestId = $scope.$parent.request.klonRequestId;
            var klonDownload = $scope.$parent.responseData.klonDownload;
            $scope.showRestartButton = false;
            $scope.showStartConfigButton = true;

            Wmoapi
                .confirm(klonRequestId, klonDownload.downloadId)
                .success(function (response) {
                    console.log('klonFlashComplete !!!');
                    $rootScope.isGlobalLoading = false;
                    $scope.identifyDevice($scope.klonFlashCompleteDetectComplete, $scope.onRedetectFailed);
                })
                .error(function (error) {
                    triggerErrorView('FLASH_KLON_ERROR_REQUEST_CONFIRM', error);
                });
        };

        $scope.klonFlashCompleteDetectComplete = function (devicesResult) {

            DeviceService.setDevices(devicesResult);
            var device = DeviceService.getFlashableDevice();
            $rootScope.isGlobalLoading = true;

            if ($rootScope.keyreadingFlash) {
                $scope.mostRecentCompletedStep.stepNumber++;
            }

            if (!$scope.$parent.flashForKlonOnly) {

                if (device.hasProgram) {

                    $scope.getLatestRequestForAntenna();

                } else {
                    loadFlashSummaryPage();
                }
            } else {
                if (device.hasProgram) {
                    $scope.getLatestRequestForAntenna();
                } else {

                    deviceDetect.detect().then(
                        function (success) {
                            messageBox.show('FLASH_KLON_STEP2_INSTRUCTION_TITLE', '');
                            $scope.$parent.loadDevicePartial();
                            DeviceService.setDevices(success);
                            $rootScope.isGlobalLoading = false;
                        }, function (error) {
                            $scope.showErrorMessage(true, 'DETECT_NO_DEVICE', error.data || error);
                            $rootScope.isGlobalLoading = false;
                        }
                    );

                }

            }
        };

        $scope.getLatestRequestForAntenna = function () {

            var device = DeviceService.getFlashableDevice();
            var bootloader = device.bootloader.version;
            var serial = device.serial;

            Wmoapi
                .getLatestRequest(bootloader, serial)
                .success(function (response) {
                    $rootScope.isGlobalLoading = false;
                    $scope.mainParentRequestId = response.action.request_id;
                    processStepAntenna(true);
                })
                .error(function (error) {
                    console.error('error in getLatestRequestForAntenna:', error);
                    alertBar.show('errorFullWhite', 'FLASH_ANTENNA_ERROR_GET_LATEST_REQUEST');
                });
        };

        function processStepAntenna(fromKlon) {
            //here we check if already activated or not
            var rfDecoder = $scope.$parent.responseData.rfDecoder;
            if (rfDecoder) {
                var serial = rfDecoder.serial;
                getPackage(serial, fromKlon);
            } else {
                showAntennaStep(fromKlon);
            }
        }

        $scope.updateAntenna = function () {

            $scope.flashing = true;
            $scope.percentage = 0;
            $scope.detectAntenna();

        };

        $scope.createRequestForAntenna = function () {

            $rootScope.isGlobalLoading = true;

            var device = DeviceService.getFlashableDevice();
            var bootloader = device.bootloader.version;
            var serial = device.serial;
            var parentRequestId = $scope.mainParentRequestId;

            Wmoapi
                .createRequestConfigureAntenna(bootloader, serial, parentRequestId)
                .success(function (response) {
                    var requestId = response.requestId;
                    $scope.$parent.request.configAntennaRequestId = requestId;
                    $scope.antennaFlashDownload(requestId);
                })
                .error(function (error) {
                    triggerErrorView('FLASH_ANTENNA_ERROR_CREATE_REQUEST', error);
                });
        };

        $scope.createRequestForFlexAntennaUpgrade = function () {
            alertBar.hide();
            $scope.percentage = 0;
            $scope.showErrorNotificationBar = false;
            $scope.flashing = true;
            $rootScope.isGlobalLoading = true;
            $scope.showQuitProcessBtn = false;
            var device = DeviceService.getFlashableDevice();
            var bootloader = device.bootloader.version;
            var serial = device.serial;
            var packageChangeId = $scope.userSelection.packageChangeId;

            Wmoapi
                .createRequestFlexAntennaUpgrade(bootloader, serial, packageChangeId)
                .success(function (response) {
                    var requestId = response.requestId;
                    $scope.$parent.request.configAntennaRequestId = requestId;
                    $scope.antennaFlashDownload(requestId);
                })
                .error(function(error){
                    $scope.showQuitProcessBtn = true;
                    triggerErrorView('FLASH_ANTENNA_ERROR_CREATE_REQUEST_UPGRADE', error);
                });
        };


        //bootloader upgrade fix process
        $scope.createRequestForBootloaderUpgrade = function () {
            $scope.showErrorNotificationBar = false;
            alertBar.hide();
            var device = DeviceService.getFlashableDevice();
            var bootloader = device.bootloader.version;
            var serial = device.serial;

            $scope.flashing = true;
            $rootScope.isGlobalLoading = true;
            $scope.showQuitProcessBtn = false;

            Wmoapi
                .createRequestBootloaderUpgrade(bootloader, serial)
                .success(function (response) {
                    var requestId = response.requestId;
                    $scope.$parent.request.configBootloaderRequestId = requestId;
                    $scope.bootloaderFlashDownload(requestId);
                })
                .error(function (error) {
                    $scope.showQuitProcessBtn = true;
                    triggerErrorView('FLASH_BOOTLOADER_ERROR_CREATE_REQUEST_UPGRADE', error);
                });
        };


        $scope.bootloaderFlashDownload = function (requestId) {

            Wmoapi
                .download(requestId)
                .success(function (response) {

                    var bootloaderDownload = response;
                    $scope.$parent.responseData.configBootloaderDownload = response;
                    $scope.antennaCreateFlashProcess(bootloaderDownload, bootloaderFlashComplete, bootloaderFlashError);
                })
                .error(function (error) {
                    triggerErrorView('FLASH_BOOTLOADER_ERROR_DOWNLOAD', error);
                 });
        };

        function bootloaderFlashError(e) {
            console.log('error in flash bootloader upgrade process: ', e);
            $rootScope.isGlobalLoading = false;
            $scope.event = restartFlashBootloader;
            $scope.showErrorNotificationBar = true;
            $scope.errorTitle = 'FLASH_BOOTLOADER_ERROR_TITLE';
            $scope.errorMessage = 'FLASH_BOOTLOADER_ERROR_DESCRIPTION';
            $scope.apply();
        }

        function bootloaderFlashComplete () {
            var configBootloaderRequestId = $scope.$parent.request.configBootloaderRequestId;
            var downloadId = $scope.$parent.responseData.configBootloaderDownload.downloadId;

            Wmoapi
                .confirm(configBootloaderRequestId, downloadId)
                .success(function () {
                    $scope.percentage = 100;
                    console.log('flashing bootloader upgrade completed!');
                    $scope.startCountdown(30);
                    $scope.redirectTimeInSeconds = 30;
                    ngDialog.openConfirm({
                        template: 'modules/core/views/modals/bootloaderupdatedelay.modal.client.view.html',
                        className: 'ngdialog-theme-plain',
                        closeByDocument: false,
                        showClose: false,
                        scope: $scope
                    }).then(function (){
                        $rootScope.isGlobalLoading = false;
                        Request.clearRequestDevice();
                        $scope.$parent.loadDevicePartial();
                    },
                        function (){
                            $rootScope.isGlobalLoading = false;
                            Request.clearRequestDevice();
                            $scope.$parent.loadDevicePartial();
                        });
                })
                .error(function () {
                    alertBar.show('errorFullWhite', 'FLASH_BOOTLOADER_ERROR_CONFIRM');
                });
        }
        var stopCount;
        $scope.startCountdown = function(waitTimeInSeconds){

            if (angular.isDefined(stopCount)) return;

            stopCount = $interval(function() {
                if (waitTimeInSeconds>0) {
                    $scope.redirectTimeInSeconds = waitTimeInSeconds;
                    waitTimeInSeconds--;
                } else {
                    $scope.redirectTimeInSeconds = 0;
                    $scope.stopCountdown();
                }
            }, 1000);
        };

        $scope.stopCountdown = function(){
            if (angular.isDefined(stopCount)) {
                $interval.cancel(stopCount);
                stopCount = undefined;
            }
        };

        function restartFlashBootloader() {
            $scope.percentage = 0;
            $scope.showErrorNotificationBar = false;
            flashDevice($scope.$parent.responseData.configBootloaderDownload, bootloaderFlashComplete, bootloaderFlashError, $scope.flashProgress);
        }

        
        $scope.antennaFlashDownload = function (requestId) {
            Wmoapi
                .download(requestId)
                .success(function (response) {
                    var antennaDownload = response;
                    $scope.$parent.responseData.configAntennaDownload = response;
                    $scope.antennaCreateFlashProcess(antennaDownload, $scope.antennaFlashComplete, $scope.antennaFlashError);
                })
                .error(function (error) {
                    triggerErrorView('FLASH_ANTENNA_ERROR_DOWNLOAD', error);

                });
        };

        $scope.antennaCreateFlashProcess = function (antennaDownload, onComplete, onError) {
            $rootScope.isGlobalLoading = false;
            $scope.showAntennaStep = false;
            $scope.apply();
            flashDevice(antennaDownload, onComplete, onError, $scope.flashProgress);
        };

        var writeConfigForTrial = function () {

            var pageContent = $scope.$parent.responseData.configTrialActivationDownload.body.eepromMap;
            var device = DeviceService.getFlashableDevice();
            $scope.configTrialResult = wu.getWriteModuleResult(device, pageContent, $scope.trialActivationFlashComplete, $scope.trialActivationFlashError);
            $rootScope.isGlobalLoading = false;
            $scope.configTrialResult.start();

        };

        var trialActivationFlashDownload = function (requestId) {

            Wmoapi
                .download(requestId)
                .success(function (response) {
                    var trialActivationDownload = response;
                    $scope.$parent.responseData.configTrialActivationDownload = response;
                    writeConfigForTrial(trialActivationDownload);
                })
                .error(function (error) {
                    triggerErrorView('TRIAL_ACTIVATION_ERROR_DOWNLOAD', error);
                });
        };


        var createRequestForActivationMode = function () {

            var antennaRequestId = $scope.$parent.request.configAntennaRequestId;
            var activateTrialModeEmail = dataService.activateTrialModeEmail;

            Wmoapi
                .createRequestTrialActivation(antennaRequestId, activateTrialModeEmail)
                .success(function (response) {
                    var requestId = response.requestId;
                    $scope.$parent.request.configTrialActivationRequestId = requestId;
                    trialActivationFlashDownload(requestId);
                })
                .error(function (error) {
                    triggerErrorView('TRIAL_ACTIVATION_ERROR_CREATE_REQUEST', error);
                });
        };

        $scope.antennaFlashComplete = function () {

            if (dataService.activateTrialMode === true) {
                createRequestForActivationMode();
            } else {
                $scope.confirmConfigAntenna();
            }

        };

        var confirmConfigTrialActivation = function () {

            var configTrialActivationRequestId = $scope.$parent.request.configTrialActivationRequestId;
            var downloadId = $scope.$parent.responseData.configTrialActivationDownload.downloadId;

            Wmoapi
                .confirm(configTrialActivationRequestId, downloadId)
                .success(function () {
                    console.log('flashing for trial activation completed!');
                    $rootScope.isGlobalLoading = false;

                    dataService.activateTrialMode = false;
                    dataService.activateTrialModeEmail = '';
                    dataService.showTrialPopUp = false;

                    $scope.confirmConfigAntenna();
                })
                .error(function (error) {
                    triggerErrorView('TRIAL_ACTIVATION_ERROR_CONFIRM', error);
                });

        };

        $scope.trialActivationFlashComplete = function () {
            confirmConfigTrialActivation();
        };

        $scope.trialActivationFlashError = function (e) {
            console.error('error flash trial activation : ' + e);
            $scope.showErrorNotificationBar = true;
            $scope.apply();
        };

        $scope.confirmConfigAntenna = function () {

            var configAntennaRequestId = $scope.$parent.request.configAntennaRequestId;
            var downloadId = $scope.$parent.responseData.configAntennaDownload.downloadId;

            Wmoapi
                .confirm(configAntennaRequestId, downloadId)
                .success(function () {
                    console.log('flashing antenna completed !');
                    $rootScope.isGlobalLoading = false;
                    $scope.isAntennaUpdated = true;
                    if ($scope.$parent.flashForFlexUpgradeOnly  || $scope.$parent.flashForKlonOnly) {
                        messageBox.show('POPUP_FIRMWAREUPGRADEFLEX_SAVED_TITLE', '');
                        Request.clearRequestDevice();
                        $scope.$parent.loadDevicePartial();
                    } else {
                        $scope.redetect();
                    }
                })
                .error(function (error) {
                    triggerErrorView('FLASH_ANTENNA_ERROR_CONFIRM', error);
                });
        };

        $scope.antennaFlashError = function () {
            console.log('error flash antenna : ');
            $scope.event = restartFlashAntenna;
            $scope.showErrorNotificationBar = true;
            $scope.errorTitle = 'FLASH_ANTENNA_ERROR_TITLE';
            $scope.errorMessage = 'FLASH_ANTENNA_ERROR_DESCRIPTION';
            $scope.apply();
        };

        function restartFlashAntenna() {
            $scope.percentage = 0;
            $scope.showErrorNotificationBar = false;
            flashDevice($scope.$parent.responseData.configAntennaDownload, $scope.antennaFlashComplete, $scope.antennaFlashError, $scope.flashProgress);
        }

        $scope.klonFlashError = function (e) {
            if (e) {
                $scope.flashExtProgError = true;
                $scope.showErrorMessage(true, 'FLASH_KLON_ERROR_FLASHING', e);
                $scope.apply();
            }
        };

        $scope.$on('$destroy', function () {
            $scope.stopKlonCountdown();
        });

        $scope.quitKlonProcess = function () {

            alertBar.hide();

            if (!$scope.$parent.flashForKlonOnly) {
                $scope.flashSuccess = true;
                $scope.changePageView(2);
                $scope.showKlonStep = false;
            }
            else {
                $scope.$parent.loadDevicePartial();
                $timeout(function () {
                    $scope.showKlonStep = false;
                }, 1000);
            }
        };

        $scope.finishLaterKlon = function () {

            $rootScope.isGlobalLoading = true;
            alertBar.hide();
            $scope.stopKlonCountdown();
            $scope.identifyDevice($scope.finishLaterKlonDetectComplete, $scope.onRedetectFailed);
        };

        $scope.finishLaterKlonDetectComplete = function (devicesResult) {

            DeviceService.setDevices(devicesResult);
            $scope.$parent.detectedDevice = devicesResult[0];
            Request.clearRequestDevice();
            $scope.$parent.request.id = '';
            $scope.$parent.loadDevicePartial();
            loadFlashSummaryPage();
        };

        $scope.identifyDevice = function (onComplete, onError) {

            var device = DeviceService.getMainDevice();
            var serial = device.serial;
            var bootloader = device.bootloader.version;

            if(deviceIdentifier.isDeviceHub(device.partId) && !deviceIdentifier.hasFirmwareUpdate(device).hasUpdate) {
                var restartHub = wu.restartHub(device, $window.location.reload(true), onRestartHubFailed);
                restartHub.start();
            } else {
                $scope.result = deviceIdentifier.identify(serial, bootloader, onComplete, onError);
            }


        };

        $scope.gotoElement = function (eID) {
            $location.hash(eID);
            anchorSmoothScroll.scrollTo(eID);
        };



        function flashFlexUpgrade() {
            $scope.createRequestForFlexAntennaUpgrade();
        }


        function flashBootloderUpgrade() {
            $scope.createRequestForBootloaderUpgrade();
        }

        $scope.flashFirmwareUpdate = function () {
            // Create list queue
            if(!$scope.flashQueue.length) {
                if(dataService.mainDeviceSerialToUpdate) {
                    $scope.flashQueue.push(dataService.mainDeviceSerialToUpdate);
                }
                if(dataService.embeddedDevicesSerialsToUpdate) {
                    $scope.flashQueue = [].concat($scope.flashQueue, dataService.embeddedDevicesSerialsToUpdate);
                }
                if(dataService.rsuDeviceSerialToUpdate) {
                    $scope.flashQueue.push(dataService.rsuDeviceSerialToUpdate);
                }
                $scope.flashQueueCopy = angular.copy($scope.flashQueue);
            }

            // Setup Step
            $scope.currentFlashSerial = $scope.flashQueue[0];
            console.log('Flash queue: 1/'+ $scope.flashQueueCopy.length);

            // Setup UI
            $scope.totalflash = $scope.flashQueueCopy.length;

            if ($scope.$parent.responseData.setFirmware.hasLcdRemoteFirmwareUpdate) {
                $scope.totalflash++;
            }

            var mainDevice = DeviceService.getMainDevice();
            if(deviceIdentifier.isDeviceHub(mainDevice.partId)) {
                $scope.totalflash = $scope.totalflash - 1;
            }

            $scope.showProgressMessage = $scope.totalflash > 1;
            $scope.firmwareUpdateFlashing = true;
            var device = DeviceService.getDeviceBySerial($scope.currentFlashSerial);
            // if there is only update for embedded devices
            if (!dataService.mainDeviceHasFwUpdate) {
                DeviceService.setDeviceToFlash(device);
                // If is RSU device
                if($scope.currentFlashSerial === dataService.rsuDeviceSerialToUpdate) {
                    createRequestRsuFirmwareUpdate(device);

                } else {
                    createRequestFirmwareUpdate(device);
                }
            } else {
                // updater for both
                if ($scope.$parent.configurationParameters.flashByFirmware){
                    createRequestFirmwareUpdate(device);
                } else {
                    firmwareUpdateGetConfigEeprom();
                }

            }

        };

        function createRequestRsuFirmwareUpdate(rsuDevice) {
            $scope.$parent.request.id = '';
            $scope.$parent.responseData.firmwareDownload = {};
            $scope.$parent.request.configModuleRequestId = '';
            $scope.$parent.responseData.configModuleDownload = {};

            $rootScope.isGlobalLoading = true;
            $rootScope.flashInProgress = true;
            $scope.flashing = true;
            alertBar.hide();

            Wmoapi
                .createRequestRsuFirmwareUpdate(rsuDevice.Product.rsuManufacturerId, rsuDevice.Product.id)
                .success(function (response) {
                    var requestId = response.requestId;
                    $scope.$parent.request.id = requestId;
                    Request.setRequestDevice(requestId, rsuDevice.bootloader.version, rsuDevice.serial);
                    $scope.firmwareUpdateDownload(response.requestId);
                })
                .error(function (error) {
                    triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_CREATEREQUEST', error);
                });

        }

        function createRequestFirmwareUpdate(device) {

            var serial = device.serial;
            var bootloader = device.bootloader.version;
            $rootScope.isGlobalLoading = true;
            $rootScope.flashInProgress = true;
            $scope.flashing = true;
            alertBar.hide();

            $scope.$parent.request.parentRequestId = $scope.$parent.request.id;

            if ($scope.$parent.firmwareAlreadySet) {
                $scope.$parent.resetFirmwareFlag();
                $scope.firmwareUpdateDownload($scope.$parent.request.id);
            } else {

                $scope.$parent.request.id = '';
                $scope.$parent.responseData.firmwareDownload = {};
                $scope.$parent.request.configModuleRequestId = '';
                $scope.$parent.responseData.configModuleDownload = {};

                Wmoapi
                    .createRequestFirmwareUpdate(bootloader, serial)
                    .success(function (response) {

                        var requestId = response.requestId;

                        $scope.$parent.request.id = requestId;
                        Request.setRequestDevice(requestId, bootloader, serial);
                        $scope.firmwareUpdateSetFirmware(device, requestId);

                    })
                    .error(function (error) {
                        triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_CREATEREQUEST', error);
                    });
            }
        }

        $scope.firmwareUpdateSetFirmware = function (detectedDevice, requestId) {

            var firmwareId = parseInt(detectedDevice.latestId);

            Wmoapi
                .setFirmware(requestId, firmwareId)
                .success(function (response) {
                    $scope.$parent.responseData.setFirmware = response;
                    if($scope.$parent.responseData.setFirmware.hasLcdRemoteFirmwareUpdate){
                        Wmoapi.createRequestFlashExternalMemory(detectedDevice.serial, requestId).then(function(response){
                            $scope.$parent.externalMemoryRequest.id = response.data.requestId;
                            $scope.firmwareUpdateDownload(requestId);
                        }, function (error) {
                            triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_CREATEREQUEST', error);
                        });
                    } else {
                        $scope.firmwareUpdateDownload(requestId);
                    }
                })
                .error(function (error) {
                    triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_SETFIRMWARE', error);
                });

        };

        $scope.firmwareUpdateDownload = function (requestId) {

            Wmoapi
                .download(requestId)
                .success(function (response) {

                    if (response.status === 'success') {
                        var firmwareDownload = response;
                        $scope.$parent.responseData.firmwareDownload = firmwareDownload;
                        $scope.firmwareUpdateCreateFlashDevice(firmwareDownload);
                    } else {
                        $rootScope.isGlobalLoading = false;
                        $scope.disableFlashButton = false;
                        $scope.showErrorMessage(false, 'FLASH_FIRMWAREUPDATE_ERROR_DOWNLOAD', response.messages[0]);
                    }
                })
                .error(function (error) {
                    triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_DOWNLOAD', error);
                });

        };

        $scope.firmwareUpdateCreateFlashDevice = function (firmwareDownload) {
            $rootScope.isGlobalLoading = false;
            flashDevice(firmwareDownload, firmwareUpdateFlashComplete, firmwareUpdateWuError, $scope.flashProgress);
        };

        function firmwareUpdateFlashComplete () {
            var device = DeviceService.getFlashableDevice();
            var isRfid = deviceIdentifier.isDeviceRfid(device.deviceFunctionId);
            var isHub = deviceIdentifier.isDeviceHub(device.partId);
            var isRsu = DeviceService.isRsuDevice(device);
            var isFlashingMainDevice = device.serial === DeviceService.getMainDevice().serial;
            var hasConfigureModule = $scope.$parent.responseData.setFirmware.hasConfig;

            $scope.firmwareUpdateFlashing = true;
            var request_id = $scope.$parent.request.id;
            var downloadId = $scope.$parent.responseData.firmwareDownload.downloadId;
            if (isHub) {
                confirmFirmwareUpdate(request_id, downloadId, onHubFirmwareUpdateComplete);
            } else if (isRfid || isRsu || !hasConfigureModule) {
                $scope.flashQueue = $scope.flashQueue.splice(1); // clear queue
                if ($scope.flashQueue.length) {
                    confirmFirmwareUpdate(request_id, downloadId, triggerFlashUpdateEmbeddedDevice);
                } else {
                    if (confirmConfigComplete) {
                        confirmFirmwareUpdate(request_id, downloadId, $scope.confirmConfigComplete);
                    } else {
                        confirmFirmwareUpdate(request_id, downloadId, onFirmwareUpdateComplete);
                    }
                }
            } else {
                if (!isFlashingMainDevice && hasConfigureModule) {
                    // Allow embedded devices with hasConfig in firmware to get eeprom
                    Wmoapi.createRequestConfigureModule(request_id).then(function (success) {
                        $scope.$parent.request.configModuleRequestId = success.data.requestId;
                        $scope.firmwareUpdateCreateRequestConfigModuleNoReadings(device);
                    }, function (error) {
                        triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_CREATEREQUEST', error);
                    });
                }
                else if ($scope.$parent.configurationParameters.flashByFirmware) {
                    $scope.firmwareUpdateCreateRequestConfigModuleNoReadings();
                } else {
                    var readings = wu.transformPageReadingsToArray($scope.readRequiredPagesResult.configuration);
                    $scope.$parent.responseData.configModulePagesReadings = readings;
                    $scope.firmwareUpdateCreateRequestConfigModule(readings);
                }
            }
        }

        function onHubFirmwareUpdateComplete () {
            var restartHub = wu.restartHub($scope.$parent.detectedDevice, onRestartHubComplete, onRestartHubFailed);
            restartHub.start();
        }

        function onRestartHubComplete () {
            AppService.setRequiresReboot(false);
            $window.location.reload(true);
        }

        function onRestartHubFailed () {
            ngDialog.openConfirm({
                template: 'modules/core/views/modals/hubreset.modal.client.view.html',
                className:'ngdialog-theme-plain'
            }).then(function(result){
                $window.location.reload(true);
            },function(error){
                $window.location.reload(true);
            });
        }
        
        function firmwareUpdateWuError (e) {
            alertBar.show('errorFull', (e) ? e : 'FLASH_FIRMWAREUPDATE_ERROR_FLASH');
            $scope.firmwareUpdateFlashing = false;
            $scope.apply();
        }

        $scope.firmwareUpdateRetry = function () {

            alertBar.hide();
            $scope.apply();
            $scope.firmwareUpdateFlashing = true;
            if ($scope.$parent.responseData.firmwareDownload.downloadId) {
                $scope.firmwareUpdateCreateFlashDevice($scope.$parent.responseData.firmwareDownload);
            } else {
                $scope.flashFirmwareUpdate();
            }

        };

        $scope.flashByFirmwareRetry = function () {
            alertBar.hide();
            $scope.apply();
            $scope.firmwareUpdateFlashing = true;
            $scope.firmwareUpdateDownload($scope.$parent.request.id);
        };

        function firmwareUpdateGetConfigEeprom() {

            $scope.flashing = true;
            $rootScope.isGlobalLoading = true;
            alertBar.hide();

            Wmoapi
                .getEepromAddress()
                .success(function (response) {
                    var requiredPages = response.address;
                    $scope.$parent.responseData.configModuleRequiredPages = requiredPages;

                    $scope.firmwareUpdateReadConfigEeprom(requiredPages);

                })
                .error(function (error) {
                    triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_GETEEPROM', error);
                });

        }

        $scope.firmwareUpdateReadConfigEeprom = function (requiredPages) {

            if (requiredPages.length > 0) {

                var device = DeviceService.getFlashableDevice();

                $scope.readRequiredPagesResult = wu.getReadModuleResult(device, requiredPages, $scope.firmwareUpdateReadConfigEepromComplete, $scope.firmwareUpdateReadConfigEepromError);
                $scope.readRequiredPagesResult.start();

            } else {
                alertBar.show('errorFull', 'FLASH_FIRMWAREUPDATE_ERROR_NOREQUIREDPAGES');
            }

        };

        $scope.firmwareUpdateReadConfigEepromComplete = function () {
            var device = DeviceService.getFlashableDevice();
            createRequestFirmwareUpdate(device);
        };

        $scope.firmwareUpdateReadConfigEepromError = function () {
            $scope.firmwareUpdateFlashing = false;
            alertBar.show('errorFull', 'FLASH_FIRMWAREUPDATE_ERROR_READEEPROM');

        };

        $scope.firmwareUpdateCreateRequestConfigModule = function (readings) {

            $scope.confirmRequestFlash(
                null,
                function () { //flash confirm complete

                    if (confirmConfigComplete) {
                        $scope.confirmConfigComplete();
                        return;
                    }

                    var device = DeviceService.getFlashableDevice();
                    var serial = device.serial;
                    var bootloader = device.bootloader.version;
                    var parent_request_id = $scope.$parent.request.id;

                    //standalone request
                    Wmoapi
                        .createRequestConfigureModuleSoloFwUpdate(bootloader, serial, readings, parent_request_id)
                        .success(function (response) {

                            var requestid_configModule = response.requestId;
                            $scope.$parent.request.configModuleRequestId = requestid_configModule;
                            $scope.firmwareUpdateDownloadConfig(requestid_configModule);

                        })
                        .error(function (error) {
                            triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_CREATEREQUESTCONFIG', error);
                        });

                },
                function (error) { //flash confirm error
                    triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_CONFIRM', error);
                });

        };

        $scope.firmwareUpdateCreateRequestConfigModuleNoReadings = function (device) {

            $scope.confirmRequestFlash(
                device,
                function () { //flash confirm complete
                    $scope.firmwareUpdateDownloadConfig($scope.$parent.request.configModuleRequestId);
                },
                function (error) { //flash confirm error
                    triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_CONFIRM', error);
                });

        };

        $scope.firmwareUpdateDownloadConfig = function (configModuleRequestId) {

            Wmoapi
                .download(configModuleRequestId)
                .success(function (response) {

                    var configDownload = response;
                    $scope.$parent.responseData.configModuleDownload = configDownload;
                    $scope.firmwareUpdateWriteConfigModule(configDownload);

                })
                .error(function (error) {
                    triggerErrorView('FLASH_FIRMWAREUPDATE_ERROR_CREATEREQUESTCONFIG', error);

                });
        };

        $scope.openLink = function(link){
            window.WD_open_webbrowser(link);
        };

        $scope.firmwareUpdateWriteConfigModule = function (configDownload) {

            var pageContent = configDownload.body.eepromMap;
            var device = DeviceService.getFlashableDevice();

            $scope.configResult = wu.getWriteModuleResult(device, pageContent, $scope.firmwareUpdateWriteConfigModuleComplete, firmwareUpdateWuError);
            $scope.configResult.start();
        };

        /**
         * Confirm all firmware update requests
         */
        $scope.firmwareUpdateWriteConfigModuleComplete = function () {

            var request_id = $scope.$parent.request.configModuleRequestId;
            var downloadId = $scope.$parent.responseData.configModuleDownload.downloadId;

            //Clear queue
            $scope.flashQueue = $scope.flashQueue.splice(1);

            if ($scope.flashQueue.length) {
                confirmFirmwareUpdate(request_id, downloadId, triggerFlashUpdateEmbeddedDevice);
            } else {
                if (confirmConfigComplete) {
                    confirmFirmwareUpdate(request_id, downloadId, $scope.confirmConfigComplete);
                } else {
                    confirmFirmwareUpdate(request_id, downloadId, onFirmwareUpdateComplete);
                }

            }

        };

        function triggerFlashUpdateEmbeddedDevice() {
            // Setup Step
            $scope.currentFlashSerial = $scope.flashQueue[0];
            $scope.flashnumber = $scope.flashQueueCopy.indexOf($scope.currentFlashSerial) + 1;
            console.log('Flash queue: '+$scope.flashnumber+'/'+ $scope.flashQueueCopy.length);
            Request.clearRequestDevice();
            dataService.mainDeviceHasFwUpdate = false;
            $rootScope.isGlobalLoading = false;
            $scope.firmwareUpdateFlashing = true;
            
            var embeddedDevice = DeviceService.getDeviceBySerial($scope.currentFlashSerial);
            var embeddedDeviceHasFwDependant = deviceIdentifier.isDetectedDeviceFwDependant(embeddedDevice.productPartOptions);

            // If flash new module
            if($scope.flashParameters.isNewModule && embeddedDeviceHasFwDependant) {
                createRequestFlashByFw();
            } else {
                if($scope.flashParameters.isNewModule && $scope.flashQueue.length <= 1) {
                    confirmConfigComplete = true;
                }
                DeviceService.setDeviceToFlash(embeddedDevice);

                if($scope.currentFlashSerial === dataService.rsuDeviceSerialToUpdate) {
                    createRequestRsuFirmwareUpdate(embeddedDevice);
                } else {
                    createRequestFirmwareUpdate(embeddedDevice);
                }
            }

        }

        //flash config module complete
        function onFirmwareUpdateComplete() {
            $scope.$parent.flashForFirmwareUpdateOnly = false;
            $rootScope.isGlobalLoading = false;
            messageBox.show('POPUP_FIRMWAREUPDATE_SAVED_TITLE', '');
            Request.clearRequestDevice();
            $scope.$parent.request.configModuleRequestId = '';
            $scope.$parent.request.id = '';
            $scope.$parent.loadDevicePartial();
        }

        function onErrorFirmwareUpdate() {
            $scope.firmwareUpdateFlashing = false;
            alertBar.show('errorFull', 'FLASH_FIRMWAREUPDATE_ERROR_CONFIRMCONFIG');
        }

        function confirmFirmwareUpdate(request_id, downloadId, onComplete) {

            if ($scope.$parent.responseData.setFirmware.hasLcdRemoteFirmwareUpdate) {
                Wmoapi
                    .download($scope.$parent.externalMemoryRequest.id)
                    .success(function (response) {
                        $rootScope.isGlobalLoading = false;
                        $scope.flashnumber++;
                        flashImage(response, function () {
                            $scope.confirmRequestConfigModule($scope.$parent.externalMemoryRequest.id, response.downloadId, function () {
                                $scope.confirmRequestConfigModule(request_id, downloadId, onComplete, onErrorFirmwareUpdate);
                            }, onErrorFirmwareUpdate);

                        }, firmwareUpdateWuError, $scope.flashProgress);
                    }).error(function(error){
                        console.log('--- hasLcdRemoteFirmwareUpdate');
                        triggerErrorView('FLASH_ERROR_DOWNLOAD',  error);
                     });
            } else {
                $scope.confirmRequestConfigModule(request_id, downloadId, onComplete, onErrorFirmwareUpdate);
            }
        }

        $scope.quitFirmwareUpdate = function () {

            $rootScope.isGlobalLoading = true;
            alertBar.hide();
            Request.clearAllRequestIds();
            $scope.$parent.loadDevicePartial();
        };

        $scope.confirmRequestFlash = function (device, onComplete, onError) {

            var parentRequestId = $scope.$parent.request.id;
            var downloadId = $scope.$parent.responseData.firmwareDownload.downloadId;
            DeviceService.setDeviceToFlash(device || DeviceService.getMainDevice());
            $scope.confirmRequest(parentRequestId, downloadId, onComplete, onError);
        };

        $scope.confirmRequestConfigModule = function (request_id, downloadId, onComplete, onError) {
            $scope.confirmRequest(request_id, downloadId, onComplete, onError);
        };

        $scope.confirmRequest = function (requestId, downloadId, onComplete, onError) {
            Wmoapi
                .confirm(requestId, downloadId)
                .success(function (response) {
                    onComplete();
                })
                .error(function (error) {
                    onError(error);
                });
        };

        $scope.confirmRequestConfigRemotes = function (onComplete, onError) {
            var configRemotesRequestId = $scope.$parent.request.configRemotesRequestId;
            var downloadId = $scope.$parent.responseData.configRemotesDownload.downloadId;
            $scope.confirmRequest(configRemotesRequestId, downloadId, onComplete, onError);
        };

        $scope.apply = function () {
            if ($scope.$root.$$phase !== '$apply' && $scope.$root.$$phase !== '$digest' && $scope.$$phase !== '$apply') {
                $scope.$apply();
            }
        };

        $scope.showErrorMessage = function (showFullBar, translateKey, errorMsgFromServer) {
            $rootScope.isGlobalLoading = false;
            var error_msg = _.isEmpty(errorMsgFromServer) ? '' : ' [ ' + (errorMsgFromServer.error || errorMsgFromServer) + ' ]';
            alertBar.show(showFullBar ? 'errorFull' : 'error', translateKey, {ERROR_MSG: error_msg});
            console.error(translateKey + error_msg);

        };

        $scope.detectAntenna = function () {
            $rootScope.isGlobalLoading = true;
            alertBar.hide();

            deviceDetect.detect().then(
                function (success) {
                    $scope.showErrorAntennaNotificationBar = false;
                    DeviceService.setDevices(success);
                    $scope.onDetectAntennaComplete();
                }, function (error) {
                    $rootScope.isGlobalLoading = false;
                    $scope.errorMessage = 'DETECT_NO_ANTENNA';
                    $scope.showErrorAntennaNotificationBar = true;
                }
            );
        };

        $scope.onDetectAntennaComplete = function () {

            $rootScope.isGlobalLoading = false;

            var device = DeviceService.getFlashableDevice();
            var deviceFunctionId = device.deviceFunctionId;
            var rfDecoder = $scope.$parent.responseData.rfDecoder;

            if (!deviceIdentifier.isDeviceAntenna(deviceFunctionId)) {
                $scope.showErrorAntennaNotificationBar = true;
                $scope.errorMessage = 'FLASH_ANTENNA_ERROR_NOT_OF_TYPE_ANTENNA';
            } else if (!rfDecoder || rfDecoder.id === 0 || rfDecoder.id === '') {

                $scope.showErrorAntennaNotificationBar = true;
                $scope.errorMessage = 'FLASH_ANTENNA_ERROR_KIT_NOT_FOUND';

            } else if (device.serial.toUpperCase() !== rfDecoder.serial.toUpperCase()) {

                $scope.showErrorAntennaNotificationBar = true;
                $scope.errorMessage = 'FLASH_ANTENNA_ERROR_NOT_SAME_AS_KIT';

            } else {
                if (device.hasProgram) {
                    $scope.showErrorAntennaNotificationBar = false;
                    $scope.createRequestForAntenna();
                } else {
                    $scope.showErrorAntennaNotificationBar = true;
                    $scope.errorMessage = 'FLASH_ANTENNA_ERROR_NOT_HAS_PROGRAM';
                }
            }

        };

        $scope.reDetectAntenna = function () {
            $scope.detectAntenna();
        };

        //functions for FLSBA device

        /**
         * Flashing FLRSBA embedded device based on the parent request
         * The firmware on the embedded device is dependent on the parent device firmware > protocol
         */
        function createRequestFlashByFw() {
            var parentRequestId = $scope.$parent.request.id;
            var embeddedDevice = DeviceService.getEmbeddedDevice();

            Wmoapi
                .createRequestFlashByFw(embeddedDevice.bootloader.version, embeddedDevice.serial, parentRequestId)
                .success(function (response) {
                    var requestId = response.requestId;
                    $scope.$parent.request.configBADeviceRequestId = requestId;
                    DeviceService.setDeviceToFlash(embeddedDevice);
                    //so here we download
                    flashBADevice(requestId);

                })
                .error(function (error) {
                    triggerErrorView('FLASH_BA_DEVICE_ERROR_CREATE_REQUEST', error);
                });

        }

        function flashBADevice(requestId) {
            $scope.progress.message = 'FLASH_BA_DEVICE_PROGRESS_TITLE';
            Wmoapi
                .download(requestId)
                .success(function (response) {
                    $rootScope.$broadcast('flashProgress', 99);
                    var codeDownload = response;
                    $scope.$parent.responseData.configBADownload = response;
                    $scope.antennaCreateFlashProcess(codeDownload, confirmConfigBA, onFlashBADError);
                })
                .error(function (error) {
                    triggerErrorView('FLASH_BA_DEVICE_ERROR_DOWNLOAD', error);
                });

        }

        function confirmConfigBA() {

            var configBARequestId = $scope.$parent.request.configBADeviceRequestId;
            var downloadId = $scope.$parent.responseData.configBADownload.downloadId;


            Wmoapi
                .confirm(configBARequestId, downloadId)
                .success(function () {
                    console.log('flashing BA device completed!');
                    $rootScope.$broadcast('flashProgress', 100);
                    $scope.mostRecentCompletedStep.stepNumber++;
                    $rootScope.isGlobalLoading = false;
                    $scope.isAntennaUpdated = true;
                    $scope.$parent.sidebar.mode = 'flash-klon';
                    $scope.$parent.sidebar.currentIndex = isDetectedDeviceIsClassic ? 9 : 8;
                    sidebarManager.setGuidesWiresSidebarStatus(true);
                    Request.clearAllRequestIds();
                    $scope.redetect();
                })
                .error(function (error) {
                    triggerErrorView('FLASH_BA_DEVICE_ERROR_CONFIRM', error);
                });

        }

        function onFlashBADError() {
            console.error('error flash BA device:');
            if($scope.keyreadingFlash) {
                $rootScope.$broadcast('flashError', 'FLASH_BA_DEVICE_ERROR_MESSAGE');
            } else {
                $timeout(function () {
                    $scope.flashing = false;
                    $scope.percentage = 0;
                }, 0);
                $scope.showErrorNotificationBar = true;
                $scope.errorTitle = 'FLASH_BA_DEVICE_ERROR_TITLE';
                $scope.errorMessage = 'FLASH_BA_DEVICE_ERROR_MESSAGE';
                $scope.event = retryFlashBADevice;
                $scope.apply();
            }

        }

        function retryFlashBADevice(){
            alertBar.hide();
            $scope.percentage = 0;
            $scope.showErrorNotificationBar = false;
            flashDevice($scope.$parent.responseData.configBADownload, confirmConfigBA, onFlashBADError, $scope.flashProgress);
        }

        $scope.initControlsBox =  function () {

            $scope.getActivateRemotes();
            $scope.controlsObject =  getControlsItems ();
        };

        //Controls box
        function getControlsItems () {
            var i = 0;
            var controlsObject = {};
            var slides= [];
            var array = [];
            var telematicObj = {
                name: 'No Telematics',
                image: 'img/telematics/flash-summary/phone/no-phone.png',
                isTelematic: false,
                skipStep:'telematics',
                disabled: $scope.isDeviceUsedAsAddOn
            };
            var oemObj = {
                name: 'No Oem Remote',
                image:'img/flash/no-remote-oem.png',
                isOem: false,
                skipStep:'oem-remote-start',
            };
            var rfControl = {
                name: 'No Remotes',
                image:'img/remotes/generic_summary_grey.png',
                description:'',
                brand:'',
                skipStep:'internal-remotes',
            };

            var remotes = $scope.$parent.responseData.deviceControls;

            if (remotes.length > 0) {
                rfControl.image = 'img/remotes/generic_summary_grey.png';
                rfControl.remotes = remotes;
                rfControl.name = $scope.selectedRemotes.brand;
                rfControl.brand = $scope.rsuDataSelectedBrand && $scope.rsuDataSelectedBrand.name ? $scope.rsuDataSelectedBrand.name : '';
                slides.push(++i);
                array.push(rfControl);
            } else if ($scope.selectedRemotes.esns.length  === 0  &&  $scope.selectedRsu && $scope.selectedRsu.id) {
                rfControl.image = $scope.selectedRsu.image;
                rfControl.name = $scope.selectedRsu.name;
                rfControl.description = $scope.selectedRemotes.description;
                rfControl.brand = $scope.rsuDataSelectedBrand && $scope.rsuDataSelectedBrand.name ? $scope.rsuDataSelectedBrand.name : '';
                slides.push(++i);
                array.push(rfControl);
            } else if ($scope.$parent.userSelection.selectedProtocol.isStandaloneRf && $scope.$parent.userSelection.selectedProtocol.id !== '') {
                rfControl.image = 'img/remotes/generic_summary_grey.png';
                rfControl.name = $scope.$parent.userSelection.selectedProtocol.name;
                rfControl.description = '';
                rfControl.brand = '';
                rfControl.skipStep = 'protocols';
                slides.push(++i);
                array.push(rfControl);
            }

            if($scope.$parent.userSelection.telematicId){
                telematicObj.name = $scope.$parent.userSelection.telematicName;
                telematicObj.image = $scope.$parent.userSelection.telematicImagePhone;
                telematicObj.isTelematic = true;
            } else if ($scope.$parent.userSelection.selectedProtocol.isStandaloneTelematic && $scope.$parent.userSelection.selectedProtocol.id !== '') {
                telematicObj.name = $scope.$parent.userSelection.selectedProtocol.name;
                telematicObj.image = 'img/telematics/flash-summary/phone/generic.png';
                telematicObj.isTelematic = true;
                telematicObj.skipStep = 'protocols';
            }
            slides.push(++i);

            if($scope.$parent.userSelection.oemRemote.id !== '' || $scope.$parent.userSelection.oemRemote.id !== 0){
                oemObj.name = $scope.$parent.userSelection.oemRemote.name;
                oemObj.isOem =  true;
                oemObj.image = 'img/flash/remote-oem.png';
            }
            slides.push(++i);

            if (telematicObj.isTelematic){
                array.push(telematicObj);
                array.push(oemObj);
            } else {
                array.push(oemObj);
                array.push(telematicObj);
            }

            controlsObject.array = array;
            controlsObject.slides = slides;
            return controlsObject;
        }

        /***
         * Only does the flashing of the device (no configure-module, no remote programming)
         */
        function flashFirmwareOnly () {

            alertBar.hide();
            var parentRequestId = $scope.$parent.request.id;
            $rootScope.isGlobalLoading = true;

            Wmoapi
                .download(parentRequestId)
                .success(function (response) {
                    //download required code, then start flash process
                    $scope.flashing = true;
                    $rootScope.flashInProgress = true;
                    $scope.percentage = 0;
                    $scope.$parent.responseData.firmwareDownload = response;
                    flashDevice($scope.$parent.responseData.firmwareDownload, $scope.flashFirmwareOnlyComplete, $scope.flashError, $scope.flashProgress);
                })
                .error(function (error) {
                    $rootScope.isGlobalLoading = false;
                    $scope.disableFlashButton = false;
                    $scope.showErrorMessage(true, 'FLASH_ERROR_DOWNLOAD', error);
                });
        }

        $scope.flashFirmwareOnlyComplete = function () {

            var parentRequestId = $scope.$parent.request.id;
            var downloadId = $scope.$parent.responseData.firmwareDownload.downloadId;

            Wmoapi
                .confirm(parentRequestId, downloadId)
                .success(function () {
                    console.log('flash fw only completed - confirming req -');
                    $scope.confirmConfigComplete();
                })
                .error(function (error) {
                    console.log('--- flashFirmwareOnlyComplete');
                    triggerErrorView('FLASH_ERROR_FLASHING',  error);
                    $rootScope.isGlobalLoading = false;
                });
        };

        $scope.clickRsuGuide = function () {
            guidesService.getRsuGuide($scope.$parent.request.id, mainDevice, rsuDevice, $scope.hasRsuGuide.extraGuideName);
        };

        $scope.saveFavorite = function () {
            var selectedVehicle = `${$scope.$parent.selectedVehicle.makeName} ${$scope.$parent.selectedVehicle.modelName} ${$scope.$parent.selectedVehicle.yearName} ${$scope.$parent.selectedVehicle.vehicleName}`;
            ngDialog.openConfirm({
                template: 'modules/core/views/partials/favorites/modals/save-favorite.modal.client.view.html',
                className:'ngdialog-theme-plain favorites-modal',
                controller: ['$scope', function ($scope) {
                    $scope.name = selectedVehicle;
                    $scope.description = '';
                    $scope.disableNameField = true;
                    $scope.proceedToFlash = true;

                    $scope.submit = function (favoriteForm) {
                        if (favoriteForm.$valid) {
                            $scope.confirm({name: $scope.name, description: $scope.description});
                        }
                    };
                }]
            }).then(function (result){
                Wmoapi.saveFavoriteFlash(result.name, result.description, $scope.$parent.request.id, $scope.$parent.request.configModuleRequestId)
                    .then(function (response) {
                        if(response.data.result === 1) {
                            var fwpost = $scope.$parent.responseData.firmwarePosts;
                            $scope.hasFirmwarePost = (fwpost && fwpost.length !== undefined && fwpost.length > 0);

                            if ($scope.hasFirmwarePost){
                                $scope.openWarningModal();
                            } else {
                                $scope.flashClick();
                            }
                        } else {
                            $scope.showErrorMessage(true, 'FAVORITE_SAVE_ERROR', response.data);
                        }
                    }, function (error){
                        $scope.showErrorMessage(true, 'FAVORITE_SAVE_ERROR', error.data);
                    });
            });
        };

    }
]);
